/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useRef,
  Suspense,
  useCallback,
  useMemo,
} from "react";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import Button from "@material-ui/core/Button";
import {
  SCREEN,
  SUBDEFINITIONS,
  MAX_SEARCHBAR_WIDGETS,
  FTS_FIELD,
} from "../util/constants";
import {
  isNull,
  isEmpty,
  isString,
  calcRuleState,
  getHideRuleState,
  evaluateTriggerState,
} from "../util/common.js";
import definitionHelper from "../util/definitions.js";
import * as cloneDeep from "lodash/cloneDeep";
import Loading from "../components/loading";
import Skeleton from "@material-ui/lab/Skeleton";
import Chance from "chance";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { View, ScrollView } from "react-native";
import { FormProvider } from "../form/formContext";
import TabBar from "../components/TabBar";
import Persist from "../form/Persist";
import FormRoot from "../form/FormRoot";
import { FieldList } from "../form/widgets/index";
import { getWidget } from "../form/helper";
import FormTemplate from "./formTemplate";
import { WafelButton, WafelSubmitButton } from "../form/widgets/index";
import Toast from "../components/Toast";
import { WORKFLOWLOG } from "../hoc/withCache";
import { useWorkflow } from "../hooks/useWorkflow";
import TabBarIcon from "../components/TabBarIcon";
import { renderLogo } from "../navigation/utils";
import MainDrawer from "../components/drawer/MainDrawer";
import Drawer from "../components/drawer/Drawer";
import getEnvironment from "../environment/env";
import { useCache } from "../hoc/withCache";
import { TOKENS } from "../theme";
import {
  calcRelativeCellScreenSize,
  fieldHidden,
  fieldIsReadonly,
} from "./layoutHelper";

const chance = new Chance();

const SUBDEF_FIELD = "!!subdef";
const BLANK_STYLE = {};

function getRootDefinition(
  entityFactory,
  searchDefinition,
  definition,
  formDefinition,
  isWorkflowForm
) {
  let rootDefinition = searchDefinition
    ? searchDefinition
    : definition
    ? definition
    : formDefinition
    ? formDefinition
    : entityFactory
    ? entityFactory.definition
    : definition;
  rootDefinition = cloneDeep(rootDefinition);
  if (isWorkflowForm) {
    rootDefinition = definitionHelper.fixWorkflowFormDefinition(rootDefinition);
  } else {
    rootDefinition = definitionHelper.fixDefinition(rootDefinition);
  }
  return rootDefinition;
}

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

function getSubDefField(rootDefinition) {
  let subDefField = undefined;
  if (!isEmpty(rootDefinition?.fields)) {
    subDefField = rootDefinition.fields.find(
      (field) => field.fieldName === SUBDEF_FIELD
    );
  }
  return subDefField;
}

function fieldIsRequired(field) {
  return field.required;
}

function getDisplayMode(pageDefinition) {
  return isEmpty(pageDefinition.displayMode)
    ? "None"
    : pageDefinition.displayMode;
}

function fieldReadOnlyByRule(definition, fieldName, readOnlyRuleState) {
  let rules = isNull(definition.rules) ? [] : definition.rules;
  let applicableRules = rules.filter((rule) => !isEmpty(rule.readOnly));
  for (let i = 0; i < applicableRules.length; i++) {
    let applicableRule = applicableRules[i];
    let triggerField = applicableRule.fieldName;

    let triggerCheck = null;
    if (isNull(applicableRule.trigger)) {
      triggerCheck = readOnlyRuleState[triggerField + "_null"];
    } else {
      triggerCheck =
        readOnlyRuleState[
          triggerField + "_" + applicableRule.trigger.toString()
        ];
    }
    if (triggerCheck && !isEmpty(applicableRule.readOnly)) {
      for (let j = 0; j < applicableRule.readOnly.length; j++) {
        let targetField = applicableRule.readOnly[j];
        if (targetField === fieldName) {
          return true;
        }
      }
    }
  }
  return false;
}

function findAllFields(
  currentUser,
  definition,
  fields,
  hidden,
  hideRuleState,
  searchBarTop,
  screenSize
) {
  let results = [];
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    let currentIsHidden =
      hidden ||
      fieldHidden(
        currentUser,
        definition,
        field,
        hideRuleState,
        searchBarTop,
        screenSize
      );
    results.push({ field: field, hidden: currentIsHidden });
    if (field.type === "object") {
      let children = findAllFields(
        currentUser,
        definition,
        field.definition.fields,
        currentIsHidden,
        hideRuleState,
        searchBarTop,
        screenSize
      );
      for (let j = 0; j < children.length; j++) {
        let child = children[j];
        results.push({ field: child.field, hidden: child.hidden });
      }
    }
  }
  return results;
}

function findAllFieldsAndValue(
  currentUser,
  definition,
  formData,
  fields,
  hideRuleState,
  searchBarTop,
  screenSize,
  isBulk = false
) {
  let results = findAllFields(
    currentUser,
    definition,
    fields,
    false,
    hideRuleState,
    searchBarTop,
    screenSize
  );
  for (let i = 0; i < results.length; i++) {
    let field = results[i];
    if (!isNull(field) && field.type !== "button" && field.type !== "id") {
      let value = formData ? formData[field.field.fieldName] : null;
      if (isNull(value)) {
        value = null;
      }
      if (
        !isEmpty(value) &&
        field.field.type === "array" &&
        field.field.inPlace &&
        field.field.definition.fields
      ) {
        if (isBulk) {
          value = value.prop;
        }
        if (isNull(value)) {
          value = [];
        }

        let newValue = [];
        for (let j = 0; j < value.length; j++) {
          let allFields = findAllFieldsAndValue(
            currentUser,
            definition,
            value[j],
            field.field.definition.fields,
            hideRuleState,
            searchBarTop,
            screenSize,
            isBulk
          );
          let input = {};
          for (let k = 0; k < allFields.length; k++) {
            const field = allFields[k].field;
            if (field.type !== "object") {
              if (field.type === "array" && field.inPlace) {
                input[field.fieldName] = JSON.stringify(allFields[k].value);
              } else {
                input[field.fieldName] = allFields[k].value;
              }
            }
          }
          newValue.push(input);
        }
        if (isBulk) {
          if (newValue.length > 0) {
            field.value = { select: true, prop: newValue };
          } else {
            field.value = { select: false, prop: null };
          }
        } else {
          field.value = newValue;
        }
      } else if (isEmpty(value) && field.field.type === "string") {
        field.value = null;
      } else {
        field.value = value;
      }
    }
  }
  return results;
}

function getReadOnlyRuleState(currentUser, definition, values) {
  let rules = isNull(definition.rules) ? [] : definition.rules;
  let applicableRules = rules.filter((rule) => !isEmpty(rule.readOnly));
  return calcRuleState(currentUser, definition, values, applicableRules);
}

function suggestSubDefinition(subdef, rootDefinition) {
  let suggestedDefinition = subdef;
  let suggested = null;
  if (!isNull(rootDefinition.current)) {
    for (let i = 0; i < rootDefinition.current.subDefinitions.length; i++) {
      if (
        rootDefinition.current.subDefinitions[i].viewName ===
        suggestedDefinition
      ) {
        suggested = suggestedDefinition;
        break;
      }
    }
  }
  if (isNull(suggested) && suggestedDefinition.endsWith(":xl")) {
    let baseSubDef = suggestedDefinition.substring(
      0,
      suggestedDefinition.length - 3
    );
    suggestedDefinition = baseSubDef + ":l";
    if (
      definitionHelper.subDefinitionExists(
        rootDefinition.current,
        suggestedDefinition
      )
    ) {
      suggested = suggestedDefinition;
    }
  }

  if (isNull(suggested) && suggestedDefinition.endsWith(":l")) {
    let baseSubDef = suggestedDefinition.substring(
      0,
      suggestedDefinition.length - 2
    );
    suggestedDefinition = baseSubDef + ":m";
    if (
      definitionHelper.subDefinitionExists(
        rootDefinition.current,
        suggestedDefinition
      )
    ) {
      suggested = suggestedDefinition;
    }
  }

  if (isNull(suggested) && suggestedDefinition.endsWith(":m")) {
    suggestedDefinition = suggestedDefinition.substring(
      0,
      suggestedDefinition.length - 2
    );
    if (suggestedDefinition !== "default") {
      if (
        definitionHelper.subDefinitionExists(
          rootDefinition.current,
          suggestedDefinition
        )
      ) {
        suggested = suggestedDefinition;
      } else {
        if (
          definitionHelper.subDefinitionExists(
            rootDefinition.current,
            suggestedDefinition + ":s"
          )
        ) {
          suggested = suggestedDefinition + ":s";
        }
      }
    } else {
      suggested = "default";
    }
  }

  if (isNull(suggested) && suggestedDefinition.endsWith(":s")) {
    suggestedDefinition = suggestedDefinition.substring(
      0,
      suggestedDefinition.length - 2
    );
    if (suggestedDefinition !== "default") {
      if (
        definitionHelper.subDefinitionExists(
          rootDefinition.current,
          suggestedDefinition
        )
      ) {
        suggested = suggestedDefinition;
      } else {
        suggestedDefinition = null;
      }
    }
  }

  if (isNull(suggested)) {
    suggested = "default";
  }
  return suggested;
}

function getDefinition(
  screenSize,
  subDefinition,
  item,
  flatten,
  renderLevel,
  subDefField,
  rootDefinition
) {
  //console.log("getDefinition:::screenSize", screenSize)
  let suggestedDefinition = subDefinition;

  if (isNull(suggestedDefinition)) {
    //For workflow ... check for !!subdef.
    if (!isNull(item) && !isNull(item[SUBDEF_FIELD])) {
      suggestedDefinition = item[SUBDEF_FIELD];
    } else {
      if (subDefField.current) {
        if (!isNull(subDefField.current.default)) {
          suggestedDefinition = subDefField.current.default;
        }
      }
    }
  }

  let definition = null;
  if (suggestedDefinition === SUBDEFINITIONS.LARGE.NAME) {
    suggestedDefinition =
      screenSize === SCREEN.SMALL
        ? SUBDEFINITIONS.SMALL.NAME
        : screenSize === SCREEN.MEDIUM
        ? SUBDEFINITIONS.MEDIUM.NAME
        : SUBDEFINITIONS.LARGE.NAME;
  } else if (suggestedDefinition === SUBDEFINITIONS.MEDIUM.NAME) {
    suggestedDefinition =
      screenSize >= SCREEN.LARGE
        ? SUBDEFINITIONS.MEDIUM.NAME
        : SUBDEFINITIONS.SMALL.NAME;
  }

  if (isNull(suggestedDefinition)) suggestedDefinition = "default";
  if (suggestedDefinition.indexOf(":") === -1) {
    //Try and check for an override version.
    if (screenSize >= SCREEN.LARGE) {
      suggestedDefinition = suggestedDefinition + ":l";
    } else if (screenSize === SCREEN.MEDIUM) {
      suggestedDefinition = suggestedDefinition + ":m";
    } else if (screenSize === SCREEN.SMALL) {
      suggestedDefinition = suggestedDefinition + ":s";
    }
  }

  suggestedDefinition = suggestSubDefinition(
    suggestedDefinition,
    rootDefinition
  );

  //if (!isNull(suggestedDefinition)) {
  definition = definitionHelper.findSubDefinition(
    rootDefinition.current,
    suggestedDefinition
  );
  let flattenDef = false;
  if (!suggestedDefinition.endsWith(":s") && screenSize === SCREEN.SMALL) {
    //We're going have to flatten
    flattenDef = true;
  } else if (
    (suggestedDefinition.endsWith(":xl") ||
      suggestedDefinition.endsWith(":l")) &&
    screenSize === SCREEN.MEDIUM
  ) {
    //We're going have to flatten
    flattenDef = true;
  } else if (
    suggestedDefinition.endsWith(":xl") &&
    screenSize === SCREEN.LARGE
  ) {
    //We're going have to flatten
    flattenDef = true;
  } else {
    flattenDef = isNull(flatten) ? false : flatten;
  }
  if (flattenDef) {
    definition = definitionHelper.flattenDefinition(definition, renderLevel);
  } else {
    definition = cloneDeep(definition);
  }
  /*
  } else {
    let flatten = false;
    if (props.subDefinition === SUBDEFINITIONS.LARGE.NAME && screenSize === SCREEN.SMALL) {
      //We're going have to flatten
      flatten = true;
    } else if (props.subDefinition === SUBDEFINITIONS.MEDIUM.NAME && screenSize <= SCREEN.MEDIUM) {
      //We're going have to flatten
      flatten = true;
    } else {
      flatten = isNull(props.flatten) ? false : props.flatten;
    }
    if (flatten) {
      definition = definitionHelper.flattenDefinition(rootDefinition.current, props.renderLevel);
    } else {
      definition = cloneDeep(rootDefinition.current);
    }
  }
  */
  return definition;
}
function coreFormDataToMutationInput(
  entityFactory,
  bulkItems,
  thread,
  layoutGraph,
  subDefinition,
  item,
  flatten,
  renderLevel,
  match,
  isNew,
  isSearch,
  subDefField,
  rootDefinition,
  hideRuleState,
  formData,
  searchBarTop,
  screenSize,
  includeAll = false
) {
  //const { fields } = rootDefinition.current;
  let updateFields = [];
  let definition = getDefinition(
    screenSize,
    subDefinition,
    item,
    flatten,
    renderLevel,
    subDefField,
    rootDefinition
  );
  let input = { ids: item && item.id ? [item.id] : null };
  if (!isNull(definition)) {
    const { fields } = definition;
    let currentUser = layoutGraph ? layoutGraph.currentUser : null;
    let isNewItem =
      (match && match.params && match.params.entityId === "new") || isNew;

    let allFields = findAllFieldsAndValue(
      currentUser,
      definition,
      formData,
      fields,
      hideRuleState,
      searchBarTop,
      screenSize,
      false
    );
    for (let i = 0; i < allFields.length; i++) {
      const field = allFields[i].field;
      let fieldHidden = allFields[i].hidden && !isSearch && !isNewItem; //fieldHidden(currentUser, field);
      let fieldReadonly = fieldIsReadonly(
        entityFactory,
        layoutGraph,
        item,
        bulkItems,
        thread,
        currentUser,
        input.ids !== null,
        field
      );
      if (
        field.type !== "object" &&
        (includeAll || (!fieldHidden && !fieldReadonly))
      ) {
        if (!isSearch || !isNull(allFields[i].value)) {
          if (field.fieldName === FTS_FIELD) {
            let rawVal = allFields[i].value;
            if (!isNull(rawVal)) {
              input.fts = rawVal;
            }
          } else {
            if (field.type === "array" && field.inPlace) {
              input[field.fieldName] = JSON.stringify(allFields[i].value);
              updateFields.push(field.fieldName);
            } else {
              if (isSearch && field.type === "json") {
                //console.log("allFields[i].value", allFields[i].value)
                let rawVal = allFields[i].value;
                if (!isEmpty(rawVal)) {
                  let val = JSON.parse(rawVal);
                  if (!isNull(val) && !isEmpty(val.val)) {
                    input[field.fieldName] = allFields[i].value;
                    updateFields.push(field.fieldName);
                  }
                }
              } else {
                input[field.fieldName] = allFields[i].value;
                updateFields.push(field.fieldName);
              }
            }
          }
        }
      }
    }
  }
  if (updateFields.length > 0 || !isNull(input.fts)) {
    input.updateFields = updateFields;
    input.versionId = item && item.id ? item.versionId : null;
  } else {
    input = {};
  }

  return input;
}

function calcCurrentThreadFormData(
  entityFactory,
  bulkItems,
  thread,
  layoutGraph,
  subDefinition,
  item,
  flatten,
  renderLevel,
  match,
  isNew,
  isSearch,
  searchBarTop,
  screenSize,
  subDefField,
  rootDefinition,
  isTask,
  hideRuleState,
  formData,
  includeAll
) {
  if (isTask) {
    let newThreadFormData = coreFormDataToMutationInput(
      entityFactory,
      bulkItems,
      thread,
      layoutGraph,
      subDefinition,
      item,
      flatten,
      renderLevel,
      match,
      isNew,
      isSearch,
      subDefField,
      rootDefinition,
      hideRuleState,
      formData,
      searchBarTop,
      screenSize,
      includeAll
    );
    delete newThreadFormData.versionId;
    delete newThreadFormData.updateFields;
    if (!newThreadFormData.ids) delete newThreadFormData.ids;
    if (newThreadFormData === undefined) {
      newThreadFormData = null;
    }
    return newThreadFormData;
  }
}

function calcFormDataToMutationInput(
  entityFactory,
  bulkItems,
  thread,
  step,
  layoutGraph,
  subDefinition,
  item,
  flatten,
  renderLevel,
  match,
  isNew,
  isSearch,
  searchBarTop,
  screenSize,
  subDefField,
  rootDefinition,
  isTask,
  hideRuleState,
  formData
) {
  if (isTask) {
    let newThreadFormData = calcCurrentThreadFormData(
      entityFactory,
      bulkItems,
      thread,
      layoutGraph,
      subDefinition,
      item,
      flatten,
      renderLevel,
      match,
      isNew,
      isSearch,
      searchBarTop,
      screenSize,
      subDefField,
      rootDefinition,
      isTask,
      hideRuleState,
      formData,
      false
    );

    //The becomes the threadStateData for the thread... we now have to create the queryParams for the Thread.

    //The version won't be right. Fix it at the end
    //Copy any old values across.
    /*
    let threadFormData = JSON.parse(thread.threadStateData);
    for (let key in threadFormData) {
      if (threadFormData.hasOwnProperty(key)) {
        if (newThreadFormData[key] === undefined) {
          newThreadFormData[key] = threadFormData[key];
        }
      }
    }
    */

    let nextStepId = 1;
    for (let i = 0; i < step.exits.length; i++) {
      let exit = step.exits[i];
      if (exit.isDefault) {
        nextStepId = exit.id;
        break;
      }
    }

    let queryParams = {
      ids: [thread.id],
      transactionId: chance.guid(),
      timeStamp: thread && !isNull(thread.timeStamp) ? thread.timeStamp : null,
      stepId: step.id,
      exitId: nextStepId,
      script: "",
      workflowName: thread.workflowName,
      threadStateData: JSON.stringify(newThreadFormData),
      updateFields: [],
    };

    return queryParams;
  } else {
    return coreFormDataToMutationInput(
      entityFactory,
      bulkItems,
      thread,
      layoutGraph,
      subDefinition,
      item,
      flatten,
      renderLevel,
      match,
      isNew,
      isSearch,
      subDefField,
      rootDefinition,
      hideRuleState,
      formData,
      searchBarTop,
      screenSize
    );
  }
}

const FormBase = (props) => {
  const {
    entityFactory,
    searchDefinition,
    isWorkflowForm,
    screenSize,
    isSearch,
    searchBarTop,
    publicStyle,
    layoutGraph,
    step,
    thread,
    onInvalidate,
    onSubmit,
  } = props;
  const { formCache } = useCache();
  const { current: emptyObject } = useRef({});
  const [isTask] = useState(!isNull(onInvalidate));
  const [processing, setProcessing] = useState(false);
  const isProcessing = thread?.processing || processing;
  const [taskState, setTaskState] = useState({
    debugMode: false,
    debugHistory: [],
    debugPosition: -1,
    isOpen: false,
  });
  const _menuActionDrawer = useRef(null);
  const rootDefinition = useRef(
    getRootDefinition(
      entityFactory,
      searchDefinition,
      props.definition,
      props.formDefinition,
      isWorkflowForm
    )
  );
  const subDefField = useRef(getSubDefField(rootDefinition.current));

  const { executeAction } = useWorkflow();

  const handleClose = () => {
    setTaskState({ ...taskState, isOpen: false });
  };

  const handleOpen = () => {
    setTaskState({ ...taskState, isOpen: true });
  };

  const closeMenuActionDrawer = () => {
    if (_menuActionDrawer.current) _menuActionDrawer.current.close();
  };
  const openMenuActionDrawer = () => {
    if (_menuActionDrawer.current.drawer) _menuActionDrawer.current.open();
  };

  const onDebug = () => {
    let newState = {};
    newState.debugMode = !taskState.debugMode;
    if (newState.debugMode) {
      newState.debugHistory = getNewDebugHistory(props);
      newState.debugPosition = newState.debugHistory.length - 1;
    } else {
      newState.debugHistory = [];
      newState.debugPosition = -1;
    }
    setTaskState({ ...taskState, ...newState });
  };

  const applyDefault = useCallback(
    (field, isExisting) => {
      if (isTask) {
        return (
          field.readOnly ||
          (["array", "json", "file", "object"].indexOf(field.type) >= 0 &&
            isString(field.default) &&
            field.default.startsWith("::")) ||
          isExisting
        );
      } else {
        if (rootDefinition.current) {
          let checkedField = definitionHelper.findField(
            rootDefinition.current.fields,
            field.fieldName
          );
          if (isNull(checkedField)) {
            return true;
          } else {
            return !isExisting;
          }
        } else {
          return !isExisting;
        }
      }
    },
    [isTask]
  );

  const getInitialData = useCallback(
    (entityId, onSubmitCallback, definition, item, isExisting) => {
      let formData = {};
      if (!isNull(definition)) {
        if (
          entityId &&
          isNull(onSubmitCallback) &&
          isWorkflowForm !== true &&
          isNull(formData.id)
        ) {
          formData.id = entityId;
        }

        const { fields } = definition;
        for (let i = 0; i < fields.length; i++) {
          let field = fields[i];
          formData[field.fieldName] = null;
          if (
            field.definition &&
            field.type !== "json" &&
            field.type !== "chart" &&
            field.type !== "geog"
          ) {
            if (field.type === "array" || field.type === "stringarray") {
              if (item && !isNull(item[field.fieldName])) {
                if (field.inPlace) {
                  let fieldArray = JSON.parse(item[field.fieldName]);
                  let resultArray = [];
                  for (let j = 0; j < fieldArray.length; j++) {
                    resultArray.push(
                      getInitialData(
                        entityId,
                        onSubmitCallback,
                        field.definition,
                        fieldArray[j],
                        isExisting
                      )
                    );
                  }
                  formData[field.fieldName] = resultArray;
                } else {
                  formData[field.fieldName] = item[field.fieldName];
                }
              } else {
                formData[field.fieldName] = [];
              }
            } else {
              //Should be object type
              let child = getInitialData(
                entityId,
                onSubmitCallback,
                field.definition,
                item,
                isExisting
              );
              if (!isNull(child)) {
                let merged = { ...formData, ...child };
                formData = merged;
              }
            }
          } else if (field.type === "id") {
            if (item) {
              formData[field.fieldName] = item.id;
            }
          } else {
            if (item && !isNull(item[field.fieldName])) {
              formData[field.fieldName] = item[field.fieldName];
            } else if (
              !isNull(field.default) &&
              applyDefault(field, isExisting)
            ) {
              let defaultVal = field.default;
              if (defaultVal === "::now") {
                let now = new Date().toISOString();
                //now = now.substring(0, now.length-5) + "Z";
                defaultVal = now;
              } else if (defaultVal === "::log") {
                defaultVal = JSON.stringify(WORKFLOWLOG());
              }
              formData[field.fieldName] = defaultVal;
            }
            if (
              field.type === "string" &&
              isNull(formData[field.fieldName]) &&
              field.format !== "date" &&
              field.format !== "date-time" &&
              field.format !== "date-time-range"
            ) {
              //Initialize with empty string.
              formData[field.fieldName] = "";
            }
          }
        }
      }
      return formData;
    },
    [applyDefault, isWorkflowForm]
  );

  const getWafelMenu = (location, hideWorkflowButtons) => {
    const { layoutGraph } = props;
    let workflows = [];
    let allWorkflows =
      layoutGraph && !isEmpty(layoutGraph.allWorkflows)
        ? layoutGraph.allWorkflows
        : [];
    for (let j = 0; j < allWorkflows.length; j++) {
      let workflow = allWorkflows[j];
      let add = false;
      let parent = null;
      let priority = 0;
      if (workflow.isEntityWorkflow) {
        if (
          entityFactory?.definition &&
          workflow.entityName === entityFactory.definition?.entityName
        ) {
          add = !hideWorkflowButtons;
          if (add) {
            if (!isEmpty(workflow.locationHints)) {
              //Only makes sense currently to have one ... it's for the entity.
              priority = isNull(workflow.locationHints[0].priority)
                ? 0
                : workflow.locationHints[0].priority;
              let hintArray = workflow.locationHints[0].location;
              if (hintArray && hintArray.length > 1) {
                parent = isEmpty(hintArray[1]) ? null : hintArray[1];
              }
            }
          }
        }
      }
      if (!add && !workflow.isEntityWorkflow && !isEmpty(location)) {
        location = location.toLowerCase();
        for (let k = 0; k < workflow.locationHints.length; k++) {
          let hintArray = workflow.locationHints[k].location;
          if (hintArray && hintArray.length > 0) {
            let hint = hintArray[0].toLowerCase();
            if (hint === location) {
              if (hintArray.length > 1) {
                parent = hintArray[1];
              }
              priority = workflow.locationHints[k].priority;
              add = true;
              break;
            }
          }
        }
      }
      if (add) {
        workflows.push({ workflow, parent, priority, children: null });
      }
    }
    workflows.sort((a, b) => a.priority - b.priority);
    let root = {};

    for (let i = 0; i < workflows.length; i++) {
      let workflow = workflows[i].workflow;
      let parent = workflows[i].parent;
      if (!isEmpty(parent)) {
        let child = null;
        let title = isEmpty(workflow.title) ? workflow.name : workflow.title;
        child = { workflow: workflow, title };

        if (isNull(root[parent])) {
          root[parent] = [];
          workflows[i].children = root[parent];
        }
        root[parent].push(child);
      }
    }
    let drawerMenu = {};
    let drawerButtons = [];
    for (let i = 0; i < workflows.length; i++) {
      let workflow = workflows[i].workflow;
      let title = isEmpty(workflow.title) ? workflow.name : workflow.title;
      let parent = workflows[i].parent;
      let children = workflows[i].children;
      if (!isEmpty(parent)) {
        if (!isEmpty(children)) {
          let menuItems = [];
          let buttonMenu = {};
          for (let j = 0; j < children.length; j++) {
            let child = children[j];
            menuItems.push({
              url: "",
              menuNames: [parent, child.title],
              workflow: child.workflow,
            });
            buttonMenu[child.title] = [
              { url: "", menuNames: [child.title], workflow: child.workflow },
            ];
          }
          drawerMenu[parent] = menuItems;
          drawerButtons.push({
            buttonName: parent,
            action: undefined,
            buttonMenu: buttonMenu,
          });
        }
      } else {
        let action = { url: "", menuNames: [title], workflow: workflow };
        drawerMenu[title] = [action];
        drawerButtons.push({
          buttonName: title,
          action: action,
          buttonMenu: undefined,
        });
      }
    }
    return { drawerMenu, drawerButtons };
  };

  const [focused, setFocused] = useState({});

  const [defaultFormValues] = useState(() => {
    let defaultVal = undefined;
    let isExisting = false;

    defaultVal = getInitialData(
      undefined,
      onSubmit,
      rootDefinition.current,
      null,
      isExisting
    );
    return defaultVal;
  });

  const [initialValues, setInitialValues] = useState(() => {
    const { isPlainForm, isSearch, item, formName, entityId } = props;
    let initial = undefined;
    let isExisting = item && item.id > 0 ? true : false;
    if ((isSearch || isPlainForm) && entityFactory) {
      let cacheVal = null;
      if (!isSearch) {
        const queryParams = new URLSearchParams(window.location.search);
        let allFields = isEmpty(rootDefinition.current?.fields)
          ? []
          : definitionHelper.findAllTopLevelDBFields(
              rootDefinition.current.fields
            );
        var values = null;
        allFields.forEach((field) => {
          let val = queryParams.get(field.fieldName);
          if (val !== null) {
            if (values === null) values = {};
            values[field.fieldName] = val;
          }
        });
        if (values === null) {
          cacheVal = formCache[formName];
        } else {
          cacheVal = JSON.stringify(values);
        }
        // if (window.location.pathname.toLowerCase() === "/page/messageresult") {
        //   const message = queryParams.get("message");
        //   const subject = queryParams.get("subject");
        //   cacheVal = JSON.stringify({ message, subject });
        // } else {
        //   cacheVal = formCache[formName];
        // }
      } else {
        cacheVal = formCache["s_" + entityFactory.entityName()];
      }
      if (!isNull(cacheVal)) {
        //console.log("JSON.parse(cacheVal)", JSON.parse(cacheVal).values)
        initial = JSON.parse(cacheVal);
      }
    }
    if (isNull(initial)) {
      initial = getInitialData(
        entityId,
        onSubmit,
        rootDefinition.current,
        props.item,
        isExisting
      );
    }
    initial["____versionId"] = item?.versionId;
    if (isTask) {
      initial["____id"] = item?._guid;
    } else {
      initial["____id"] = item?.id;
    }
    return initial;
  });

  const [state, setState] = useState(() => {
    const { subDefinition, item, flatten, renderLevel, screenSize } = props;
    let renderDefinition = getDefinition(
      screenSize,
      subDefinition,
      item,
      flatten,
      renderLevel,
      subDefField,
      rootDefinition
    );
    let mappedFields = definitionHelper.findAllDisplayFieldsMapped(
      renderDefinition.fields,
      false
    );
    let isExisting = props.item && props.item.id > 0 ? true : false;
    let template = undefined;
    if (
      !isNull(renderDefinition.formTemplate) &&
      (props.isSearch !== true || !props.isGrid)
    ) {
      template = renderDefinition.formTemplate;
    }

    let initialState = {
      definition: renderDefinition,
      rootFields: mappedFields.rootFields,
      workflowFields: mappedFields.workflowFields,
      isExisting: isExisting,
      template: template,
    };

    let hideWorkflowButtons = false || props.readOnly || props.hideActionButton;
    if (
      !hideWorkflowButtons &&
      entityFactory &&
      !isNull(entityFactory.definition) &&
      !isNull(entityFactory.definition.hideWorkflowButtons)
    ) {
      hideWorkflowButtons = entityFactory.definition.hideWorkflowButtons;
    }
    if (!isNull(props.hideWorkflowButtons)) {
      hideWorkflowButtons = props.hideWorkflowButtons;
    } else {
      hideWorkflowButtons = isNull(props.thread);
    }
    let { drawerMenu, drawerButtons } = getWafelMenu(null, hideWorkflowButtons);
    initialState.drawerMenu = drawerMenu;
    initialState.drawerButtons = drawerButtons;
    initialState.hideWorkflowButtons = hideWorkflowButtons;

    return initialState;
  });

  const [ruleState, setRuleState] = useState(() => {
    const {
      bulkItems,
      thread,
      step,
      layoutGraph,
      subDefinition,
      item,
      flatten,
      renderLevel,
      match,
      isNew,
      isSearch,
      searchBarTop,
      screenSize,
      isPlainForm,
      formName,
      update,
    } = props;
    let initialState = null;
    if ((isSearch || isPlainForm) && entityFactory) {
      let cacheVal = null;
      if (!isSearch) {
        cacheVal = formCache[formName];
      } else {
        cacheVal = formCache["s_" + entityFactory.entityName()];
      }
      if (!isNull(cacheVal)) {
        initialState = {};
        initialState.hideRuleState = getHideRuleState(
          currentUser,
          state.definition,
          initialValues
        );
        initialState.readOnlyRuleState = getReadOnlyRuleState(
          currentUser,
          state.definition,
          initialValues
        );
        if (isSearch && update)
          update([
            calcFormDataToMutationInput(
              entityFactory,
              bulkItems,
              thread,
              step,
              layoutGraph,
              subDefinition,
              item,
              flatten,
              renderLevel,
              match,
              isNew,
              isSearch,
              searchBarTop,
              screenSize,
              subDefField,
              rootDefinition,
              isTask,
              initialState.hideRuleState,
              initialValues
            ),
          ]);
      }
    }
    if (isNull(initialState)) {
      initialState = {};
      initialState.hideRuleState = getHideRuleState(
        currentUser,
        state.definition,
        initialValues
      );
      initialState.readOnlyRuleState = getReadOnlyRuleState(
        currentUser,
        state.definition,
        initialValues
      );
    }
    return initialState;
  });

  const formDataToMutationInput = (formData) => {
    const {
      bulkItems,
      thread,
      step,
      layoutGraph,
      subDefinition,
      item,
      flatten,
      renderLevel,
      match,
      isNew,
      isSearch,
      searchBarTop,
      screenSize,
    } = props;
    return calcFormDataToMutationInput(
      entityFactory,
      bulkItems,
      thread,
      step,
      layoutGraph,
      subDefinition,
      item,
      flatten,
      renderLevel,
      match,
      isNew,
      isSearch,
      searchBarTop,
      screenSize,
      subDefField,
      rootDefinition,
      isTask,
      ruleState.hideRuleState,
      formData
    );
  };

  const getCurrentThreadFormData = useCallback(
    (formData, includeAll) => {
      return calcCurrentThreadFormData(
        entityFactory,
        props.bulkItems,
        props.thread,
        props.layoutGraph,
        props.subDefinition,
        props.item,
        props.flatten,
        props.renderLevel,
        props.match,
        props.isNew,
        props.isSearch,
        props.searchBarTop,
        screenSize,
        subDefField,
        rootDefinition,
        isTask,
        ruleState.hideRuleState,
        formData,
        includeAll
      );
    },
    [
      ruleState.hideRuleState,
      entityFactory,
      props.bulkItems,
      props.thread,
      props.layoutGraph,
      props.subDefinition,
      props.item,
      props.flatten,
      props.renderLevel,
      props.match,
      props.isNew,
      props.isSearch,
      props.searchBarTop,
      screenSize,
      isTask,
    ]
  );

  const getTaskStateFromProps = useCallback(() => {
    let nextState = {};
    let definition = getDefinition(
      screenSize,
      props.subDefinition,
      props.item,
      props.flatten,
      props.renderLevel,
      subDefField,
      rootDefinition
    );
    //nextState.____guid = props.item?._guid;
    nextState.definition = definition;
    nextState.isExisting = props.item && props.item.id > 0 ? true : false;
    //nextState.versionId = props.item && props.item.versionId ? props.item.versionId : null;
    let template = undefined;
    if (!isNull(definition.formTemplate)) {
      if (
        isNull(state.definition) ||
        isNull(state.template) ||
        state.definition.formTemplate !== definition.formTemplate
      ) {
        template = definition.formTemplate;
      } else {
        template = state.template;
      }
    }
    nextState.template = template;
    let mappedFields = definitionHelper.findAllDisplayFieldsMapped(
      definition.fields,
      false
    );
    nextState.rootFields = mappedFields.rootFields;
    nextState.workflowFields = mappedFields.workflowFields;
    return nextState;
  }, [
    screenSize,
    props.subDefinition,
    props.item,
    props.flatten,
    props.renderLevel,
    state.definition,
    state.template,
  ]);

  useEffect(() => {
    if (isTask) {
      if (
        props.item?.versionId !== initialValues?.____versionId ||
        props.item?._guid !== initialValues?.____id
      ) {
        rootDefinition.current = definitionHelper.fixWorkflowFormDefinition(
          props.formDefinition
        );

        let newState = getTaskStateFromProps();

        let initial = getInitialData(
          props.entityId,
          onSubmit,
          newState.definition,
          props.item,
          props.isNew
        );
        initial["____versionId"] = props.item?.versionId;
        initial["____id"] = props.item?._guid;
        setInitialValues(initial);
        setState(newState);
      }
    } else {
      if (
        props.item?.versionId !== initialValues?.____versionId ||
        props.item?.id !== initialValues?.____id
      ) {
        let initial = undefined;
        if (props.item && props.isPlainForm !== true) {
          let isExisting = props.item.id > 0;
          initial = getInitialData(
            props.entityId,
            onSubmit,
            rootDefinition.current,
            props.item,
            isExisting
          );
          initial["____versionId"] = props.item?.versionId;
          initial["____id"] = props.item?.id;
        }
        if (!isNull(initial)) {
          setInitialValues(initial);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.item,
    props.isPlainForm,
    props.entityId,
    isTask,
    props.formDefinition,
    props.isNew,
    initialValues?.____versionId,
    initialValues?.____id,
  ]);

  const getUpdate = useCallback(() => {
    if (isTask) {
      return props.updateThread;
    } else {
      return props.update;
    }
  }, [isTask, props.updateThread, props.update]);

  const getUpdateChain = useCallback(
    (queryParams) => {
      let update = getUpdate();
      if (isTask) {
        let chunkSize = 500000;
        let threadStateData = queryParams.threadStateData;
        if (isEmpty(threadStateData) || threadStateData.length <= chunkSize) {
          queryParams.pageNo = 1;
          queryParams.pageCount = 1;
          return update([queryParams]);
        } else if (!isNull(threadStateData)) {
          let chunks = [];
          for (
            let i = 0, charCount = threadStateData.length;
            i < charCount;
            i += chunkSize
          ) {
            chunks.push(threadStateData.substring(i, i + chunkSize));
          }
          delete queryParams.threadStateData;
          queryParams.pageCount = chunks.length;
          let first = { ...queryParams };
          first.pageNo = 1;
          first.threadStateData = chunks[0];
          let chain = update([first]);
          for (let i = 1; i < chunks.length; i++) {
            let page = { ...queryParams };
            page.pageNo = i + 1;
            page.threadStateData = chunks[i];
            chain = chain.then((response) => {
              if (
                response &&
                response.data &&
                response.data.updateThreads &&
                response.data.updateThreads.length > 0 &&
                (isNull(response.data.updateThreads[0].isError) ||
                  response.data.updateThreads[0].isError === false)
              ) {
                return update([page]);
              } else {
                if (
                  response &&
                  response.data &&
                  response.data.updateThreads &&
                  response.data.updateThreads.length > 0 &&
                  response.data.updateThreads[0].isError === true
                ) {
                  throw new Error(response.data.updateThreads[0].display);
                } else {
                  throw new Error("Unknown error.");
                }
              }
            });
          }
          return chain;
        }
      } else {
        return update([queryParams]);
      }
    },
    [isTask, getUpdate]
  );

  const postAction = useCallback(
    (result) => {
      if (isTask) {
        if (!isEmpty(result?.data?.updateThreads)) {
          let display = result.data.updateThreads[0].display;
          let ignoreMessages = [
            "Please wait for data to be processed",
            "Please wait for data to be transferred",
            "Current task is still processing",
          ];
          let ignore = ignoreMessages.indexOf(display) >= 0;
          if (!ignore) {
            onInvalidate(result.data.updateThreads[0].timeStamp);
          } else {
            console.log("SKIPPING POSTACTION EVENT");
          }
        }
      }
    },
    [isTask, onInvalidate]
  );

  const doUpdate = useCallback(
    (queryParams, data) => {
      getUpdateChain(queryParams)
        .catch((res) => {
          if (res.graphQLErrors) {
            const errors = res.graphQLErrors.map((error) => {
              return error.message;
            });
            for (let i = 0; i < errors.length; i++) {
              Toast.toast(`[API Error]: ` + errors[i].message, 5000);
              console.log("ERROR!", errors[i].message);
            }
          }
          //formActions.setSubmitting(false);
          throw res;
        })
        .then((result) => {
          console.log("Update Successful!");
          postAction(result);
          //formActions.setSubmitting(false);
          if (data && methods?.reset) {
            //Clear the dirty flag.
            methods.reset(data);
          }
          if (onSubmit) {
            onSubmit(props.formName);
          }
        })
        .catch((error) => {
          //formActions.setSubmitting(false);
          Toast.toast(`[Update Error]: ` + error.toString());
          console.log("Update failed: ", error.toString());
        });
    },
    [getUpdateChain, postAction, onSubmit, props.formName, methods?.reset]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFormSubmit = (data) => {
    const {
      subDefinition,
      item,
      flatten,
      renderLevel,
      layoutGraph,
      bulkItems,
      searchBarTop,
      screenSize,
    } = props;
    if (isTask) {
      //formActions.setSubmitting(true);
      onWafelClick(true, "", data);
    } else {
      //formActions.setSubmitting(true);
      // make async call
      //console.log("data", data)
      //console.log("submit: ", coreFormDataToMutationInput(props, data, false));
      let queryParams = {};
      if (!props.bulkItems || props.bulkItems.length === 0) {
        queryParams = formDataToMutationInput(data);
      } else {
        queryParams = formDataToBulkMutationInput(
          layoutGraph?.currentUser,
          subDefinition,
          item,
          flatten,
          renderLevel,
          ruleState.hideRuleState,
          data,
          bulkItems,
          searchBarTop,
          screenSize
        );
      }
      doUpdate(queryParams, data);
    }
  };

  const formDataToBulkMutationInput = (
    currentUser,
    subDefinition,
    item,
    flatten,
    renderLevel,
    hideRuleState,
    formData,
    bulkItems,
    searchBarTop,
    screenSize
  ) => {
    //const { fields } = rootDefinition.current;
    let definition = getDefinition(
      screenSize,
      subDefinition,
      item,
      flatten,
      renderLevel,
      subDefField,
      rootDefinition
    );
    const { fields } = definition;

    let queryParams = {};
    queryParams.ids = bulkItems;
    let updateFields = [];

    let allFields = findAllFieldsAndValue(
      currentUser,
      definition,
      formData,
      fields,
      hideRuleState,
      searchBarTop,
      screenSize,
      true
    );
    for (let i = 0; i < allFields.length; i++) {
      const field = allFields[i].field;
      if (field.type !== "object") {
        let value = allFields[i].value;
        if (value && value.select) {
          if (field.type === "boolean") {
            queryParams[field.fieldName] = value.prop ? true : false;
          } else {
            if (field.type === "array" && field.inPlace) {
              queryParams[field.fieldName] = JSON.stringify(value.prop);
            } else {
              queryParams[field.fieldName] = value.prop;
            }
          }
          updateFields.push(field.fieldName);
        }
      }
    }

    if (updateFields.length > 0) {
      queryParams.updateFields = updateFields;
    } else {
      queryParams = {};
    }
    return queryParams;
  };

  const executeActionOnCurrentItem = useCallback(
    (workflow, additional = {}) => {
      let entityId = props.item?.id ? props.item?.id : null;
      if (!workflow?.requiresEntityId) {
        executeAction(
          workflow,
          setProcessing,
          onSubmit,
          entityFactory,
          null,
          additional
        );
      } else if (!isNull(entityId)) {
        executeAction(
          workflow,
          setProcessing,
          onSubmit,
          entityFactory,
          entityId
        );
      }
    },
    [executeAction, props.item?.id, onSubmit, entityFactory]
  );

  const renderRight = (
    handleSubmit,
    handleReset,
    dirty,
    isSubmitting,
    errors
  ) => {
    if (isTask) {
      const { screenSize, step } = props;
      let maxExits =
        screenSize === SCREEN.SMALL
          ? 0
          : screenSize === SCREEN.MEDIUM
          ? 4
          : 100;
      let exits = step?.exits ? step?.exits : [];
      for (let i = 0; i < exits.length; i++) {
        let exit = exits[i];
        if (exit.isDefault) {
          if (!displayExitButton(exit)) {
            maxExits += 1;
          }
          break;
        }
      }
      let { buttons, exitCount } = getLeftWafelButtons(dirty, maxExits);
      let rightButtons = getRightWafelButtons(
        handleSubmit,
        handleReset,
        dirty,
        isSubmitting,
        errors,
        props.publicStyle,
        maxExits,
        exitCount
      ); //row-reverse
      buttons = [...buttons, ...rightButtons];
      return (
        <View style={{ flexDirection: "row-reverse" }}>
          <View style={{ flexDirection: "row" }}>{buttons}</View>
        </View>
      );
    } else {
      return [];
    }
  };

  const displayActionButton = (action) => {
    const { workflowFields } = state;
    let addButton = true;
    if (workflowFields && workflowFields[action.name.toLowerCase()]) {
      addButton =
        workflowFields[action.name.toLowerCase()].allowInHeader === true;
    }
    return addButton;
  };

  const displayExitButton = (exit) => {
    const { workflowFields } = state;
    let addButton = true;
    if (workflowFields && workflowFields["exit!" + exit.name.toLowerCase()]) {
      addButton =
        workflowFields["exit!" + exit.name.toLowerCase()].allowInHeader ===
        true;
    }
    return addButton;
  };

  const onPreviousClick = useCallback(
    (exitId) => {
      let queryParams = {
        ids: [thread.id],
        transactionId: chance.guid(),
        timeStamp:
          thread && !isNull(thread.timeStamp) ? thread.timeStamp : null,
        stepId: step.id,
        exitId: exitId,
        script: "",
        workflowName: thread.workflowName,
        threadStateData: thread.threadStateData,
        updateFields: [],
      };

      doUpdate(queryParams);
    },
    [thread, step, doUpdate]
  );

  const getRightWafelButtons = (
    handleSubmit,
    handleReset,
    dirty,
    isSubmitting,
    errors,
    publicStyle,
    maxExits,
    exitCount
  ) => {
    let { step } = props;
    const { debugMode } = taskState;

    let buttons = [];
    if (
      isSubmitting ||
      processing ||
      (props.thread && props.thread.processing)
    ) {
      buttons.push(
        <Loading
          messageOnly={true}
          messageVariant={"subtitle1"}
          color={"#ff0000"}
          inverse={true}
          direction={"row-reverse"}
          key={"processing"}
          message={"Processing ..."}
        />
      );
    } else {
      for (let i = step.exits.length - 1; i >= 0; i--) {
        let exit = step.exits[i];
        if (!exit.isDefault) {
          if (displayExitButton(exit)) {
            exitCount += 1;
            if (exitCount <= maxExits) {
              let buttonTitle = debugMode
                ? "exit!" + exit.name.toLowerCase()
                : isEmpty(exit.title)
                ? exit.name
                : exit.title;
              buttons.push(
                <Button
                  key={`exit-${i}`}
                  size={"small"}
                  variant={undefined}
                  color={"inherit"}
                  onClick={() => {
                    onPreviousClick(exit.id);
                  }}
                  style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
                >
                  {buttonTitle}
                </Button>
              );
            } else {
              break;
            }
          }
        }
      }

      for (let i = 0; i < step.exits.length; i++) {
        let exit = step.exits[i];
        if (exit.isDefault) {
          if (displayExitButton(exit)) {
            let buttonTitle = debugMode
              ? "exit!" + exit.name.toLowerCase()
              : isEmpty(exit.title)
              ? exit.name
              : exit.title;
            buttons.push(
              <Suspense
                key={"btn_exit_" + i}
                fallback={<Skeleton animation="wave" width={160} height={40} />}
              >
                <WafelSubmitButton
                  topbar={true}
                  formAction={{ style: "success", name: "" }}
                  onClick={onWafelClick}
                  disabled={false}
                  title={buttonTitle}
                  publicstyle={publicStyle}
                />
              </Suspense>
            );
          }
          /*
          <Button
            key={"btn_exit_" + i}
            success
            style={{ marginLeft: 10, justifyContent: "center", width: buttonTitle.length <= 9 ? 75 : undefined }}
            onPress={handleSubmit}><Text style={{ color: "white" }}>{buttonTitle}</Text></Button>
          */
        }
      }
    }
    return buttons;
  };

  const getCurrentDebugHistory = (props) => {
    if (props && props.thread) {
      return {
        processing: props.thread.processing ? "True" : "False",
        timeStamp: props.thread.timeStamp,
        threadStateData: props.thread.threadStateData
          ? JSON.stringify(JSON.parse(props.thread.threadStateData))
          : "NO TASK DATA",
      };
    } else {
      return {
        processing: "NO DATA",
        timeStamp: "NO DATA",
        threadStateData: props.thread.threadStateData
          ? JSON.stringify(JSON.parse(props.thread.threadStateData))
          : "NO TASK DATA",
      };
    }
  };

  const onDebugStart = () => {
    if (!isEmpty(taskState.debugHistory)) {
      setTaskState({ ...taskState, debugPosition: 0 });
    }
  };

  const onDebugEnd = () => {
    if (!isEmpty(taskState.debugHistory)) {
      setTaskState({
        ...taskState,
        debugPosition: taskState.debugHistory.length - 1,
      });
    }
  };

  const onDebugNext = () => {
    if (
      !isEmpty(taskState.debugHistory) &&
      taskState.debugPosition < taskState.debugHistory.length - 1
    ) {
      setTaskState({
        ...taskState,
        debugPosition: taskState.debugPosition + 1,
      });
    }
  };

  const onDebugPrev = () => {
    if (!isEmpty(taskState.debugHistory) && taskState.debugPosition > 0) {
      setTaskState({
        ...taskState,
        debugPosition: taskState.debugPosition - 1,
      });
    }
  };

  const getNewDebugHistory = (props) => {
    if (isEmpty(taskState.debugHistory)) {
      return [getCurrentDebugHistory(props)];
    } else {
      return [...taskState.debugHistory, getCurrentDebugHistory(props)];
    }
  };

  /*
  onWafelPayClick(script, values, formActions, tokenId) {
    let { thread, step } = props;
    let newThreadFormData = getCurrentThreadFormData(state.formData, false);
    newThreadFormData.__payment_token_id = tokenId;
    let queryParams = {
      ids: [thread.id,],
      transactionId: chance.guid(),
      timeStamp: thread && !isNull(thread.timeStamp) ? thread.timeStamp : null,
      stepId: step.id,
      exitId: 0,
      script: script,
      workflowName: thread.workflowName,
      threadStateData: JSON.stringify(newThreadFormData),
      updateFields: []
    };
    doUpdate(queryParams, formActions);
  }
  */
  const renderSearch = (
    renderBody,
    screenSize,
    handleSubmit,
    handleReset,
    dirty,
    isSubmitting,
    errors,
    searchBarTop
  ) => {
    return (
      <View
        style={{
          flex: 1,
          zIndex: 999,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            flex: 1,
            zIndex: 999,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingRight: 10,
          }}
        >
          <IconButton
            aria-label="switchsearch"
            size="small"
            color="inherit"
            onClick={props.onChangeSearchMode}
          >
            {searchBarTop ? (
              <PlayCircleFilledIcon />
            ) : (
              <ArrowDropDownCircleIcon />
            )}
          </IconButton>
          {searchBarTop ? renderBody : undefined}
        </View>
        <View
          disabled={isSubmitting || Object.keys(errors).length > 0}
          style={{ flexDirection: "row", marginLeft: 10 }}
        >
          {screenSize > SCREEN.SMALL ? (
            <Button
              size={"small"}
              variant={undefined}
              color={"inherit"}
              disabled={false}
              onClick={() => {
                handleReset(defaultFormValues, { keepDirty: false });
              }}
              style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
            >
              {"Reset"}
            </Button>
          ) : undefined}
          <Button
            size={"small"}
            variant={"contained"}
            color={"secondary"}
            onClick={() => {
              handleSubmit(onFormSubmit)();
            }}
            style={{
              marginLeft: 10,
              marginTop: 10,
              marginBottom: 10,
              marginRight: 5,
            }}
          >
            {"Search"}
          </Button>
        </View>
      </View>
    );
  };

  const renderTitle = (isSubmitting) => {
    if (isTask) {
      const {
        history,
        screenSize,
        isModal,
        hasSideBar,
        menuOnlyHasHome,
        formName,
      } = props;

      let title = "";
      if (!isNull(state?.definition)) {
        if (screenSize === SCREEN.SMALL) {
          title = state.definition.short
            ? state.definition.short
            : state.definition.title;
        } else {
          title = state.definition.title
            ? state.definition.title
            : state.definition.short;
        }
        if (isNull(title)) title = "";
      }
      if ((screenSize === SCREEN.SMALL || isModal) && title.length > 15) {
        title = title.substring(0, 12) + "...";
      }
      const showBack = false; // screenSize === SCREEN.SMALL;
      return (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            zIndex: 2200,
            height: 50,
          }}
        >
          {!hasSideBar && !menuOnlyHasHome ? (
            <IconButton
              aria-label={showBack ? "back" : "menu"}
              disabled={false}
              color="inherit"
              style={{ marginRight: 10 }}
              onClick={() =>
                showBack ? history.goBack() : openMenuActionDrawer()
              }
            >
              {showBack ? <ArrowBackIcon /> : <MenuIcon />}
            </IconButton>
          ) : (
            <View style={{ width: 10 }} />
          )}
          {renderLogo(32, history, onDebug)}
          {state.definition && !isEmpty(state.definition.title) ? (
            <Typography
              key={"frt"}
              variant="h6"
              display="block"
              color={"inherit"}
              style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
            >
              {taskState.debugMode ? formName : title}
            </Typography>
          ) : undefined}
        </View>
      );
    } else {
      /*
          <SearchBar round lightTheme
            inputContainerStyle={{ backgroundColor: "white", borderWidth: 1, borderBottomWidth: 1 }} 
            containerStyle={{ backgroundColor: 'rgba(52, 52, 52, 0)', borderTop: 0, borderBottom: 0 }}
            searchIcon={undefined} 
            placeholder="General search..." />
          */
      if (isSubmitting) {
        return (
          <Loading
            messageOnly={true}
            messageVariant={"subtitle1"}
            color={"#ff0000"}
            inverse={true}
            direction={"row-reverse"}
            key={"processing"}
            message={"Processing ..."}
          />
        );
        //} else {
        /*
        let { entityFactory } = props;
        let pageTitle = "";
        if (entityFactory && !isNull(entityFactory.definition) && !isEmpty(entityFactory.definition.title)) {
          pageTitle = entityFactory.definition.title;
        }
        return dirty && !state.hideWorkflowButtons
          ? <View style={{ flexDirection: "row-reverse" }}>
            <Button 
              success 
              disabled={isSubmitting || Object.keys(errors).length > 0} 
              onPress={handleSubmit}>
                <Text uppercase={null}>
                  Submit
                </Text>
            </Button>
            <Button 
              danger 
              disabled={isSubmitting || !dirty} 
              onPress={handleReset}>
                <Text uppercase={null}>
                  Discard
                </Text>
            </Button>
          </View>
          : (pageTitle.length > 0
            ? <Text uppercase={null}>{pageTitle}</Text>
            : undefined)
        */
      }
    }
  };

  const renderTemplateField = (fieldName, style) => {
    const { layoutGraph } = props;
    const { rootFields, definition } = state;
    const { hideRuleState } = ruleState;
    const currentUser = layoutGraph ? layoutGraph.currentUser : null;

    let field = rootFields[fieldName];
    let notFound = isNull(field);
    let hidden = notFound;
    if (!hidden) {
      hidden = fieldHidden(
        currentUser,
        definition,
        field,
        hideRuleState,
        props.searchBarTop,
        props.screenSize
      );
    }

    if (notFound) {
      return fieldName ? (
        <View
          style={{
            flex: 1,
            zIndex: 1,
            flexDirection: "row",
            alignItems: "flex-end",
          }}
          key={"tmpl_" + fieldName.toString()}
        >
          <Typography variant="body1" display="block" color={"textSecondary"}>
            Field Not Found: {fieldName}
          </Typography>
        </View>
      ) : null;
    } else if (hidden) {
      return null;
    } else {
      let elementStyle = style ? { ...style } : {};
      let autoStyle = emptyObject;
      if (
        elementStyle.fontFamily ||
        elementStyle.fontSize ||
        elementStyle.fontWeight
      ) {
        //This will break memoization and result in more renders, but will work.
        autoStyle = {
          fontFamily: elementStyle.fontFamily,
          fontSize: elementStyle.fontSize,
          fontWeight: elementStyle.fontWeight,
        };
      }
      let cell = renderWidget(field, elementStyle.flex, autoStyle);
      if (isNull(elementStyle.width)) elementStyle.width = "100%";
      if (isNull(elementStyle.zIndex)) elementStyle.zIndex = 1;
      return (
        <View style={{ ...elementStyle }} key={"tmpl_" + fieldName.toString()}>
          <Suspense
            fallback={<Skeleton animation="wave" width="100%" height={40} />}
          >
            {cell}
          </Suspense>
        </View>
      );
    }
  };

  const onWafelSubmit = useCallback(
    (isDefault, script, newThreadFormData, event) => {
      if (isTask) {
        let nextStepId = 0;
        let valid = true;
        if (isDefault) {
          nextStepId = 1;
          for (let i = 0; i < step.exits.length; i++) {
            let exit = step.exits[i];
            if (exit.isDefault) {
              nextStepId = exit.id;
              break;
            }
          }
        } else {
          valid = !isEmpty(script);
          if (valid) {
            if (script.startsWith("exit!")) {
              let exitName = script.substring(5).toLowerCase();
              valid = false;
              if (!isNull(step)) {
                for (let i = 0; i < step.exits.length; i++) {
                  let exit = step.exits[i];
                  if (exit.name.toLowerCase() === exitName) {
                    valid = true;
                    break;
                  }
                }
              }
            } else if (script.indexOf("!") < 0) {
              let actionName = script.toLowerCase();
              valid = false;
              if (!isNull(step)) {
                for (let i = 0; i < step.actions.length; i++) {
                  let action = step.actions[i];
                  if (action.name.toLowerCase() === actionName) {
                    valid = true;
                    break;
                  }
                }
                if (!valid) {
                  for (let i = 0; i < step.exits.length; i++) {
                    let exit = step.exits[i];
                    if (exit.name.toLowerCase() === actionName) {
                      valid = true;
                      break;
                    }
                  }
                }
              }
            }
          }
        }

        if (valid) {
          let queryParams = {
            ids: [thread.id],
            transactionId: chance.guid(),
            timeStamp:
              thread && !isNull(thread.timeStamp) ? thread.timeStamp : null,
            stepId: step.id,
            exitId: nextStepId,
            script: isDefault ? "" : script,
            workflowName: thread.workflowName,
            threadStateData: JSON.stringify(newThreadFormData),
            event: isNull(event) ? undefined : JSON.stringify(event),
            updateFields: [],
          };
          doUpdate(queryParams);
        }
      }
    },
    [isTask, thread, step, doUpdate]
  );

  const renderWidget = (field, flex, style, cellScreenSize) => {
    const {
      layoutGraph,
      allEntityFactories,
      screenSize,
      isSearch,
      searchBarTop,
      item,
      thread,
      step,
      bulkItems,
      publicStyle,
      changeLoginState,
    } = props;
    const { definition } = state;
    const { hideRuleState, readOnlyRuleState } = ruleState;
    const currentUser = layoutGraph ? layoutGraph.currentUser : null;
    //const layoutLoading = layoutGraph ? layoutGraph.loading : true;
    if (isNull(cellScreenSize)) cellScreenSize = screenSize;

    let fieldTitle = isNull(field.title) ? field.fieldName : field.title;
    let Widget = getWidget(isSearch, field);
    let disabled =
      fieldIsReadonly(
        entityFactory,
        layoutGraph,
        item,
        bulkItems,
        thread,
        currentUser,
        item && item.id > 0,
        field
      ) || fieldReadOnlyByRule(definition, field.fieldName, readOnlyRuleState);
    let definitionfield = field;
    if (field.type === "button") {
      let dirtyAware = false;
      let valid = !isNull(field.workflow);
      if (valid) {
        if (
          !isNull(step) &&
          !isNull(thread) &&
          field.workflow.length > 5 &&
          field.workflow.startsWith("exit!")
        ) {
          valid = false;
          let exitName = field.workflow.substring(5).toLowerCase();
          for (let i = 0; i < step.exits.length; i++) {
            let exit = step.exits[i];
            if (exit.name.toLowerCase() === exitName) {
              if (exit.isDefault) {
                let buttonTitle = isNull(field.title)
                  ? isEmpty(exit.title)
                    ? exit.name
                    : exit.title
                  : field.title;
                //formAction={{ style: isEmpty(field.style) ? "success" : field.style, name: "" }}
                return (
                  <WafelSubmitButton
                    definitionfield={definitionfield}
                    flex={flex}
                    topbar={false}
                    key={"btn_exit_" + i}
                    onClick={onWafelClick}
                    disabled={isProcessing || disabled}
                    title={buttonTitle}
                    publicstyle={publicStyle}
                  />
                );
              } else {
                valid = true;
              }
            }
          }
        }
        if (
          !isNull(step) &&
          !isNull(thread) &&
          !field.workflow.startsWith("exit!") &&
          !field.workflow.startsWith("login!") &&
          !field.workflow.startsWith("register!")
        ) {
          valid = false;
          let actionName = field.workflow.toLowerCase();
          for (let i = 0; i < step.actions.length; i++) {
            let action = step.actions[i];
            dirtyAware = isNull(action.dirtyAware) ? false : action.dirtyAware;
            if (action.name.toLowerCase() === actionName) {
              valid = true;
              break;
            }
          }
          if (!valid) {
            for (let i = 0; i < step.exits.length; i++) {
              let exit = step.exits[i];
              if (exit.name.toLowerCase() === actionName) {
                valid = true;
                break;
              }
            }
          }
        }
      }
      //console.log(">>> dirtyAware", dirtyAware);
      if (valid) {
        //btnStyle={!isEmpty(field.style) ? field.style : "default"}
        return (
          <Widget
            key={"workflow_" + field.fieldName}
            flex={flex}
            dirtyAware={dirtyAware}
            changeLoginState={changeLoginState}
            onClick={onWafelClick}
            workflow={
              isEmpty(field.workflow) ? field.fieldName : field.workflow
            }
            definitionfield={definitionfield}
            width={isEmpty(field.width) ? undefined : field.width}
            publicstyle={publicStyle}
            disabled={isProcessing}
          />
        );
      } else {
        return <View />;
      }
    } else if (field.type === "login") {
      return (
        <Widget
          currentUser={currentUser}
          publicstyle={publicStyle}
          definitionfield={definitionfield}
          disabled={disabled}
          title={definitionfield.title}
          changeLoginState={changeLoginState}
          screenSize={cellScreenSize}
        />
      );
    } else {
      return (
        <Widget
          style={style}
          width={isEmpty(field.width) ? undefined : field.width}
          flex={flex}
          getcurrentthreadformdata={getCurrentThreadFormData}
          onwafelsubmit={onWafelSubmit}
          insearchbar={searchBarTop && isSearch}
          isSearch={isSearch}
          hiderulestate={hideRuleState}
          thread={thread}
          processing={isProcessing}
          onWafelClick={onWafelClick}
          item={item}
          bulkitems={bulkItems}
          entityfactory={entityFactory}
          isnew={!(item && item.id > 0)}
          focused={focused}
          screensize={cellScreenSize}
          disabled={disabled && isSearch !== true}
          definitionfield={definitionfield}
          allentityfactories={allEntityFactories}
          layoutgraph={isNull(layoutGraph) ? emptyObject : layoutGraph}
          rootdefinition={definition}
          currentuser={currentUser}
          setfocus={setFocus}
          placeholder={field.tooltip}
          name={field.fieldName}
          label={fieldTitle}
          required={fieldIsRequired(field)}
          publicstyle={publicStyle}
          workflow={field.workflow}
        />
      );
    }
  };

  const onWafelClick = useCallback(
    (isDefault, script, values) => {
      if (isTask) {
        if (script.startsWith("exit!")) {
          let exitId = undefined;
          let exitName = script.substring(5).toLowerCase();
          let exits = !isNull(step) && !isNull(step.exits) ? step.exits : [];
          for (let i = 0; i < exits.length; i++) {
            let exit = exits[i];
            if (exit.name.toLowerCase() === exitName) {
              exitId = exit.id;
              break;
            }
          }
          if (!isNull(exitId)) {
            onPreviousClick(exitId);
          }
        } else {
          if (!isDefault) {
            let stepActions =
              !isNull(step) && !isNull(step.actions) ? step.actions : [];
            for (let i = 0; i < stepActions.length; i++) {
              let stepAction = stepActions[i];
              if (stepAction.name.toLowerCase() === script.toLowerCase()) {
                script = stepAction.name;
                break;
              }
            }
          }
          let newThreadFormData = getCurrentThreadFormData(values, false);
          onWafelSubmit(isDefault, script, newThreadFormData, undefined);
        }
      } else {
        let allWorkflows = !isEmpty(layoutGraph?.allWorkflows)
          ? layoutGraph.allWorkflows
          : [];
        let workflow = undefined;
        for (let j = 0; j < allWorkflows.length; j++) {
          let workflowIter = allWorkflows[j];
          if (workflowIter.name === script) {
            workflow = workflowIter;
            break;
          }
        }
        if (!isNull(workflow)) {
          if (props.isPlainForm && !isNull(values)) {
            executeActionOnCurrentItem(workflow, values);
          } else {
            executeActionOnCurrentItem(workflow);
          }
        }
      }
    },
    [
      props.isPlainForm,
      isTask,
      layoutGraph?.allWorkflows,
      step,
      onPreviousClick,
      onWafelSubmit,
      getCurrentThreadFormData,
      executeActionOnCurrentItem,
    ]
  );

  const renderField = (
    props,
    flexDirection,
    hideRuleState,
    focused,
    rootDefinition,
    layoutLoading,
    currentUser,
    formDefinition,
    section,
    field,
    isFocused,
    displayMode,
    cellScreenSize,
    rowCount,
    limitFields,
    renderLevel
  ) => {
    //let allThreads = layoutGraph ? layoutGraph.allThreads : [];
    //let element = <View key={"tg_" + field.fieldName} />
    if (field.type === "object") {
      isFocused = false;
      let ariaLabel = isEmpty(field.title) ? field.fieldName : field.title;
      let definitionResults = renderDefinition(
        props,
        ariaLabel,
        flexDirection,
        hideRuleState,
        focused,
        rootDefinition,
        layoutLoading,
        currentUser,
        field.definition,
        "_" + field.fieldName,
        cellScreenSize,
        limitFields,
        renderLevel + 1
      );
      let definitionElement = definitionResults[0];
      isFocused = definitionResults[1];
      let elementWithTitle = definitionElement;

      if (!isNull(definitionElement) && !isNull(field.title)) {
        elementWithTitle = [];
        elementWithTitle.push(
          <View key={"title_" + field.fieldname}>
            <Typography variant="h6" display="block" color={"textPrimary"}>
              {field.title}
            </Typography>
          </View>
        );
        elementWithTitle.push(definitionElement);
      }

      return [
        //MBJHERE
        // eslint-disable-next-line react/jsx-key
        <View
          style={{
            flex: 1,
            zIndex: 999,
            flexDirection: "column",
            width: "100%",
          }}
        >
          {elementWithTitle}
        </View>,
        isFocused,
      ];
    } else {
      return [
        // eslint-disable-next-line react/jsx-key
        <View
          style={{
            flex: 1,
            zIndex: isFocused ? 999 : 1,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 5,
          }}
        >
          <Suspense
            fallback={<Skeleton animation="wave" width="100%" height={40} />}
          >
            {renderWidget(field, undefined, BLANK_STYLE, cellScreenSize)}
          </Suspense>
        </View>,
        false,
      ];
    }
  };

  const setFocus = useCallback(
    (fieldName, focus) => {
      let newFocused = {}; //...state.focused
      if (focus) {
        let parts = fieldName.split(".");
        newFocused[parts[0]] = focus;
        if (parts.length > 1) {
          newFocused[parts[0] + "." + parts[1]] = focus;
          if (parts.length > 2) {
            newFocused[parts[0] + "." + parts[1] + "." + parts[2]] = focus;
            if (parts.length > 3) {
              newFocused[
                parts[0] + "." + parts[1] + "." + parts[2] + "." + parts[3]
              ] = focus;
              if (parts.length > 4) {
                newFocused[
                  parts[0] +
                    "." +
                    parts[1] +
                    "." +
                    parts[2] +
                    "." +
                    parts[3] +
                    "." +
                    parts[4]
                ] = focus;
              }
            }
          }
        }
      }
      //let renderBody = renderFormBody(props, focused, state.definition)
      setFocused(newFocused);
    },
    [setFocused]
  );

  const renderSection = (
    props,
    flexDirection,
    hideRuleState,
    focused,
    rootDefinition,
    layoutLoading,
    currentUser,
    formDefinition,
    section,
    isFirstSection,
    displayMode,
    screenSize,
    limitFields,
    renderLevel,
    isRoot
  ) => {
    let cells = [];
    let cellIsFocused = [];
    let rows = [];
    let fields = formDefinition.fields;
    let columns =
      isNull(section.columns) || section.columns < 1 ? 1 : section.columns;
    let cellScreenSize = calcRelativeCellScreenSize(columns, screenSize);
    let totalCells = 0;

    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      let hidden = fieldHidden(
        currentUser,
        rootDefinition,
        field,
        hideRuleState,
        props.searchBarTop,
        props.screenSize
      );
      if (
        hidden !== true &&
        ((isFirstSection && isEmpty(field.tab)) || field.tab === section.id)
      ) {
        totalCells += 1;
      }
    }
    let rowCount = Math.trunc(totalCells / columns);
    if (totalCells % columns > 0) {
      rowCount += 1;
    }
    let sectionFocused = false;
    //var div = Math.trunc(y/x);
    //var rem = y % x;
    if (totalCells > 0) {
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        let hidden =
          fieldHidden(
            currentUser,
            rootDefinition,
            field,
            hideRuleState,
            props.searchBarTop,
            props.screenSize
          ) ||
          (!isNull(limitFields) && i > limitFields);
        if (
          hidden !== true &&
          (!(
            field.type === "object" ||
            (field.type === "json" && field.inPlace) ||
            (field.type === "array" && field.inPlace)
          ) ||
            (field.definition &&
              field.definition.fields &&
              field.definition.fields.length > 0)) &&
          ((isFirstSection && isEmpty(field.tab)) || field.tab === section.id)
        ) {
          let fieldResults = renderField(
            props,
            flexDirection,
            hideRuleState,
            focused,
            rootDefinition,
            layoutLoading,
            currentUser,
            formDefinition,
            section,
            field,
            isFocused,
            displayMode,
            cellScreenSize,
            rowCount,
            limitFields,
            renderLevel
          );
          let cell = fieldResults[0];
          let isFocused = focused[field.fieldName] || fieldResults[1];
          cellIsFocused.push(isFocused);
          cells.push(
            <View
              style={{
                flex: isNull(field.width)
                  ? isNull(field.tableWidth)
                    ? 1
                    : field.tableWidth
                  : undefined,
                width: isNull(field.width) ? undefined : field.width,
                zIndex: isFocused ? 999 : 1,
                flexDirection: field.type === "object" ? "column" : "row",
                alignItems:
                  field.type === "object" ? "space-between" : "flex-end",
              }}
              key={"col_" + i.toString()}
            >
              {cell}
            </View>
          );
        }
      }

      for (let i = 0; i < rowCount; i++) {
        let rowElements = [];
        let rowFocus = false;
        for (let j = 0; j < columns; j++) {
          let current = i * columns + j;
          rowElements.push(cells[current]);
          if (cellIsFocused[current]) {
            rowFocus = true;
            sectionFocused = true;
          }
        }
        if (rowElements.length > 0) {
          rows.push(
            <View
              style={{ flexDirection: "row", zIndex: rowFocus ? 999 : 1 }}
              key={"row" + section.id + "_" + i.toString()}
            >
              {rowElements}
            </View>
          );
        }
      }
    }
    let elementsInDiv = [];
    if (rows.length > 0) {
      elementsInDiv.push(
        <View
          style={{
            zIndex: sectionFocused ? 999 : 1,
            flexDirection: flexDirection,
          }}
          key={"grid_" + section.id}
        >
          {rows}
        </View>
      );
    }
    //let eventKey = formDefinition.entityName + ":" + section.id;
    let sectionTitle = isNull(section.title) ? section.id : section.title;
    if (elementsInDiv.length === 0) {
      return [undefined, false];
    } else if (
      displayMode === "tabs" ||
      displayMode === "pills" ||
      (displayMode === "panels" && isFirstSection) ||
      displayMode === "tabbar"
    ) {
      // I seem to need the height on the view ... even though it's ignored.
      // Otherwise no scrollbars.
      return [
        <TabBar.Item
          style={{ flex: 1, zIndex: sectionFocused ? 999 : 1 }}
          type={displayMode}
          key={"st_" + section.id}
          icon={section.icon}
          title={sectionTitle}
          publicStyle={props.publicStyle}
        >
          <ScrollView
            style={{
              height: 100,
              flex: 1,
              zIndex: sectionFocused ? 999 : 1,
              backgroundColor: section.backgroundColor,
            }}
            contentContainerStyle={{
              flex: 1,
              zIndex: sectionFocused ? 999 : 1,
              paddingTop: 10,
            }}
            nestedScrollEnabled={true}
          >
            {elementsInDiv}
          </ScrollView>
        </TabBar.Item>,
        sectionFocused,
      ];
    } else {
      if (isEmpty(section.title)) {
        return [
          <View
            key={"st_" + section.id}
            style={{
              backgroundColor: section.backgroundColor,
              zIndex: sectionFocused ? 999 : 1,
              flexDirection: flexDirection,
              paddingTop: isRoot ? 10 : 0,
              flex: displayMode === "panels" ? 1 : undefined,
            }}
          >
            {elementsInDiv}
          </View>,
          sectionFocused,
        ];
      } else {
        //<Text h1>{section.title}</Text>
        if (!isEmpty(elementsInDiv) && flexDirection === "column") {
          let sectionStyle = props.publicStyle?.form?.sectionStyle
            ? props.publicStyle?.form?.sectionStyle
            : {};
          elementsInDiv = [
            <View
              key={"title_" + section.id}
              style={{
                marginRight: 10,
                marginLeft: 10,
                ...sectionStyle,
              }}
            >
              <Typography variant="h6" display="block" color={"textPrimary"}>
                {section.title}
              </Typography>
            </View>,
            ...elementsInDiv,
          ];
        }
        return [
          <View
            key={"st_" + section.id}
            style={{
              backgroundColor: section.backgroundColor,
              zIndex: sectionFocused ? 999 : 1,
              flexDirection: flexDirection,
              paddingTop: isRoot ? 10 : 0,
            }}
          >
            {elementsInDiv}
          </View>,
          sectionFocused,
        ];
      }
    }
  };

  const renderSections = (
    props,
    flexDirection,
    hideRuleState,
    focused,
    rootDefinition,
    layoutLoading,
    currentUser,
    formDefinition,
    displayMode,
    screenSize,
    limitFields,
    renderLevel,
    isRoot
  ) => {
    let elements = [];
    let positions = [];
    let sections = formDefinition.sections;
    let definitionFocused = false;
    for (let i = 0; i < sections.length; i++) {
      let section = formDefinition.sections[i];
      if (
        displayMode === "tabs" ||
        displayMode === "pills" ||
        displayMode === "panels" ||
        displayMode === "tabbar"
      ) {
        let sectionResults = renderSection(
          props,
          flexDirection,
          hideRuleState,
          focused,
          rootDefinition,
          layoutLoading,
          currentUser,
          formDefinition,
          section,
          i === 0,
          displayMode,
          screenSize,
          limitFields,
          renderLevel,
          isRoot
        );
        let sectionElement = sectionResults[0];
        let sectionFocused = sectionResults[1];
        if (sectionFocused) definitionFocused = true;
        if (sectionElement) {
          elements.push(sectionElement);
          positions.push(section.position);
        }
        //} else {
        //  let eventKey = null;
        //  eventKey = formDefinition.entityName + ":" + section.id;
        //  if (displayMode === "tabs") {
        //    let sectionTitle = isNull(section.title) ? section.id : section.title;
        //    elements.push(<TabBar.Item key={"rst_" + section.id} title={sectionTitle}>
        //        <ScrollView style={{ height: 10 }}>
        //        </ScrollView>
        //      </TabBar.Item>);
        //  } else if (displayMode === "pills") {
        //    elements.push(<TabBar.Item key={"rst_" + section.id} title={sectionTitle}>
        //        <ScrollView style={{ height: 10 }}>
        //        </ScrollView>
        //      </TabBar.Item>);
        //  }
        //}
      } else {
        let sectionResults = renderSection(
          props,
          flexDirection,
          hideRuleState,
          focused,
          rootDefinition,
          layoutLoading,
          currentUser,
          formDefinition,
          section,
          i === 0,
          displayMode,
          screenSize,
          limitFields,
          renderLevel,
          isRoot
        );
        let sectionElement = sectionResults[0];
        let sectionFocused = sectionResults[1];
        if (sectionFocused) definitionFocused = true;
        if (sectionElement) {
          elements.push(sectionElement);
          positions.push(section.position);
        }
      }
    }
    return [elements, positions, definitionFocused];
  };

  const renderDefinition = (
    props,
    ariaLabel,
    flexDirection,
    hideRuleState,
    focused,
    rootDefinition,
    layoutLoading,
    currentUser,
    formDefinition,
    fieldName,
    screenSize,
    limitFields,
    renderLevel
  ) => {
    let elements = [];
    if (layoutLoading || isNull(currentUser) || isNull(formDefinition))
      return elements;

    let displayMode = getDisplayMode(formDefinition);
    let isTabBar =
      displayMode === "tabs" ||
      displayMode === "pills" ||
      displayMode === "panels" ||
      displayMode === "tabbar";
    let isRoot = isEmpty(fieldName);
    let sectionsResults = renderSections(
      props,
      flexDirection,
      hideRuleState,
      focused,
      rootDefinition,
      layoutLoading,
      currentUser,
      formDefinition,
      displayMode,
      screenSize,
      limitFields,
      renderLevel,
      isRoot
    );
    let pageElements = sectionsResults[0];
    let positions = sectionsResults[1];
    let definitionFocused = sectionsResults[2];

    if (isTabBar) {
      elements.push(
        <TabBar
          positions={positions}
          ariaLabel={ariaLabel}
          style={{ zIndex: definitionFocused ? 999 : 1 }}
          type={displayMode}
          id={
            "f_" +
            (isTask ? props.formName : rootDefinition.entityName) +
            "_" +
            props.subDefinition +
            fieldName
          }
          key={"rdt_" + formDefinition.entityName}
          activeKey={undefined}
          screenSize={screenSize}
          publicStyle={props.publicStyle}
        >
          {pageElements}
        </TabBar>
      );
    } else {
      elements.push(...pageElements);
    }
    if (elements.length === 0)
      elements.push(
        <View
          style={{
            zIndex: definitionFocused ? 999 : 1,
            flexDirection: "column",
          }}
          key={"rdt_" + rootDefinition.entityName}
        />
      );
    return [elements, definitionFocused];
  };

  const renderTemplate = () => {
    const {
      layoutGraph,
      notificationSystem,
      allEntityFactories,
      navigationDefaults,
      item,
      screenSize,
      publicStyle,
    } = props;
    const { definition } = state;
    const { hideRuleState } = ruleState;
    //const currentUser = layoutGraph ? layoutGraph.currentUser : null;
    //const layoutLoading = layoutGraph ? layoutGraph.loading : true;
    return (
      <View style={{ flex: 1 }}>
        <FormRoot>
          <FormTemplate
            onWafelClick={onWafelClick}
            screenSize={screenSize}
            renderField={renderTemplateField}
            hideRuleState={hideRuleState}
            entityFactory={entityFactory}
            definition={definition}
            selected={false}
            item={initialValues}
            versionId={item ? item.versionId : undefined}
            allEntityFactories={allEntityFactories}
            layoutGraph={layoutGraph}
            navigationDefaults={navigationDefaults}
            notificationSystem={notificationSystem}
            publicStyle={publicStyle}
          />
        </FormRoot>
      </View>
    );
  };

  const renderCalendar = (definitionField, screenSize) => {
    return (
      <View style={{ flex: 1 }}>
        <Suspense
          fallback={<Skeleton animation="wave" width="100%" height={40} />}
        >
          {renderWidget(definitionField, undefined, BLANK_STYLE, screenSize)}
        </Suspense>
      </View>
    );
  };

  const renderFormBody = (hideRuleState, focused, definition, template) => {
    const {
      layoutGraph,
      item,
      isSearch,
      searchBarTop,
      renderLevel,
      screenSize,
      match,
      isNew,
    } = props;
    let currentUser = layoutGraph ? layoutGraph.currentUser : null;
    const layoutLoading = layoutGraph ? layoutGraph.loading : true;
    if (!isEmpty(template)) return renderTemplate();
    /*
    return <ScrollView style={{ flex: 1, flexDirection: "column", width: "100%" }}>
      <TextField placeholder="Enter Name" name="name" label={"Name"} />
      <TextField placeholder="Enter Description" name="description" label={"Description"} />
    </ScrollView>;*/
    if (
      definition.fields.length === 1 &&
      definition.fields[0].type === "calendar"
    ) {
      return renderCalendar(definition.fields[0], screenSize);
    }

    let flexDirection = isSearch && searchBarTop ? "row" : "column";
    let currentRenderLevel = isNull(renderLevel) ? 0 : renderLevel;
    let ariaLabel = isEmpty(definition?.title)
      ? isEmpty(entityFactory)
        ? "Unknown"
        : entityFactory.lowerCaseEntityName()
      : definition.title;
    let definitionResults = renderDefinition(
      props,
      ariaLabel,
      flexDirection,
      hideRuleState,
      focused,
      definition,
      layoutLoading,
      currentUser,
      definition,
      "",
      screenSize,
      isSearch ? (searchBarTop ? MAX_SEARCHBAR_WIDGETS : undefined) : undefined,
      currentRenderLevel
    );
    let elements = definitionResults[0];
    let isNewMode =
      (match && match.params && match.params.entityId === "new") || isNew;

    let body = undefined;
    if (elements && elements.length === 1) {
      body = (
        <View
          style={{
            flex: 1,
            marginTop: isSearch ? undefined : 0,
            marginBottom: isSearch && searchBarTop ? 10 : undefined,
          }}
        >
          {layoutLoading ? (
            <View
              style={{
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></View>
          ) : undefined}
          {!isSearch && !layoutLoading && isNull(item) && !isNewMode ? (
            <Typography variant="body1" display="block" color={"textSecondary"}>
              Item not found.
            </Typography>
          ) : undefined}
          {!layoutLoading && currentUser && currentUser.id ? (
            <FormRoot>{elements[0]}</FormRoot>
          ) : undefined}
        </View>
      );
    } else {
      body = (
        <View
          style={{
            flex: 1,
            marginBottom: isSearch && searchBarTop ? 10 : undefined,
          }}
        >
          {layoutLoading ? (
            <View
              style={{
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></View>
          ) : undefined}
          {!isSearch && !layoutLoading && isNull(item) && !isNewMode ? (
            <Typography variant="body1" display="block" color={"textSecondary"}>
              Item not found.
            </Typography>
          ) : undefined}
          {!layoutLoading && currentUser && currentUser.id ? (
            <FormRoot>{elements}</FormRoot>
          ) : undefined}
        </View>
      );
    }
    if (definition.displayMode === "None" && !(isSearch && searchBarTop)) {
      if (isSearch || definition.autoTemplate !== true) {
        return (
          <ScrollView
            style={{ height: isSearch ? 80 : 80, flex: 1 }}
            contentContainerStyle={{ flex: 1 }}
            nestedScrollEnabled={true}
          >
            {body}
          </ScrollView>
        );
      } else {
        return (
          <View
            style={{
              flex: 1,
              width: "100%",
              justifyContent: "center",
              flexDirection: "row",
              backgroundImage: "linear-gradient(white 20%, DarkGray 100%)",
            }}
          >
            <View
              style={{
                flex: 1,
                height: "100%",
                maxWidth: 600,
                flexDirection: "column",
                backgroundColor: TOKENS?.backgroundColor,
                boxShadow: "0px 0px 10px 5px rgb(190,190,190)",
                border: "1px solid rgb(170,170,170)",
              }}
            >
              <ScrollView
                style={{ height: isSearch ? 80 : 80, flex: 1 }}
                contentContainerStyle={{ flex: 1 }}
                nestedScrollEnabled={true}
              >
                {body}
              </ScrollView>
            </View>
          </View>
        );
      }
    } else {
      if (isSearch || definition.autoTemplate !== true) {
        return body;
      } else {
        return (
          <View
            style={{
              flex: 1,
              width: "100%",
              justifyContent: "center",
              flexDirection: "row",
              backgroundImage: "linear-gradient(white 20%, DarkGray 100%)",
            }}
          >
            <View
              style={{
                flex: 1,
                height: "100%",
                maxWidth: 600,
                flexDirection: "column",
                backgroundColor: TOKENS?.backgroundColor,
                boxShadow: "0px 0px 10px 5px rgb(190,190,190)",
                border: "1px solid rgb(170,170,170)",
              }}
            >
              {body}
            </View>
          </View>
        );
      }
    }
  };

  const renderFormBase = (
    entityFactory,
    renderBody,
    screenSize,
    handleSubmit,
    handleReset,
    dirty,
    isSubmitting,
    errors,
    isSearch,
    searchBarTop
  ) => {
    return (
      <View
        style={{
          flex:
            isSearch && searchBarTop
              ? undefined
              : isSearch && !searchBarTop && screenSize === SCREEN.SMALL
              ? 2
              : 1,
          zIndex: 999,
        }}
      >
        {isSearch && props.isGrid ? (
          <AppBar position="static" color="transparent">
            <View style={{ zIndex: 999 }}>
              {renderSearch(
                renderBody,
                screenSize,
                handleSubmit,
                handleReset,
                dirty,
                isSubmitting,
                errors,
                searchBarTop
              )}
            </View>
          </AppBar>
        ) : undefined}
        {isSearch && props.isGrid && searchBarTop ? (
          <Suspense
            fallback={<Skeleton animation="wave" width="100%" height={40} />}
          >
            <FieldList definition={state.definition} screenSize={screenSize} />
          </Suspense>
        ) : undefined}
        {!isSearch &&
        (isNull(state.hideWorkflowButtons) || !state.hideWorkflowButtons) ? (
          <AppBar position="static" color="primary">
            <View style={{ flexDirection: "row" }}>
              <View style={{ justifyContent: "center", flexDirection: "row" }}>
                {renderTitle(isSubmitting)}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "flex-end",
                }}
              >
                {renderRight(
                  handleSubmit,
                  handleReset,
                  dirty,
                  isSubmitting,
                  errors
                )}
              </View>
            </View>
          </AppBar>
        ) : undefined}
        {searchBarTop ? undefined : renderBody}
        {isSearch ? (
          <Persist name={"s_" + entityFactory.entityName()} />
        ) : undefined}
      </View>
    );
  };

  const renderTaskForm = (
    currentUser,
    values,
    publicStyle,
    entityFactory,
    renderBody,
    screenSize,
    handleSubmit,
    handleReset,
    dirty,
    isSubmitting,
    errors,
    isSearch,
    searchBarTop
  ) => {
    const { isOpen, debugMode, debugPosition, debugHistory } = taskState;
    const { classes, theme } = props;

    let siteName = getEnvironment().SITE_NAME;
    if (isEmpty(siteName)) {
      siteName = "Xelleron";
    }
    let speedDialButtons = getSpeedDialButtons();
    return (
      <View style={{ flex: 1, flexDirection: "column", width: "100%" }}>
        <Drawer
          ref={(ref) => {
            _menuActionDrawer.current = ref;
          }}
          openDrawerOffset={screenSize ? screenSize * 0.2 + 0.2 : 0.2}
          panCloseMask={screenSize ? screenSize * 0.2 + 0.2 : 0.2}
          content={
            <View
              style={{
                flex: 1,
                backgroundColor: "#ffffff",
                flexDirection: "column",
              }}
            >
              <MainDrawer
                currentUser={currentUser}
                title={siteName}
                renderLogo={renderLogo}
                history={props.history}
                changeLoginState={props.changeLoginState}
                drawerMenu={props.drawerMenu}
                closeDrawer={closeMenuActionDrawer}
                publicStyle={publicStyle}
              />
            </View>
          }
          onClose={() => closeMenuActionDrawer()}
        >
          <View
            style={{
              display: "flex",
              flex: 1,
              paddingLeft: props.hasSideBar ? 64 : 0,
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "stretch",
              width: "100vw",
            }}
          >
            {debugMode && screenSize && !isNull(props.thread) ? (
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <View
                  style={{
                    flex: 3,
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                    height: "100%",
                  }}
                >
                  {renderFormBase(
                    entityFactory,
                    renderBody,
                    screenSize,
                    handleSubmit,
                    handleReset,
                    dirty,
                    isSubmitting,
                    errors,
                    isSearch,
                    searchBarTop
                  )}
                </View>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                    height: "100%",
                  }}
                >
                  <ScrollView
                    style={{ height: 100, flex: 1 }}
                    contentContainerStyle={{ flex: 1 }}
                    nestedScrollEnabled={true}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "stretch",
                        width: "100%",
                      }}
                    >
                      <Button
                        size={"small"}
                        variant={"contained"}
                        color={"inherit"}
                        onClick={onDebugStart}
                        style={{ flex: 1, justifyContent: "center" }}
                        disabled={debugPosition < 1}
                        startIcon={<SkipPreviousIcon />}
                      >
                        Start
                      </Button>
                      <Button
                        size={"small"}
                        variant={"contained"}
                        color={"inherit"}
                        onClick={onDebugPrev}
                        style={{ flex: 1, justifyContent: "center" }}
                        disabled={debugPosition < 1}
                        startIcon={<FastRewindIcon />}
                      >
                        Back
                      </Button>
                      <Button
                        size={"small"}
                        variant={"contained"}
                        color={"inherit"}
                        onClick={onDebugNext}
                        style={{ flex: 1, justifyContent: "center" }}
                        disabled={
                          isEmpty(debugHistory) ||
                          debugPosition === debugHistory.length - 1
                        }
                        startIcon={<FastForwardIcon />}
                      >
                        Next
                      </Button>
                      <Button
                        size={"small"}
                        variant={"contained"}
                        color={"inherit"}
                        onClick={onDebugEnd}
                        style={{ flex: 1, justifyContent: "center" }}
                        disabled={
                          isEmpty(debugHistory) ||
                          debugPosition === debugHistory.length - 1
                        }
                        startIcon={<SkipNextIcon />}
                      >
                        End
                      </Button>
                    </View>
                    <Typography>
                      Processing:{" "}
                      {debugPosition >= 0
                        ? debugHistory[debugPosition].processing
                        : "NO DATA"}
                    </Typography>
                    <Typography>
                      Timestamp:{" "}
                      {debugPosition >= 0
                        ? debugHistory[debugPosition].timeStamp
                        : "NO DATA"}
                    </Typography>
                    <Typography>
                      {debugPosition >= 0
                        ? debugHistory[debugPosition].threadStateData
                        : "NO TASK DATA"}
                    </Typography>
                  </ScrollView>
                </View>
              </View>
            ) : (
              renderFormBase(
                entityFactory,
                renderBody,
                screenSize,
                handleSubmit,
                handleReset,
                dirty,
                isSubmitting,
                errors,
                isSearch,
                searchBarTop
              )
            )}
            {speedDialButtons.length > 0 ? (
              <SpeedDial
                ariaLabel="Additional Form Actions"
                className={classes.speedDial}
                hidden={false}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={isOpen ? true : false}
                direction={"up"}
              >
                {speedDialButtons.map((button) => {
                  let isDisabled = button.dirtyAware && !dirty;
                  return (
                    <SpeedDialAction
                      key={button.buttonName}
                      disabled={isDisabled}
                      icon={
                        <IconButton
                          aria-label={button.buttonName}
                          color="inherit"
                        >
                          <TabBarIcon
                            icon={
                              button.icon
                                ? button.icon
                                : "arrow-forward-outline"
                            }
                            size={20}
                            color={
                              isDisabled
                                ? theme.palette.text.disabled
                                : theme.palette.primary.main
                            }
                          />
                        </IconButton>
                      }
                      tooltipTitle={button.buttonName}
                      tooltipOpen
                      onClick={() => {
                        //if (button.action?.workflow) executeAction(button.action.workflow, entityId);
                        onWafelClick(false, button.script, values);
                        handleClose();
                      }}
                    />
                  );
                })}
              </SpeedDial>
            ) : undefined}
          </View>
        </Drawer>
      </View>
    );
  };

  const renderForm = (
    currentUser,
    values,
    publicStyle,
    entityFactory,
    renderBody,
    screenSize,
    handleSubmit,
    handleReset,
    dirty,
    isSubmitting,
    errors,
    isSearch,
    searchBarTop
  ) => {
    if (isTask) {
      return renderTaskForm(
        currentUser,
        values,
        publicStyle,
        entityFactory,
        renderBody,
        screenSize,
        handleSubmit,
        handleReset,
        dirty,
        isSubmitting,
        errors,
        isSearch,
        searchBarTop
      );
    } else {
      return renderFormBase(
        entityFactory,
        renderBody,
        screenSize,
        handleSubmit,
        handleReset,
        dirty,
        isSubmitting,
        errors,
        isSearch,
        searchBarTop
      );
    }
  };

  const getSpeedDialButtons = () => {
    const { screenSize, step } = props;
    if (screenSize >= SCREEN.LARGE) return [];
    let maxExits =
      screenSize === SCREEN.SMALL ? 0 : screenSize === SCREEN.MEDIUM ? 4 : 100;
    let exitCount = 0;

    let exits = !isNull(step) && !isNull(step.exits) ? step.exits : [];
    for (let i = 0; i < exits.length; i++) {
      let exit = exits[i];
      if (exit.isDefault) {
        if (!displayExitButton(exit)) {
          maxExits += 1;
        }
        break;
      }
    }

    let buttons = [];
    let stepActions =
      !isNull(step) && !isNull(step.actions) ? step.actions : [];
    for (let i = 0; i < stepActions.length; i++) {
      let stepAction = stepActions[i];
      if (isEmpty(stepAction.local)) {
        if (displayActionButton(stepAction)) {
          exitCount += 1;
          if (exitCount > maxExits) {
            buttons.push({
              icon: stepAction.icon,
              buttonName: isEmpty(stepAction.title)
                ? stepAction.name
                : stepAction.title,
              script: stepAction.name,
              dirtyAware: stepAction.dirtyAware,
            });
          }
        }
      }
    }

    for (let i = 0; i < exits.length; i++) {
      let exit = exits[i];
      if (!exit.isDefault) {
        if (displayExitButton(exit)) {
          exitCount += 1;
          if (exitCount > maxExits) {
            buttons.push({
              icon: exit.icon,
              buttonName: isEmpty(exit.title) ? exit.name : exit.title,
              script: "exit!" + exit.name.toLowerCase(),
            });
          }
        }
      }
    }

    return buttons;
  };

  const getLeftWafelButtons = (dirty, maxExits) => {
    let { step, publicStyle } = props;
    const { debugMode } = taskState;
    //let newThreadFormData = getCurrentThreadFormData(state.formData, true);
    let buttons = [];
    let exitCount = 0;

    if (props.thread && !props.thread.processing && !processing) {
      //let stepActions = [{ name: "save", title: "Add Contact", local: "add", focusSection: "financial", focusField: "name", entity: "contact", "varEntityField": "contactId" },];
      let stepActions =
        !isNull(step) && !isNull(step.actions) ? step.actions : [];

      if (stepActions.length > 0) {
        let actionButtons = [];
        for (let i = 0; i < stepActions.length; i++) {
          let stepAction = stepActions[i];
          if (!isEmpty(stepAction.local)) {
            if (stepAction.local === "add" || stepAction.local === "edit") {
              //} else if (stepAction.local === "pay" && !isEmpty(stepAction.name)) {
              //actionButtons.push(<Text>STRIPE</Text>);
              //actionButtons.push(<Elements><StripeButton style={{ marginRight: 5, marginBottom: 5, float: "left" }} bsStyle={!isEmpty(stepAction.style) ? stepAction.style : "default"} key={"workflow_" + stepAction.name} title={stepAction.title} onSubmit={isEmpty(stepAction.name) ? null : onWafelPayClick} price={111.22} /></Elements>);
            }
          } else {
            let display = true;
            const { workflowFields } = state;
            if (
              workflowFields &&
              workflowFields[stepAction.name.toLowerCase()]
            ) {
              display = displayActionButton(stepAction); //(workflowFields[stepAction.name.toLowerCase()].allowInHeader === true);
            }
            if (display) {
              exitCount += 1;
              if (exitCount <= maxExits) {
                if (stepAction.dirtyAware) {
                  //key={"My Action"} onClick={onWafelClick} script="myaction" title="My Action"
                  //btnStyle={dirty && !isEmpty(stepAction.style) ? stepAction.style : "default"}
                  //btnStyle={!isEmpty(stepAction.style) ? stepAction.style : "default"}
                  actionButtons.push(
                    <View
                      key={"wafel_" + stepAction.name}
                      style={{
                        marginLeft: 10,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <Suspense
                        fallback={
                          <Skeleton animation="wave" width={160} height={40} />
                        }
                      >
                        <WafelButton
                          definitionfield={{
                            size: "small",
                            variant: "text",
                            color: "inherit",
                          }}
                          topbar={true}
                          workflow={stepAction.name}
                          onClick={onWafelClick}
                          disabled={!dirty}
                          title={
                            debugMode
                              ? stepAction.name.toLowerCase()
                              : stepAction.title
                          }
                          width={
                            stepAction.title && stepAction.title.length <= 9
                              ? 75
                              : undefined
                          }
                          publicstyle={publicStyle}
                        />
                      </Suspense>
                    </View>
                  );
                } else {
                  actionButtons.push(
                    <View
                      key={"wafel_" + stepAction.name}
                      style={{
                        marginLeft: 10,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <Suspense
                        fallback={
                          <Skeleton animation="wave" width={160} height={40} />
                        }
                      >
                        <WafelButton
                          definitionfield={{
                            size: "small",
                            variant: "text",
                            color: "inherit",
                          }}
                          topbar={true}
                          workflow={stepAction.name}
                          onClick={onWafelClick}
                          disabled={false}
                          title={
                            debugMode
                              ? stepAction.name.toLowerCase()
                              : stepAction.title
                          }
                          width={
                            stepAction.title && stepAction.title.length <= 9
                              ? 75
                              : undefined
                          }
                          publicstyle={publicStyle}
                        />
                      </Suspense>
                    </View>
                  );
                }
              }
            }
          }
        }
        buttons.push(...actionButtons);
      }
      //if (props.screenSize > SCREEN.SMALL && !props.isModal) {
      //  buttons.push(<View style={{ marginLeft: buttons.length > 0 ? 10 : 0 }}><DiscardButton topbar={true} publicstyle={publicStyle} key={"btn_discard"} /></View>);
      //}
    }

    return { buttons, exitCount };
  };

  const applyRules = (definition, values, setFieldValue) => {
    let currentUser =
      props && props.layoutGraph ? props.layoutGraph.currentUser : null;
    let rules = isNull(definition.rules) ? [] : definition.rules;

    let applicableRules = rules.filter((rule) => !isEmpty(rule.copy));
    applicableRules.forEach((applicableRule) => {
      let triggerCheck = evaluateTriggerState(
        currentUser,
        definition,
        values,
        applicableRule
      );
      if (triggerCheck && !isEmpty(applicableRule.copy)) {
        applicableRule.copy.forEach((copyAction) => {
          let newValue = cloneDeep(values[copyAction.fromField]);
          if (
            JSON.stringify(newValue) !==
            JSON.stringify(values[copyAction.toField])
          ) {
            newValue = cloneDeep(newValue);
            setFieldValue(copyAction.toField, newValue);
          }
        });
      }
    });

    if (!props.readOnly) {
      applicableRules = rules.filter((rule) => !isEmpty(rule.clear));
      applicableRules.forEach((applicableRule) => {
        let triggerCheck = evaluateTriggerState(
          currentUser,
          definition,
          values,
          applicableRule
        );
        if (triggerCheck && !isEmpty(applicableRule.clear)) {
          applicableRule.clear.forEach((targetField) => {
            if (!isNull(values[targetField])) {
              setFieldValue(targetField, undefined);
            }
          });
        }
      });
    }

    let newHideRuleState = getHideRuleState(currentUser, definition, values);
    let newReadOnlyRuleState = getReadOnlyRuleState(
      currentUser,
      definition,
      values
    );
    let newState = null;
    if (
      JSON.stringify(newHideRuleState) !==
      JSON.stringify(ruleState.hideRuleState)
    ) {
      newState = { hideRuleState: newHideRuleState };
    }
    if (
      JSON.stringify(newReadOnlyRuleState) !==
      JSON.stringify(ruleState.readOnlyRuleState)
    ) {
      if (isNull(newState)) {
        newState = { readOnlyRuleState: newReadOnlyRuleState };
      } else {
        newState.readOnlyRuleState = newReadOnlyRuleState;
      }
    }
    if (!isNull(newState)) {
      setRuleState({ ...ruleState, ...newState });
    }
  };

  const { definition, template } = state;
  const methods = useForm({
    mode: isSearch ? "onSubmit" : "all",
    defaultValues: initialValues,
    resolver: yupResolver(entityFactory.getValidationSchema(definition)),
  });
  const { hideRuleState, readOnlyRuleState } = ruleState;

  useEffect(() => {
    if (props.setFormState && props.formName) {
      props.setFormState(
        props.formName,
        methods.formState.isDirty,
        methods.formState.isSubmitting,
        !isEmpty(methods.formState.errors),
        methods.handleSubmit,
        methods.reset,
        onFormSubmit,
        props.isSearch && !props.isGrid && entityFactory
          ? "/entities/" + entityFactory.lowerCasePluralName()
          : undefined
      );
    }
    let rules = isNull(definition.rules) ? [] : definition.rules;

    if (!isEmpty(rules)) {
      applyRules(definition, methods.getValues(), methods.setValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onFormSubmit,
    methods.formState.isDirty,
    methods.formState.isSubmitting,
    methods.handleSubmit,
    methods.reset,
    props.setFormState,
    props.formName,
    props,
    methods.formState.errors,
    entityFactory,
  ]);

  useEffect(() => {
    if (methods?.reset) {
      methods?.reset(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods?.reset, initialValues]);

  //let topPanel = searchBarTop || (isGrid && screenSize === SCREEN.SMALL);
  let renderBody = useMemo(() => {
    return renderFormBody(hideRuleState, focused, definition, template);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hideRuleState,
    readOnlyRuleState,
    focused,
    definition,
    template,
    props.layoutGraph?.currentUser,
    props.layoutGraph?.loading,
    props.item,
    props.isSearch,
    props.searchBarTop,
    entityFactory,
    props.renderLevel,
    props.screenSize,
    props.match,
    props.isNew,
    isProcessing,
    publicStyle,
  ]);
  let currentUser = layoutGraph ? layoutGraph.currentUser : null;
  //console.log(">>> initialValues", initialValues);
  //console.log("FORM RENDER", currentUser);
  return (
    <FormProvider
      {...methods}
      defaultValues={initialValues}
      onFormSubmit={onFormSubmit}
      processing={processing}
      setProcessing={setProcessing}
    >
      {renderForm(
        currentUser,
        methods.getValues(),
        publicStyle,
        entityFactory,
        renderBody,
        screenSize,
        methods.handleSubmit,
        methods.reset,
        methods.formState.isDirty,
        methods.formState.isSubmitting,
        methods.formState.errors,
        isSearch,
        searchBarTop
      )}
    </FormProvider>
  );
};

export default FormBase;
