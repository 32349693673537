import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { isEmpty } from "../../util/types";
import { getGlobalFilter } from "../../util/common";
import Constants from "expo-constants";

const NO_LAYOUT = Object.freeze({
  BaseComponentWithLayout: undefined,
  userId: undefined,
});

function getGraphQL(allEntityFactories) {
  let queryArray = [];
  for (let i = 0; i < allEntityFactories.length; i++) {
    let entity = allEntityFactories[i];
    if (entity.entityIsValidForLayout()) {
      queryArray.push(entity.getGraphQueryString());
    }
  }
  return queryArray.join("\n");
}

function getStateFromProps(
  BaseComponent,
  currentUserId,
  allEntityFactories,
  uiGraph,
  entityGraph
) {
  if (!currentUserId || isEmpty(allEntityFactories)) {
    return NO_LAYOUT;
  }

  let globalFilter = getGlobalFilter();
  let variables = { userId: currentUserId, globalFilter };
  let entitiesGraphString = getGraphQL(allEntityFactories);
  let globalFilterString = isEmpty(entitiesGraphString)
    ? ""
    : "($globalFilter: String)";
  let layoutQuery = gql(
    `
  query LayoutQuery ` +
      globalFilterString +
      ` {
    getAllThreads {
      id
      taskId
      stepId
      workflowName
      display
      userId
      userName
      timeStamp
      processing
      priority
    }       
    ` +
      entitiesGraphString +
      `
    currentUser {
      id
      userName
      name
      first
      middle
      surname
      roles
      groups
      authSource
      authState
      parentId
      userEntityName
      parentEntityName            
      pageId
      menuId
      uiVersion
    }
  }
  `
  );

  //CACHE POLICY MUST BE CACHE-FIRST OR TASK SUBSCRIPTIONS AND NETWORK QUERIES
  //CAN GET RACE CONDITIONS WITH NETWORK-ONLY
  let graphQuery = graphql(layoutQuery, {
    options() {
      return {
        fetchPolicy: "cache-first",
        fragments: {},
        variables: variables,
      };
    },
    props({ data }) {
      let layoutGraph = {
        currentUser: data.currentUser,
        loading: data.loading,
        allThreads: data.getAllThreads,
        subscribeToMore: data.subscribeToMore,
        refetch: data.refetch,
      };
      for (let i = 0; i < allEntityFactories.length; i++) {
        let entity = allEntityFactories[i];
        if (entity.entityIsValidForLayout()) {
          layoutGraph["all" + entity.definition.pluralName] =
            data["getAll" + entity.definition.pluralName];
        }
        if (data && data.loading === false) {
          layoutGraph["allEntities"] = entityGraph.allEntities;
          layoutGraph["allWorkflows"] = entityGraph.allWorkflows;

          if (uiGraph && entity.entityIsValidForUIGraph()) {
            layoutGraph["all" + entity.definition.pluralName] =
              uiGraph["all" + entity.definition.pluralName];
          }
        }
      }
      return {
        layoutGraph: layoutGraph,
      };
    },
  });
  let BaseComponentWithLayout = graphQuery(BaseComponent);
  return {
    BaseComponentWithLayout: BaseComponentWithLayout,
    userId: currentUserId,
  };
}

export default function withLayoutQuery(BaseComponent) {
  const withLayoutQueryFn = function WithLayoutQueryInner(props) {
    const { userGraph, client, allEntityFactories, uiGraph, entityGraph } =
      props;

    const currentUserId = userGraph?.currentUser?.id;
    const [state, setState] = useState(() => {
      return getStateFromProps(
        BaseComponent,
        currentUserId,
        allEntityFactories,
        uiGraph,
        entityGraph
      );
    });

    useEffect(() => {
      setState((previousState) => {
        if (
          previousState?.userId === currentUserId ||
          (!previousState?.userId && !currentUserId)
        )
          return previousState;
        client.cache.reset();
        return getStateFromProps(
          BaseComponent,
          currentUserId,
          allEntityFactories,
          uiGraph,
          entityGraph
        );
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allEntityFactories]);

    const { BaseComponentWithLayout } = state;

    const queryParams = new URLSearchParams(window.location.search);
    const newversion = queryParams.get("newversion");
    if (
      process.env.NODE_ENV === "development" &&
      !newversion &&
      userGraph?.currentUser?.uiVersion &&
      userGraph?.currentUser?.uiVersion !== Constants.manifest.version
    ) {
      window.location.search += "&newversion=1";
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    if (BaseComponentWithLayout)
      return (
        <BaseComponentWithLayout key={"bcwl"} {...props} client={undefined} />
      );
    return <BaseComponent {...props} />;
  };
  return withLayoutQueryFn;
}
