export const THEME = {
  palette: {
    primary: {
      main: "#b71c1c",
      light: "#f05545",
      dark: "#7f0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#424242",
      light: "#6d6d6d",
      dark: "#1b1b1b",
      contrastText: "#ff9800",
    },
  },
};

export const TOKENS = {
  inactiveColor: "#ccc",
  activeColor: "#2f95dc",
  backgroundColor: "#FFFFFF",
  foregroundColor: "#000000",
  borderColor: "#000000",
  primaryColor1: "#003b56",
  primaryColor2: "#003b56",
  primaryColor3: "#356d7f",
  primaryColor4: "#6da6b0",
  primaryColor5: "#63812c",
  primaryColor6: "#e1d8bb",
  formBackground1: "white",
  formBackground2: "DarkGray",
  formBorder: "rgb(170,170,170)",
  formShadow: "rgb(190,190,190)",
};
