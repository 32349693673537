/* eslint-disable react/prop-types */
import * as SplashScreen from "expo-splash-screen";
import React from "react";

class NativeAppLoading extends React.Component {
  constructor(props) {
    super(props);
    SplashScreen.preventAutoHideAsync();
  }

  componentWillUnmount() {
    if (this.props.autoHideSplash === false) {
      return;
    }
    setTimeout(SplashScreen.hideAsync, 200);
  }

  render() {
    return null;
  }
}

export default NativeAppLoading;
