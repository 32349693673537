import Constants from "expo-constants";
import { gql, useMutation } from "@apollo/client";
import Chance from "chance";
import { useCache } from "../hoc/withCache";
import { useHistory } from "../ReactRouter";

const chance = new Chance();

const UPDATE_THREADS_MUTATION = gql`
  mutation updateThreads($inputs: [ThreadInput!]!, $uiVersion: String) {
    updateThreads(inputs: $inputs, uiVersion: $uiVersion) {
      taskId
      stepId
      workflowName
      display
      userId
      userName
      timeStamp
      processing
      priority
    }
  }
`;

/*
{
      update(cache, { data: { addTodo } }) {
        const { todos } = cache.readQuery({ query: GET_TODOS });
        cache.writeQuery({
          query: GET_TODOS,
          data: { todos: todos.concat([addTodo]) },
        });
      }
    }
*/

export const useWorkflow = () => {
  const history = useHistory();
  const [updateMutation] = useMutation(UPDATE_THREADS_MUTATION);
  const [addMutation] = useMutation(UPDATE_THREADS_MUTATION, {
    onCompleted({ updateThreads }) {
      if (updateThreads && updateThreads.length > 0) {
        addWorkflowLog(
          updateThreads[0].taskId,
          updateThreads[0].timeStamp,
          false
        );
      }
    },
    update(cache, data) {
      cache.modify({
        fields: {
          getAllThreads(existingThreads = []) {
            var threads = [];
            data.data.updateThreads.forEach((thread) => {
              // An alternative to checking __ref? But perhaps it doesn't
              // guarantee it's in the updateThreads query results array itself ... just that 
              // some query has this thread in it.
              // const existingThread = cache.readFragment({
              //   id: "Thread:" + thread.taskId, // The value of the to-do item's cache ID
              //   fragment: gql`
              //     fragment ExistingThread on Thread {
              //       id
              //       taskId
              //       stepId
              //       workflowName
              //       display
              //       userId
              //       userName
              //       timeStamp
              //       processing
              //       priority
              //     }
              //   `,
              // });
              var exists = false;
              for (let i = 0; i < existingThreads.length; i++) {
                let existingThread = existingThreads[i];
                if (existingThread.__ref === "Thread:" + thread.taskId) {
                  exists = true;
                  break;
                }
              }
              if (!exists) {
                console.log(
                  ">> ADDING TASK DIRECTLY TO LAYOUTQUERY",
                  thread.taskId,
                  thread.processing,
                  thread.timeStamp
                );
                const newThread = cache.writeFragment({
                  data: {
                    id: thread.taskId,
                    ...thread,
                  },
                  fragment: gql`
                    fragment NewThread on Thread {
                      id
                      taskId
                      stepId
                      workflowName
                      display
                      userId
                      userName
                      timeStamp
                      processing
                      priority
                    }
                  `,
                });
                threads.push(newThread);
              }
            });
            return [...existingThreads, ...threads];
          },
        },
      });
    },
  });
  const { addWorkflowLog } = useCache();

  const addTask = (inputs) => {
    if (
      inputs &&
      inputs.length > 0 &&
      inputs[0].ids &&
      inputs[0].ids.length > 0
    ) {
      addWorkflowLog(inputs[0].ids[0], null, true);
    }
    return addMutation({
      variables: { inputs, uiVersion: Constants.manifest.version },
    });
  };

  const updateTask = (inputs) => {
    if (
      inputs &&
      inputs.length > 0 &&
      inputs[0].ids &&
      inputs[0].ids.length > 0
    ) {
      addWorkflowLog(inputs[0].ids[0], inputs[0].timeStamp, true);
    }

    return updateMutation({
      variables: { inputs, uiVersion: Constants.manifest.version },
    });
  };

  const doThreadAdd = (queryParams, setProcessing, onSubmit) => {
    console.log(">>> doThreadAdd");
    if (setProcessing) setProcessing(true);
    addTask([queryParams])
      .catch((res) => {
        console.log("doThreadAdd AddTask error", res);
        if (res.graphQLErrors) {
          const errors = res.graphQLErrors.map((error) => {
            return error.message;
          });
          for (let i = 0; i < errors.length; i++) {
            console.log("ERROR!", errors[i].message);
          }
        }
        if (setProcessing) setProcessing(false);
        throw res;
      })
      .then((result) => {
        /* UPDATE WORKFLOW LOG FOR ID CHANGE?
            let realNew = { ...queryParams[0] };
            realNew.id = result.taskId;
            addWorkflowLog(realNew.id, realNew.id, realNew.timeStamp, false);
        */
        if (onSubmit) {
          onSubmit();
        }
        if (setProcessing) setProcessing(false);
        console.log("Update Successful!");
        history.push("/threads/" + result.data.updateThreads[0].taskId);
      })
      .catch((res) => {
        console.log("doThreadAdd onSubmit error", res, onSubmit);
        return;
      });
  };

  const executeAction = (
    workflow,
    setProcessing,
    onSubmit,
    entityFactory = null,
    entityId = null,
    additional = {}
  ) => {
    if (
      workflow &&
      (!workflow.requiresEntityId ||
        (workflow.requiresEntityId &&
          entityId &&
          entityId > 0 &&
          entityFactory))
    ) {
      let threadId = chance.guid();
      let exitId = null;
      let stepId = workflow.initialStepId;
      if (workflow.requiresEntityId) {
        for (let i = 0; i < workflow.steps.length; i++) {
          let step = workflow.steps[i];
          if (step.id === stepId) {
            for (let j = 0; j < step.exits.length; j++) {
              let exit = step.exits[j];
              if (exit.isDefault) {
                exitId = exit.id;
                break;
              }
            }
            break;
          }
        }
      }

      let threadStateData = {
        ...additional,
        _guid: threadId,
        _guid_props: {
          type: "guid",
        },
      };

      if (workflow.requiresEntityId && !entityId) {
        console.log("ERROR: MISSING ENTITYID.  CANNOT LAUNCH WORKFLOW");
        return;
      }

      if (workflow.requiresEntityId) {
        threadStateData.id = entityId;
        threadStateData["id_props"] = {
          type: "integer",
          entity: entityFactory.definition.entityName,
        };
        console.log(
          "++++++ threadStateData",
          threadStateData,
          exitId,
          !workflow.requiresEntityId
        );
      }

      if (exitId || !workflow.requiresEntityId) {
        let queryParams = {
          ids: [threadId],
          transactionId: chance.guid(),
          timeStamp: null,
          pageNo: 1,
          pageCount: 1,
          stepId: workflow.requiresEntityId ? stepId : 0,
          exitId: workflow.requiresEntityId ? exitId : 0,
          workflowName: workflow.name,
          threadStateData: JSON.stringify(threadStateData),
          updateFields: [],
        };

        doThreadAdd(queryParams, setProcessing, onSubmit);
      }
    }
  };

  return { addTask, updateTask, doThreadAdd, executeAction };
};
