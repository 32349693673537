/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Elements } from "@stripe/react-stripe-js";
import FormBase from "../formBase";
import withUpdate from "./update.js";
import useStripePromise from "../../hooks/useStripePromise";
import { isNull } from "../../util/common";
//import { getFetchOptionsForAccessToken } from "../../AccessToken";
//import getEnvironment from "../../environment/env";

function getStripeField(fields) {
  if (!isNull(fields)) {
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (
        field.type === "string" &&
        field.format === "stripe" &&
        field.widget === "stripepayment" &&
        !field.hidden
      ) {
        return field;
      }
      if (
        field.type === "object" &&
        field.definition &&
        field.definition.fields &&
        field.definition.fields.length > 0
      ) {
        let childField = getStripeField(field.definition.fields);
        if (childField !== null) {
          return childField;
        }
      }
    }
  }
  return null;
}

function getStripePaymentField(definition, formDefinition) {
  const rootDefinition = definition ? definition : formDefinition;
  return getStripeField(rootDefinition?.fields);
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

const TaskForm = (props) => {
  const stripePromise = useStripePromise();
  const stripePaymentField = useRef(
    getStripePaymentField(props.definition, props.formDefinition)
  );
  const hasStripe =
    stripePaymentField.current && !stripePaymentField.current.hidden;
  const prevStripePaymentField = usePrevious(stripePaymentField.current);
  const [stripeOptionsState, setStripeOptionsState] = useState();
  const item = props.item;
  let stripeOptions = null;
  if (!hasStripe) {
    stripeOptions = null;
  } else {
    if (stripePaymentField.current !== prevStripePaymentField) {
      let clientSecret = item
        ? item[stripePaymentField.current?.fieldName]
        : null;
      //We need to get a new client secret.  This will be returned on a promise from a call to the server. ... OR WAIT UNTIL IT COMES THROUGH IN THE VALUES!
      if (clientSecret) {
        stripeOptions = {
          clientSecret,
        };
      }
      if (stripeOptions?.clientSecret !== stripeOptionsState?.clientSecret)
        setStripeOptionsState(stripeOptions);
    } else {
      stripeOptions = stripeOptionsState;
      if (isNull(stripeOptions)) {
        let clientSecret = item ? item[stripePaymentField.current] : null;
        if (clientSecret) {
          stripeOptions = {
            clientSecret,
          };
          setStripeOptionsState(stripeOptions);
        }
      }
    }
  }

  if (stripeOptions) {
    return (
      <Elements stripe={stripePromise} options={stripeOptions}>
        <FormBase
          {...props}
          clientSecret={stripeOptions?.clientSecret}
          stripePaymentField={stripePaymentField.current}
        />
        ;
      </Elements>
    );
  }

  return (
    <Elements stripe={null}>
      <FormBase {...props} />
    </Elements>
  );
};

export default withStyles(
  (theme) => {
    return {
      root: {
        transform: "translateZ(0px)",
        flexGrow: 1,
      },
      exampleWrapper: {
        position: "relative",
        marginTop: theme.spacing(3),
        height: 380,
        backgroundColor: "green",
      },
      radioGroup: {
        margin: theme.spacing(1, 0),
      },
      speedDial: {
        position: "absolute",
        "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
          bottom: theme.spacing(12),
          right: theme.spacing(2),
        },
        "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
          top: theme.spacing(2),
          left: theme.spacing(2),
        },
      },
    };
  },
  { withTheme: true }
)(withTheme(withUpdate(TaskForm)));
