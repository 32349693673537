import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import getEnvironment from "../environment/env";
import { isNull } from "../util/common";

function getStripePromise() {
  return isNull(getEnvironment().STRIPE_KEY)
    ? null
    : loadStripe(getEnvironment().STRIPE_KEY);
}

const stripePromise = getStripePromise();

function useStripePromise() {
  return stripePromise;
}

export default useStripePromise;
