/* eslint-disable react/prop-types */
import React, { useState, useRef } from "react";

import { Platform } from "react-native";
import { View } from "react-native";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "@material-ui/core/Button";

import {
  findEntityFactory,
  getPrimaryFieldsFromLookupField,
  getGlobalFilter,
} from "../util/common.js";
import { getFetchOptionsForAccessToken } from "../AccessToken";
import { convertLookupItems } from "../form/formUtil";
import TabBarIcon from "../components/TabBarIcon";
import MainDrawer from "../components/drawer/MainDrawer";
import Drawer from "../components/drawer/Drawer";
import { renderLogo } from "../navigation/utils";
import { isEmpty, isNull } from "../util/common.js";
import { SCREEN } from "../util/constants";
import getEnvironment from "../environment/env";
import { useWorkflow } from "../hooks/useWorkflow";
import { useHistory } from "../ReactRouter";
import Constants from "expo-constants";
import Chance from "chance";

const chance = new Chance();

const MAXIMUM_BUTTONS_ON_MEDIUM = 5;

const PageLayout = ({
  layoutGraph,
  allEntityFactories,
  publicStyle,
  windowHeight,
  currentUser,
  classes,
  theme,
  screenSize,
  wafelButtons,
  hasSideBar,
  entityFactory,
  entityId,
  changeLoginState,
  drawerMenu,
  onWorkflowSubmit,
  hideTopLeft,
  hideMenu,
  showNavigation,
  title,
  onLogoPress,
  rightContent,
  bodyContent,
}) => {
  const { executeAction } = useWorkflow();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [{ isOpen, currentSpeedDialButtons }, setState] = useState({
    isOpen: false,
    currentSpeedDialButtons: undefined,
  });
  const _menuActionDrawer = useRef(null);
  const handleClose = () => {
    setState({ isOpen: false, currentSpeedDialButtons: undefined });
  };

  const handleOpen = () => {
    setState({ isOpen: true, currentSpeedDialButtons: undefined });
  };

  const closeMenuActionDrawer = () => {
    if (_menuActionDrawer.current) _menuActionDrawer.current.close();
  };
  const openMenuActionDrawer = () => {
    if (_menuActionDrawer.current) _menuActionDrawer.current.open();
  };

  const renderTopLeft = () => {
    if (hideTopLeft) {
      return <View />;
    } else {
      const globalFilters =
        (history?.location?.pathname === "/" ||
          history?.location?.pathname === "/home") &&
        layoutGraph &&
        layoutGraph["allGlobalFilters"]
          ? layoutGraph["allGlobalFilters"]
          : [];
      const validGlobalFilters = globalFilters.filter(
        (filter) =>
          filter.role === currentUser.userEntityName ||
          filter.role === currentUser.parentEntityName ||
          filter.role === "inspectionorguser"
      );

      let filterOptions = [];
      filterOptions.push({
        filter: null,
        name: "None",
      });
      for (let i = 0; i < validGlobalFilters.length; i++) {
        let validGlobalFilter = validGlobalFilters[i];
        let entityName = validGlobalFilter.entityName;
        let entityFactory = findEntityFactory(allEntityFactories, entityName);
        if (entityFactory) {
          let filterItems =
            layoutGraph &&
            layoutGraph["all" + entityFactory.definition.pluralName]
              ? layoutGraph["all" + entityName + "s"]
              : [];
          if (filterItems.length > 0) {
            let additionalOnly = false;
            let lookupFields = [];
            if (!isEmpty(entityFactory.definition.lookupFields)) {
              additionalOnly = entityFactory.definition.additionalOnly
                ? true
                : false;
              lookupFields = entityFactory.definition.lookupFields;
            }
            const lookupPrimaryFields = getPrimaryFieldsFromLookupField(
              entityFactory.definition,
              { additionalOnly, lookupFields },
              null,
              true,
              false,
              null
            );
            const formattedItems = convertLookupItems(
              layoutGraph,
              allEntityFactories,
              filterItems,
              lookupPrimaryFields,
              false,
              true
            );
            for (let j = 0; j < formattedItems.length; j++) {
              let filterItem = formattedItems[j];
              filterOptions.push({
                filter: validGlobalFilter.name + "!" + filterItem.id,
                name: filterItem.name,
              });
            }
          }
        }
      }
      const globalFilter = getGlobalFilter();
      let buttonLabel = null;
      if (!isEmpty(globalFilter) && filterOptions && filterOptions.length > 0) {
        let item = filterOptions.find(
          (filterOption) => filterOption.filter === globalFilter
        );
        if (item) {
          buttonLabel = item.name;
        }
      }
      let left = [];
      let logo = renderLogo(32, history, onLogoPress);
      if (logo) left.push(logo);
      if (filterOptions.length > 1) {
        let isOpen = anchorEl ? true : false;
        const id = isOpen ? "filter-popover" : undefined;
        left.push(
          <View key={"filter_select"}>
            <Button
              key={"filter_select"}
              size={"small"}
              variant={undefined}
              color={"inherit"}
              disabled={false}
              aria-controls={id}
              aria-haspopup={true}
              onClick={(event) => {
                setAnchorEl(event.target);
              }}
              style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
              endIcon={<FilterListIcon />}
            >
              {title} {buttonLabel ? [<br />, "(" + buttonLabel + ")"] : null}
            </Button>
            <Popover
              id={id}
              anchorEl={anchorEl}
              keepMounted
              open={isOpen}
              onClose={() => {
                setAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <View>
                <List
                  style={{
                    background: theme.palette.background.paper,
                  }}
                >
                  {filterOptions.map((entry) => {
                    const { filter, name } = entry;
                    return (
                      <ListItem
                        button
                        onClick={async () => {
                          console.log("Clicked " + filter);
                          let url = new URL(
                            getEnvironment().API_URL + "/apply_filter"
                          );
                          let params = {
                            filter,
                          };
                          console.log("CALLING apply_filter PAYLOAD", params);
                          const response = await fetch(url, {
                            ...getFetchOptionsForAccessToken(),
                            body: JSON.stringify(params),
                          });
                          if (response?.ok) {
                            console.log("RESPONSE", response);
                            let guid = chance.guid();
                            localStorage.setItem("loginstate", guid);
                            window.location.reload();
                          }
                        }}
                        key={"filteri-" + filter}
                        style={{
                          paddingLeft: theme.spacing(1),
                        }}
                      >
                        <ListItemText
                          key={"filtert-" + filter}
                          primary={name}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </View>
            </Popover>
          </View>
        );
      } else {
        if (title) {
          left.push(
            <Typography
              key={"jpt"}
              variant="h6"
              display="block"
              color={"inherit"}
              style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
            >
              {title}
            </Typography>
          );
        }
      }
      if (showNavigation && !hideMenu) {
        const showBack =
          history.location.pathname !== "/" &&
          history.location.pathname !== "/home";
        return (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <IconButton
              aria-label={showBack ? "back" : "menu"}
              disabled={false}
              color="inherit"
              style={{ marginRight: 10 }}
              onClick={() =>
                showBack ? history.goBack() : openMenuActionDrawer()
              }
            >
              {showBack ? <ArrowBackIcon /> : <MenuIcon />}
            </IconButton>
            {left}
          </View>
        );
      } else {
        return (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View style={{ width: 10 }} />
            {left}
          </View>
        );
      }
    }
  };

  const renderContent = (style) => {
    return (
      <View style={{ flex: 1 }}>
        <AppBar position="static" color="primary">
          <View
            style={{
              flexDirection: "row",
              paddingLeft: hasSideBar ? 64 : 0,
              height: 50,
            }}
          >
            <View style={{ justifyContent: "center" }}>{renderTopLeft()}</View>
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                justifyContent: "flex-end",
              }}
            >
              {rightContent}
            </View>
          </View>
        </AppBar>
        <View style={style}>{bodyContent}</View>
      </View>
    );
  };

  const fixActions = (actions) => {
    if (actions && actions.length > 8) {
      let newActions = actions.slice(0, 7);
      let buttonMenu = {};
      for (let i = 7; i < actions.length; i++) {
        let action = actions[i];
        buttonMenu[action.buttonName] = [action.action];
      }
      newActions.push({ buttonName: "More ...", buttonMenu });
      return newActions;
    } else {
      return actions;
    }
  };

  let style =
    Platform.OS === "web"
      ? {
          display: "flex",
          flex: 1,
          paddingLeft: hasSideBar ? 64 : 0,
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "stretch",
          width: "100vw",
        }
      : {
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "stretch",
        };
  let siteName = getEnvironment().SITE_NAME;
  if (isEmpty(siteName)) {
    siteName = "Xelleron";
  }
  let actions = currentSpeedDialButtons
    ? currentSpeedDialButtons
    : wafelButtons;
  if (screenSize === SCREEN.SMALL) actions = fixActions(actions);
  //HEIGHTHERE
  console.log("PAGELAYOUT", windowHeight);
  let ua = navigator.userAgent.toLowerCase();
  let isAndroidOrWindowsOrFirefox =
    ua.indexOf("android") > -1 ||
    ua.indexOf("windows") > -1 ||
    ua.indexOf("linux") > -1 ||
    ua.indexOf("firefox") > -1;
  const rootStyle = {
    flex: 1,
    width: "100vw",
    height:
      isAndroidOrWindowsOrFirefox && windowHeight
        ? ua.indexOf("duckduckgo") > -1
          ? windowHeight - 60
          : windowHeight
        : undefined,
    position: isAndroidOrWindowsOrFirefox ? "fixed" : "relative",
  };
  return (
    <View style={rootStyle}>
      <Drawer
        ref={(ref) => {
          _menuActionDrawer.current = ref;
        }}
        hasSideBar={hasSideBar}
        openDrawerOffset={screenSize ? screenSize * 0.2 + 0.2 : 0.2}
        panCloseMask={screenSize ? screenSize * 0.2 + 0.2 : 0.2}
        content={
          <View
            style={{
              flex: 1,
              backgroundColor: "#ffffff",
              flexDirection: "column",
            }}
          >
            <MainDrawer
              currentUser={currentUser}
              title={siteName}
              renderLogo={renderLogo}
              history={history}
              changeLoginState={changeLoginState}
              drawerMenu={drawerMenu}
              closeDrawer={closeMenuActionDrawer}
              publicStyle={publicStyle}
            />
          </View>
        }
        onClose={(event) => closeMenuActionDrawer(event)}
      >
        {renderContent(style)}
      </Drawer>
      {classes && !isNull(actions) && actions.length > 1 ? (
        <SpeedDial
          ariaLabel="Additional Page Actions"
          className={classes.speedDial}
          hidden={
            !(
              screenSize === SCREEN.SMALL ||
              (screenSize === SCREEN.MEDIUM &&
                actions.length > MAXIMUM_BUTTONS_ON_MEDIUM)
            )
          }
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={isOpen ? true : false}
          direction={"up"}
        >
          {actions.map((button, index) => {
            let isFolder = !(button.action && button.action.workflow);
            let isDisabled =
              button?.action?.workflow?.requiresEntityId && isNull(entityId);
            let isDefault =
              isNull(currentSpeedDialButtons) &&
              ((screenSize === SCREEN.SMALL && index === 0) ||
                (screenSize === SCREEN.MEDIUM &&
                  index < MAXIMUM_BUTTONS_ON_MEDIUM));
            if (isDefault) return undefined;

            return (
              <SpeedDialAction
                key={button.buttonName}
                disabled={isDisabled}
                icon={
                  <IconButton aria-label={button.buttonName} color="inherit">
                    <TabBarIcon
                      icon={
                        isFolder
                          ? "menu-outline"
                          : button.icon
                          ? button.icon
                          : "arrow-forward-outline"
                      }
                      size={20}
                      color={
                        isDisabled
                          ? theme.palette.text.disabled
                          : theme.palette.primary.main
                      }
                    />
                  </IconButton>
                }
                tooltipTitle={button.buttonName}
                tooltipOpen
                onClick={() => {
                  if (isFolder) {
                    let currentSpeedDialButtons = Object.entries(
                      button.buttonMenu
                    ).map((entry) => {
                      const [key, value] = entry;
                      return {
                        buttonName: key,
                        action: value[0],
                        icon: value[0].workflow?.icon,
                      };
                    });
                    setState({ isOpen: true, currentSpeedDialButtons });
                  } else {
                    if (button.action?.workflow)
                      executeAction(
                        button.action.workflow,
                        null,
                        onWorkflowSubmit,
                        entityFactory,
                        entityId
                      );
                    handleClose();
                  }
                }}
              />
            );
          })}
        </SpeedDial>
      ) : undefined}
    </View>
  );
};

export default PageLayout;
