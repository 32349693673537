/* eslint-disable react/prop-types */
import React, { memo, useEffect, useState } from "react";
import { Platform, View } from "react-native";
import * as cloneDeep from "lodash/cloneDeep";
import * as isArray from "lodash/isArray";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { withRouter } from "../ReactRouter";
import Loading from "../components/loading";
import {
  isNull,
  isEmpty,
  isString,
  replaceAndDecode,
  getLookupFactory,
  isObjectNotArray,
  getScreenSize,
  getWafelButtons,
} from "../util/common";
import definitionHelper from "../util/definitions.js";
import taskDefinition from "../entities/thread/definition.js";
import TaskGrid from "../entities/thread/grid";
import withQueryToLayout from "../hoc/withQueryToLayout.js";
import withCollection from "../hoc/withCollection.js";
import { SCREEN, SUBDEFINITIONS } from "../util/constants";
import { getEntityFactory } from "../util/common";
import withWorkflowUpdate from "../entities/thread/update.js";
import TabBar from "../components/TabBar";
import TabBarIcon from "../components/TabBarIcon";
import { withCache, useCache } from "../hoc/withCache";
import EntityFactory from "../entities/entityFactory";
import Constants from "expo-constants";
import PageLayout from "./pageLayout";
import { useWorkflow } from "../hooks/useWorkflow";
import LogoutButton from "../components/authentication/LogoutButton";

let WrappedTaskGrid = withCache(withRouter(TaskGrid));

const MAXIMUM_BUTTONS_ON_MEDIUM = 5;

function suggestSubDefinition(rootDefinition, suggestedDefinition) {
  let suggested = null;
  if (!isNull(rootDefinition) && !isEmpty(rootDefinition.subDefinitions)) {
    for (let i = 0; i < rootDefinition.subDefinitions.length; i++) {
      if (rootDefinition.subDefinitions[i].viewName === suggestedDefinition) {
        suggested = suggestedDefinition;
        break;
      }
    }
  }

  if (isNull(suggested) && suggestedDefinition.endsWith(":xl")) {
    return suggestSubDefinition(
      rootDefinition,
      suggestedDefinition.substring(0, suggestedDefinition.length - 3)
    );
  } else if (isNull(suggested) && suggestedDefinition.endsWith(":l")) {
    return suggestSubDefinition(
      rootDefinition,
      suggestedDefinition.substring(0, suggestedDefinition.length - 2)
    );
  } else if (
    (isNull(suggested) && rootDefinition, suggestedDefinition.endsWith(":m"))
  ) {
    return suggestSubDefinition(
      rootDefinition,
      suggestedDefinition.substring(0, suggestedDefinition.length - 2)
    );
  } else if (isNull(suggested) && suggestedDefinition.endsWith(":s")) {
    return suggestSubDefinition(
      rootDefinition,
      suggestedDefinition.substring(0, suggestedDefinition.length - 2)
    );
  }

  return suggested;
}

function getSuggestedSubDefinition(props, rootDefinition, screenSize) {
  let suggestedDefinition = props.viewName;
  if (isNull(suggestedDefinition)) suggestedDefinition = "default";
  if (suggestedDefinition.indexOf(":") === -1) {
    //Try and check for an override version.
    if (screenSize >= SCREEN.LARGE) {
      suggestedDefinition = suggestedDefinition + ":l";
    } else if (screenSize === SCREEN.MEDIUM) {
      suggestedDefinition = suggestedDefinition + ":m";
    } else if (screenSize === SCREEN.SMALL) {
      suggestedDefinition = suggestedDefinition + ":s";
    }
  }
  suggestedDefinition = suggestSubDefinition(
    rootDefinition,
    suggestedDefinition
  );
  if (isNull(suggestedDefinition)) suggestedDefinition = "default";
  return suggestedDefinition;
}

function precalculateDisplaySectionSearch(props, pageDefinition) {
  let displaySectionSearch = {};

  let fields = definitionHelper.findAllTopLevelDBFields(pageDefinition.fields);
  let sections = pageDefinition.sections;

  for (let i = 0; i < sections.length; i++) {
    let section = pageDefinition.sections[i];
    let displaySearch = 0;
    let entityFactory = null;
    let isFirstSection = i === 0;
    for (let j = 0; j < fields.length; j++) {
      let field = fields[j];
      if (
        field.hidden !== true &&
        ((isFirstSection && isEmpty(field.tab)) || field.tab === section.id)
      ) {
        if (
          (field.type === "entitygrid" || field.type === "entityform") &&
          !isNull(field.lookup) &&
          isNull(field.lookupFilter)
        ) {
          entityFactory = getEntityFactory(
            props?.allEntityFactories,
            field.lookup
          );
          if (
            !entityFactory.cacheable() &&
            field.type === "entitygrid" &&
            field.showAdvancedSearch !== true
          ) {
            displaySearch += 1;
          }
        }
      }
    }
    displaySectionSearch[pageDefinition.entityName + ":" + section.id] =
      displaySearch;
  }
  return displaySectionSearch;
}

function precalculateEntityDefinitions(props, pageDefinition) {
  const { layoutGraph } = props;
  let currentUser = layoutGraph ? layoutGraph.currentUser : null;
  let definitions = {};

  if (!isNull(currentUser)) {
    let allPageFields = definitionHelper.findAllFields(pageDefinition.fields);
    for (let i = 0; i < allPageFields.length; i++) {
      let pageField = allPageFields[i];
      if (!isNull(pageField) && pageField.type === "taskgrid") {
        let taskGridDefinition = cloneDeep(taskDefinition);
        taskGridDefinition = definitionHelper.fixDefinition(taskGridDefinition);
        if (!isNull(pageField.lookupFilter)) {
          for (let filterField in pageField.lookupFilter) {
            if (
              Object.prototype.hasOwnProperty.call(
                pageField.lookupFilter,
                filterField
              ) &&
              !isNull(pageField.lookupFilter[filterField]) &&
              !isNull(pageField.lookupFilter[filterField].val)
            ) {
              let filterValues = pageField.lookupFilter[filterField].val;
              if (isString(filterValues)) {
                if (filterValues.startsWith("%%")) {
                  filterValues = replaceAndDecode(filterValues, currentUser);
                }
                filterValues = [filterValues];
              }
              let filter = {
                fieldName: filterField,
                operator: pageField.negateFilter ? "neq" : "eq",
                values: filterValues,
              };
              taskGridDefinition.filters = [filter];
              break;
            }
          }
        }
        taskGridDefinition.title = pageField.title;
        taskGridDefinition.showDetail = isNull(pageField.showDetail)
          ? taskGridDefinition.showDetail
          : pageField.showDetail;
        if (!isEmpty(pageField.displayFields)) {
          for (let j = 0; j < taskGridDefinition.fields.length; j++) {
            let field = taskGridDefinition.fields[j];
            if (pageField.displayFields.indexOf(field.fieldName) < 0) {
              field.hidden = true;
            }
          }
        }
        definitions[pageField.fieldName] = taskGridDefinition;
      }
      if (!isNull(pageField) && pageField.type === "entitygrid") {
        let lookupFactory = getLookupFactory(props, pageField);
        if (lookupFactory) {
          let gridDefinition = cloneDeep(lookupFactory.definition);
          gridDefinition = definitionHelper.fixDefinition(gridDefinition);
          let realGridDefinition = definitionHelper.findSubDefinition(
            lookupFactory.definition,
            pageField.gridSubDef
              ? pageField.gridSubDef
              : SUBDEFINITIONS.GRID.NAME
          );
          if (realGridDefinition.card) {
            gridDefinition.card = realGridDefinition.card;
          }
          if (!isNull(pageField.lookupFilter)) {
            let filters = [];
            for (let filterField in pageField.lookupFilter) {
              if (
                Object.prototype.hasOwnProperty.call(
                  pageField.lookupFilter,
                  filterField
                ) &&
                !isNull(pageField.lookupFilter[filterField]) &&
                !isNull(pageField.lookupFilter[filterField].val)
              ) {
                let gridFilterValues = pageField.lookupFilter[filterField].val;
                if (isString(gridFilterValues)) {
                  if (gridFilterValues.startsWith("%%")) {
                    gridFilterValues = replaceAndDecode(
                      gridFilterValues,
                      props.item
                    );
                  }
                  gridFilterValues = [gridFilterValues];
                } else {
                  gridFilterValues = [pageField.lookupFilter[filterField].val];
                }
                if (isNull(gridDefinition.filters)) {
                  gridDefinition.filters = [];
                }
                let filter = {
                  fieldName: filterField,
                  operator: "eq",
                  values: gridFilterValues,
                };
                filters.push(filter);
              }
            }
            if (!isEmpty(filters)) {
              gridDefinition.filters = filters;
            }
          }

          definitions[pageField.fieldName] = gridDefinition;
        }
      }
    }
  }
  return definitions;
}

function precalculateEntityControls(
  props,
  formCache,
  pageDefinition,
  precalculatedEntityDefinitions,
  extraFormParams
) {
  const { layoutGraph, allEntityFactories } = props;
  let currentUser = layoutGraph ? layoutGraph.currentUser : null;

  let controls = {};
  let fields = definitionHelper.findAllFields(pageDefinition.fields);
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (field.type === "entitygrid") {
      if (allEntityFactories) {
        for (let j = 0; j < allEntityFactories.length; j++) {
          let entityFactory = allEntityFactories[j];
          if (
            entityFactory.entityName().toLowerCase() ===
            field.lookup.toLowerCase()
          ) {
            let gridDefinition =
              precalculatedEntityDefinitions[field.fieldName];
            if (!isEmpty(gridDefinition.filters)) {
              let definition = cloneDeep(entityFactory.definition);
              if (isNull(definition.filters)) {
                definition.filters = [];
              }
              gridDefinition.filters.forEach((element) => {
                let filterField = definitionHelper.findField(
                  definition.fields,
                  element.fieldName
                );
                if (filterField.type === "array") {
                  filterField.default = [element.values[0]];
                } else {
                  filterField.lookupFilter = {
                    id: { val: element.values[0] },
                  };
                  filterField.default = element.values[0];
                }

                definition.filters.push({
                  fieldName: element.fieldName,
                  operator: "eq",
                  values: [element.values[0]],
                });
              });

              let ReferenceEntityForm = entityFactory.getForm(definition);
              let ReferenceEntityGrid =
                gridDefinition.cacheStrategy !== "never"
                  ? entityFactory.getGridWithCollection(
                      entityFactory.definition
                    )
                  : entityFactory.getGridWithFTSCollection(definition);
              controls[field.fieldName + ".grid"] = ReferenceEntityGrid;
              controls[field.fieldName + ".form"] = ReferenceEntityForm;
              if (!isNull(gridDefinition.card)) {
                let ReferenceEntityCard = entityFactory.getCard(gridDefinition);
                controls[field.fieldName + ".card"] = ReferenceEntityCard;
              }
            } else {
              if (entityFactory.hasSomeViewRights(currentUser)) {
                let cacheable = entityFactory.cacheable();
                let EntityForm = entityFactory.getForm();
                controls[field.fieldName + ".cacheable"] = cacheable;
                if (cacheable) {
                  let BaseEntityGrid = entityFactory.withFactoryAndDefinition(
                    withQueryToLayout(withCollection(entityFactory.getGrid()))
                  );
                  controls[field.fieldName + ".grid"] = BaseEntityGrid;
                  controls[field.fieldName + ".form"] = EntityForm;
                } else {
                  let FTSEntityGrid = entityFactory.getGridWithFTSCollection();
                  controls[field.fieldName + ".grid"] = FTSEntityGrid;
                  controls[field.fieldName + ".form"] = EntityForm;
                  controls[field.fieldName + ".isFTS"] = true;
                }
                if (!isNull(gridDefinition.card)) {
                  let ReferenceEntityCard =
                    entityFactory.getCard(gridDefinition);
                  controls[field.fieldName + ".card"] = ReferenceEntityCard;
                }
              }
            }
            break;
          }
        }
      }
    } else if (field.type === "entityform") {
      if (
        allEntityFactories &&
        field?.lookupFilter?.id &&
        (field.viewName === "__search" || field.lookupFilter.id.val)
      ) {
        for (let j = 0; j < allEntityFactories.length; j++) {
          let entityFactory = allEntityFactories[j];
          if (
            entityFactory.entityName().toLowerCase() ===
            field.lookup.toLowerCase()
          ) {
            let EntityForm = undefined;
            if (field.viewName === "__search") {
              EntityForm = entityFactory.getSearchFormWithEntityAsComponent();
            } else {
              EntityForm = entityFactory.getFormWithEntityAsComponent();
            }
            controls[field.fieldName + ".form"] = EntityForm;
            break;
          }
        }
      }
    } else if (field.type === "form") {
      let allForms = layoutGraph ? layoutGraph.allForms : [];
      for (let j = 0; j < allForms.length; j++) {
        let form = allForms[j];
        if (form.name === field.form) {
          let definition = JSON.parse(form.definition);
          definition.addRoles = [20];
          definition.editRoles = [20];
          let entityFactory = new EntityFactory(
            currentUser,
            definition.entityName,
            definition,
            definition
          );
          if (formCache && !isNull(extraFormParams)) {
            let currentStr = formCache[field.form];
            let current = null;
            if (isEmpty(currentStr)) {
              current = {};
            } else {
              current = JSON.parse(currentStr);
            }
            if (isNull(current.values)) {
              current.values = {};
            }
            for (const [key, value] of extraFormParams) {
              current.values[key] = value;
            }
            formCache[field.form] = JSON.stringify(current);
          }

          let EntityForm = entityFactory.getPlainForm();
          controls[field.fieldName + ".form"] = EntityForm;
          controls[field.fieldName + ".entityFactory"] = entityFactory;
          break;
        }
      }
    }
  }
  return controls;
}

function getWorkflowDrawerState(props, pageDefinition) {
  let hideWorkflowButtons = false;
  if (!isNull(pageDefinition) && !isNull(pageDefinition.hideWorkflowButtons)) {
    hideWorkflowButtons = pageDefinition.hideWorkflowButtons;
  }
  let allButtons = getWafelButtons(
    props.layoutGraph,
    props.entityFactory,
    props.pageItem ? props.pageItem.name : null,
    false,
    props.entityFactory
  );

  let availableButtons = allButtons;
  if (hideWorkflowButtons) {
    availableButtons = getWafelButtons(
      props.layoutGraph,
      props.entityFactory,
      props.pageItem ? props.pageItem.name : null,
      true,
      props.entityFactory
    );
  }
  if (pageDefinition?.displayLogout) {
    let logoutAction = {
      action: {
        url: "",
        menuNames: ["Logout"],
        workflow: { name: "/logout", title: "Logout" },
      },
      buttonMenu: undefined,
      buttonName: "Logout",
      icon: null,
    };
    availableButtons = [logoutAction, ...availableButtons];
  }
  let sections = pageDefinition
    ? definitionHelper.findAllSections(pageDefinition)
    : [];
  // let sections = !isEmpty(pageDefinition?.sections)
  //   ? pageDefinition.sections
  //   : [];
  let wafelButtons = [];
  availableButtons.forEach((button) => {
    let valid = true;
    for (let i = 0; i < sections.length; i++) {
      let section = sections[i];
      if (
        section.workflow &&
        section.workflow === button?.action?.workflow?.name
      ) {
        valid = false;
        break;
      }
    }
    if (valid) {
      wafelButtons.push(button);
    }
  });

  return { wafelButtons, allButtons };
}

function getDisplayMode(pageDefinition) {
  return isEmpty(pageDefinition.displayMode)
    ? "tabs"
    : pageDefinition.displayMode;
}

function calcRelativeCellScreenSize(columns, currentScreenSize) {
  if (columns === 1) return currentScreenSize;
  if (columns === 2) {
    let screenSize = SCREEN.SMALL;
    if (currentScreenSize === SCREEN.LARGE) {
      screenSize = SCREEN.MEDIUM;
    } else if (currentScreenSize === SCREEN.HUGE) {
      screenSize = SCREEN.LARGE;
    }
    return screenSize;
  }
  if (columns === 3) {
    let screenSize = SCREEN.SMALL;
    if (currentScreenSize === SCREEN.HUGE) {
      screenSize = SCREEN.MEDIUM;
    }
    return screenSize;
  }
  return SCREEN.SMALL;
}

const useStyles = makeStyles((theme) => ({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
    backgroundColor: "green",
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(12),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

const JSONPage = (props) => {
  const { executeAction } = useWorkflow();
  const { tabCache, formCache } = useCache();
  const theme = useTheme();
  const classes = useStyles();
  const [rerender, setRerender] = useState(0);
  const getStateFromProps = (props, oldState, screenSize, extraFormParams) => {
    let newState = {};
    newState.screenSize = screenSize;
    if (!isNull(props.pageItem)) {
      newState.pageId = props.pageItem.id;
      newState.pageVersionId = props.pageItem.versionId;

      //Should fix definition.
      let recalc = false;
      if (
        isNull(oldState.origPageDefinition) ||
        isNull(oldState.pageId) ||
        oldState.pageId !== newState.pageId ||
        oldState.versionId !== newState.versionId
      ) {
        newState.origPageDefinition = isObjectNotArray(
          props.pageItem.definition
        )
          ? props.pageItem.definition
          : JSON.parse(props.pageItem.definition);
        recalc = true;
      }
      let origPageDefinition = isNull(newState.origPageDefinition)
        ? oldState.origPageDefinition
        : newState.origPageDefinition;

      let suggestedDefinition = getSuggestedSubDefinition(
        props,
        origPageDefinition,
        screenSize
      );
      if (recalc || suggestedDefinition !== oldState.suggestedDefinition) {
        newState.pageDefinition = definitionHelper.findSubDefinition(
          origPageDefinition,
          suggestedDefinition
        );
        newState.precalculatedEntityDefinitions = precalculateEntityDefinitions(
          props,
          newState.pageDefinition
        );
        newState.precalculatedDisplaySectionSearch =
          precalculateDisplaySectionSearch(props, newState.pageDefinition);
        newState.initialPrecalculatedDisplaySectionSearch = cloneDeep(
          newState.precalculatedDisplaySectionSearch
        );
        newState.precalculatedEntityControls = precalculateEntityControls(
          props,
          formCache,
          newState.pageDefinition,
          newState.precalculatedEntityDefinitions,
          extraFormParams
        );
        newState.suggestedDefinition = suggestedDefinition;
      } else {
        newState.origPageDefinition = oldState.origPageDefinition;
        newState.pageDefinition = oldState.pageDefinition;
        newState.precalculatedEntityDefinitions =
          oldState.precalculatedEntityDefinitions;
        newState.precalculatedDisplaySectionSearch =
          oldState.precalculatedDisplaySectionSearch;
        newState.initialPrecalculatedDisplaySectionSearch =
          oldState.precalculatedDisplaySectionSearch;
        newState.precalculatedEntityControls =
          oldState.precalculatedEntityControls;
        newState.suggestedDefinition = oldState.suggestedDefinition;
      }
    }
    let { wafelButtons, allButtons } = getWorkflowDrawerState(
      props,
      newState.pageDefinition
    );
    newState.wafelButtons = wafelButtons;
    newState.allButtons = allButtons;
    let allWorkflows =
      props?.layoutGraph && !isEmpty(props.layoutGraph.allWorkflows)
        ? props.layoutGraph.allWorkflows
        : [];
    newState.allWorkflows = allWorkflows;

    return newState;
  };

  const [gridModeState, setGridModeState] = useState(() => {
    return {};
  });

  const [state, setState] = useState(() => {
    let screenSize = getScreenSize(props.windowWidth);
    //let suggestedDefinition = getSuggestedSubDefinition(props, rootDefinition, {})
    let extraFormParams = null;
    if (
      props &&
      props.history &&
      props.history.location &&
      props.history.location.search
    ) {
      let paramString = props.history.location.search;
      if (!isEmpty(paramString)) {
        extraFormParams = new URLSearchParams(paramString);
      }
    }
    let initState = getStateFromProps(props, {}, screenSize, extraFormParams);
    initState.isDirty = {};
    initState.isSubmitting = {};
    initState.hasErrors = {};
    initState.handleSubmit = {};
    initState.handleReset = {};
    initState.onFormSubmit = {};
    initState.setTouched = {};
    initState.submitURLs = {};
    initState.selection = [];
    initState.extraFormParams = extraFormParams;
    return initState;
  });

  const setGridSelection = (selection) => {
    setState({ ...state, selection });
  };

  const getWorkflowEntityId = () => {
    let item = props.item;
    let entityId = item ? item.id : null;
    if (isNull(entityId)) {
      if (state.selection.length === 1) {
        entityId = state.selection[0];
      }
    }
    return entityId;
  };

  const onDebug = () => {
    let debugMode = !state.debugMode;
    let newState = { ...state };
    newState.debugMode = debugMode;
    setState({ ...state, ...newState });
  };

  const setGridMode = (formName, gridMode) => {
    let newGridMode = { ...gridModeState };
    newGridMode[formName] = gridMode;
    setGridModeState(newGridMode);
  };

  const setFormState = (
    formName,
    dirty,
    submitting,
    errors,
    submit,
    reset,
    formSubmit,
    submitURL
  ) => {
    const {
      isDirty,
      isSubmitting,
      hasErrors,
      handleSubmit,
      handleReset,
      onFormSubmit,
      submitURLs,
    } = state;
    if (
      isDirty[formName] !== dirty ||
      isSubmitting[formName] !== submitting ||
      hasErrors[formName] !== errors
    ) {
      let newDirty = { ...isDirty };
      newDirty[formName] = dirty;
      let newSubmitting = { ...isSubmitting };
      newSubmitting[formName] = submitting;
      let newErrors = { ...hasErrors };
      newErrors[formName] = errors;
      let newHandleSubmit = { ...handleSubmit };
      newHandleSubmit[formName] = submit;
      let newHandleReset = { ...handleReset };
      newHandleReset[formName] = reset;
      let newOnFormSubmit = { ...onFormSubmit };
      newOnFormSubmit[formName] = formSubmit;
      let newSubmitURLs = { ...submitURLs };
      newSubmitURLs[formName] = submitURL;
      setState({
        ...state,
        isDirty: newDirty,
        isSubmitting: newSubmitting,
        hasErrors: newErrors,
        handleSubmit: newHandleSubmit,
        handleReset: newHandleReset,
        onFormSubmit: newOnFormSubmit,
        submitURLs: newSubmitURLs,
      });
    }
  };

  useEffect(() => {
    let newId = props.pageItem ? props.pageItem.id : null;
    let newVersionId = props.pageItem ? props.pageItem.versionId : null;
    let update = false;

    if (!isNull(state.pageId)) {
      if (!isNull(newId)) {
        update = newId !== state.pageId || newVersionId !== state.pageVersionId;
      }
    } else {
      if (!isNull(newId)) {
        update = true;
      }
    }
    let screenSize = getScreenSize(props.windowWidth);

    if (!update) {
      let suggestedDefinition = getSuggestedSubDefinition(
        props,
        state.origPageDefinition,
        screenSize
      );
      if (suggestedDefinition !== state.suggestedDefinition) {
        update = true;
      }
    }

    if (
      update &&
      props.layoutGraph?.allPages &&
      props.layoutGraph.allPages.length > 0
    ) {
      setState({
        ...state,
        ...getStateFromProps(props, state, screenSize, state.extraFormParams),
      });
    } else if (screenSize !== state.screenSize) {
      let newState = { screenSize };
      let { wafelButtons, allButtons } = getWorkflowDrawerState(
        props,
        state.pageDefinition
      );
      newState.wafelButtons = wafelButtons;
      newState.allButtons = allButtons;
      setState({ ...state, ...newState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.windowWidth, props.pageItem]);

  const renderEntityForm = (
    currentUser,
    pageDefinition,
    section,
    field,
    displayMode,
    cellScreenSize,
    cacheRoot,
    renderLevel
  ) => {
    const {
      layoutGraph,
      allEntityFactories,
      navigationDefaults,
      publicStyle,
      changeLoginState,
    } = props;
    const { precalculatedEntityControls } = state;

    const layoutLoading = layoutGraph ? layoutGraph.loading : true;
    let element = <View key={"pcf_" + field.fieldName} />;
    if (
      allEntityFactories &&
      !layoutLoading &&
      currentUser &&
      field?.lookupFilter?.id &&
      (field.viewName === "__search" || field.lookupFilter.id.val)
    ) {
      for (let i = 0; i < allEntityFactories.length; i++) {
        let entityFactory = allEntityFactories[i];
        if (
          entityFactory.entityName().toLowerCase() ===
          field.lookup.toLowerCase()
        ) {
          /*
          let EntityForm = entityFactory.getFormWithEntity();
          let primaryCollection = entityFactory.getPrimaryCollection(layoutGraph);
          let subDef = isEmpty(field.viewName) ? SUBDEFINITIONS.LARGE.NAME : field.viewName;
          let buttons = []
          if (field.readOnly !== true && entityFactory.hasEditRights(currentUser)) {
            buttons.push(
              <View style={{ float: "left" }}>
                <Button type="submit" disabled={!state.dirty} bsStyle={state.dirty ? "success" : "default"} style={{ marginRight: 5 }}>Save</Button>
                {getActionButton(props.item)}
                <Button onClick={onFormDismiss} disabled={!state.dirty} bsStyle={state.dirty ? "danger" : "default"}>Discard</Button>
              </View>
            );
          }
          */
          let PrecalculatedForm =
            precalculatedEntityControls[field.fieldName + ".form"];
          let id = field.lookupFilter.id.val;
          id = replaceAndDecode(id, props.item);
          let sameEntity =
            props.entityFactory &&
            props.entityFactory.entityName().toLowerCase() ===
              entityFactory.entityName().toLowerCase();
          element = (
            <View key={"pcf_" + field.fieldName} style={{ flex: 1 }}>
              <PrecalculatedForm
                changeLoginState={changeLoginState}
                isSearch={field.viewName === "__search"}
                onSubmit={onSubmitSuccess}
                cacheRoot={cacheRoot}
                navigation={props.navigation}
                renderLevel={renderLevel + 1}
                setFormState={setFormState}
                formName={field.fieldName}
                hideActionButton={field.hideWorkflowButtons || sameEntity}
                entityFactory={entityFactory}
                entityId={id}
                subDefinition={field.viewName}
                readOnly={field.readOnly}
                screenSize={cellScreenSize}
                allEntityFactories={allEntityFactories}
                layoutGraph={layoutGraph}
                navigationDefaults={navigationDefaults}
                publicStyle={publicStyle}
              />
            </View>
          );
          break;
        }
      }
    }
    return element;
  };

  const renderPlainForm = (
    currentUser,
    pageDefinition,
    section,
    field,
    displayMode,
    cellScreenSize,
    cacheRoot,
    renderLevel
  ) => {
    const {
      layoutGraph,
      allEntityFactories,
      navigationDefaults,
      publicStyle,
      changeLoginState,
    } = props;
    const { precalculatedEntityControls } = state;
    const layoutLoading = layoutGraph ? layoutGraph.loading : true;
    let element = <View key={"pcf_" + field.fieldName} />;
    if (
      !layoutLoading &&
      currentUser &&
      field &&
      precalculatedEntityControls &&
      precalculatedEntityControls[field.fieldName + ".form"]
    ) {
      let PrecalculatedForm =
        precalculatedEntityControls[field.fieldName + ".form"];
      let entityFactory =
        precalculatedEntityControls[field.fieldName + ".entityFactory"];
      element = (
        <View key={"pcf_" + field.fieldName} style={{ flex: 1 }}>
          <PrecalculatedForm
            key={"frm_" + field.fieldName}
            isPlainForm={true}
            changeLoginState={changeLoginState}
            thread={{}}
            entityId={
              props.match && props.match.params
                ? props.match.params.entityId
                : undefined
            }
            item={{}}
            isSearch={false}
            onSubmit={undefined}
            cacheRoot={cacheRoot}
            navigation={props.navigation}
            renderLevel={renderLevel + 1}
            setFormState={undefined}
            formName={field.form}
            hideActionButton={true}
            hideWorkflowButtons={true}
            entityFactory={entityFactory}
            subDefinition={field.viewName}
            readOnly={field.readOnly}
            screenSize={cellScreenSize}
            allEntityFactories={allEntityFactories}
            layoutGraph={layoutGraph}
            navigationDefaults={navigationDefaults}
            publicStyle={publicStyle}
          />
        </View>
      );
    }
    return element;
  };

  const renderEntityGrid = (
    currentUser,
    pageDefinition,
    section,
    field,
    displayMode,
    cellScreenSize,
    cacheRoot,
    renderLevel
  ) => {
    const {
      layoutGraph,
      notificationSystem,
      allEntityFactories,
      fts,
      navigationDefaults,
      publicStyle,
      changeLoginState,
    } = props;
    const layoutLoading = layoutGraph ? layoutGraph.loading : true;
    const { precalculatedEntityControls } = state;

    let element = <View />;
    if (allEntityFactories && !layoutLoading && currentUser) {
      for (let i = 0; i < allEntityFactories.length; i++) {
        let entityFactory = allEntityFactories[i];
        if (
          entityFactory.entityName().toLowerCase() ===
          field.lookup.toLowerCase()
        ) {
          // Can override and pass definition in to getGridWithFTSCollection

          let gridDefinition =
            state.precalculatedEntityDefinitions[field.fieldName];
          let primaryCollection =
            entityFactory.getPrimaryCollection(layoutGraph);
          let showDetail = isNull(field.showDetail)
            ? gridDefinition.showDetail
            : field.showDetail;
          //let isPrimaryGrid = (props.entityFactory.definition.entityName === gridDefinition.entityName);
          if (!isEmpty(gridDefinition.filters)) {
            let filter = {};
            for (let j = 0; j < gridDefinition.filters.length; j++) {
              if (
                gridDefinition.filters[j].values &&
                gridDefinition.filters[j].values.length > 0
              ) {
                if (isArray(gridDefinition.filters[j].values[0])) {
                  filter[gridDefinition.filters[j].fieldName] = {
                    val: [...gridDefinition.filters[j].values[0]],
                  };
                } else {
                  filter[gridDefinition.filters[j].fieldName] = {
                    val: [gridDefinition.filters[j].values[0]],
                  };
                }
              }
            }
            let PrecalculatedGrid =
              precalculatedEntityControls[field.fieldName + ".grid"];
            let PrecalculatedForm =
              precalculatedEntityControls[field.fieldName + ".form"];
            let PrecalculatedCard1 =
              precalculatedEntityControls[field.fieldName + ".card"];
            element = (
              <PrecalculatedGrid
                gridSubDef={field.gridSubDef}
                subDefinition={field.viewName}
                changeLoginState={changeLoginState}
                isGrid={true}
                onSubmit={onSubmitSuccess}
                cacheRoot={cacheRoot}
                key={"pcg_" + section.id + "_" + field.fieldName}
                navigation={props.navigation}
                renderLevel={renderLevel + 1}
                gridMode={
                  !isNull(gridModeState[field.fieldName])
                    ? gridModeState[field.fieldName]
                    : 0
                }
                setGridMode={setGridMode}
                setGridSelection={setGridSelection}
                setFormState={setFormState}
                formName={field.fieldName}
                defaultFilter={field.defaultFilter}
                defaultSort={field.defaultSort}
                allowAdvancedSearch={field.allowAdvancedSearch}
                viewMode={field.viewMode}
                hideViewSwitcher={field.hideViewSwitcher}
                geoFieldName={field.geoFieldName}
                showAdvancedSearch={field.showAdvancedSearch}
                showFilter={field.showFilter}
                filter={[filter]}
                showDetail={showDetail}
                allEntityFactories={allEntityFactories}
                Form={PrecalculatedForm}
                Card={PrecalculatedCard1}
                screenSize={cellScreenSize}
                layoutGraph={layoutGraph}
                navigationDefaults={navigationDefaults}
                primaryCollection={primaryCollection}
                notificationSystem={notificationSystem}
                publicStyle={publicStyle}
              />
            );
            //showDetail={false} screenSize={props.screenSize > SCREEN.MEDIUM ? SCREEN.MEDIUM : SCREEN.SMALL} filter={[filter]} allEntityFactories={allEntityFactories} Form={ReferenceEntityForm} layoutGraph={layoutGraph} primaryCollection={lookupEntityFactory.getPrimaryCollection(layoutGraph)} notificationSystem={props.notificationSystem}
          } else {
            let PrecalculatedGrid =
              precalculatedEntityControls[field.fieldName + ".grid"];
            let PrecalculatedForm =
              precalculatedEntityControls[field.fieldName + ".form"];
            let PrecalculatedCard =
              precalculatedEntityControls[field.fieldName + ".card"];
            if (entityFactory.hasSomeViewRights(currentUser)) {
              if (precalculatedEntityControls[field.fieldName + ".cacheable"]) {
                element = (
                  <PrecalculatedGrid
                    gridSubDef={field.gridSubDef}
                    subDefinition={field.viewName}
                    changeLoginState={changeLoginState}
                    isGrid={true}
                    onSubmit={onSubmitSuccess}
                    cacheRoot={cacheRoot}
                    navigation={props.navigation}
                    renderLevel={renderLevel + 1}
                    gridMode={
                      !isNull(gridModeState[field.fieldName])
                        ? gridModeState[field.fieldName]
                        : 0
                    }
                    setGridMode={setGridMode}
                    setGridSelection={setGridSelection}
                    setFormState={setFormState}
                    formName={field.fieldName}
                    entityFactory={entityFactory}
                    key={"pcg_" + section.id + "_" + field.fieldName}
                    defaultFilter={field.defaultFilter}
                    defaultSort={field.defaultSort}
                    allowAdvancedSearch={field.allowAdvancedSearch}
                    viewMode={field.viewMode}
                    hideViewSwitcher={field.hideViewSwitcher}
                    geoFieldName={field.geoFieldName}
                    showAdvancedSearch={field.showAdvancedSearch}
                    showFilter={field.showFilter}
                    definition={gridDefinition}
                    showDetail={showDetail}
                    allEntityFactories={allEntityFactories}
                    Form={PrecalculatedForm}
                    Card={PrecalculatedCard}
                    screenSize={cellScreenSize}
                    layoutGraph={layoutGraph}
                    navigationDefaults={navigationDefaults}
                    primaryCollection={primaryCollection}
                    notificationSystem={notificationSystem}
                    publicStyle={publicStyle}
                  />
                );
              } else {
                element = (
                  <PrecalculatedGrid
                    gridSubDef={field.gridSubDef}
                    subDefinition={field.viewName}
                    changeLoginState={changeLoginState}
                    isGrid={true}
                    onSubmit={onSubmitSuccess}
                    cacheRoot={cacheRoot}
                    navigation={props.navigation}
                    renderLevel={renderLevel + 1}
                    gridMode={
                      !isNull(gridModeState[field.fieldName])
                        ? gridModeState[field.fieldName]
                        : 0
                    }
                    setGridMode={setGridMode}
                    setGridSelection={setGridSelection}
                    setFormState={setFormState}
                    formName={field.fieldName}
                    entityFactory={entityFactory}
                    parentTab={undefined}
                    key={"pcg_" + section.id + "_" + field.fieldName}
                    defaultFilter={field.defaultFilter}
                    defaultSort={field.defaultSort}
                    allowAdvancedSearch={field.allowAdvancedSearch}
                    viewMode={field.viewMode}
                    hideViewSwitcher={field.hideViewSwitcher}
                    geoFieldName={field.geoFieldName}
                    showAdvancedSearch={field.showAdvancedSearch}
                    showFilter={field.showFilter}
                    definition={gridDefinition}
                    showDetail={showDetail}
                    fts={fts}
                    allEntityFactories={allEntityFactories}
                    Form={PrecalculatedForm}
                    Card={PrecalculatedCard}
                    screenSize={cellScreenSize}
                    layoutGraph={layoutGraph}
                    navigationDefaults={navigationDefaults}
                    primaryCollection={primaryCollection}
                    notificationSystem={notificationSystem}
                    publicStyle={publicStyle}
                  />
                );
              }
            }
          }
          break;
        }
      }
    }
    return element;
  };

  const renderField = (
    currentGridMode,
    currentGridParents,
    layoutLoading,
    currentUser,
    pageDefinition,
    section,
    field,
    displayMode,
    cellScreenSize,
    rowCount,
    cacheRoot,
    renderLevel
  ) => {
    const {
      layoutGraph,
      notificationSystem,
      allEntityFactories,
      navigationDefaults,
      publicStyle,
    } = props;
    let allThreads = layoutGraph ? layoutGraph.allThreads : [];

    let element = <View key={"tg_" + field.fieldName} />;
    let fieldCacheRoot = cacheRoot + "_" + field.fieldName;
    if (field.type === "taskgrid") {
      element = (
        <WrappedTaskGrid
          cacheRoot={fieldCacheRoot}
          key={"tg_" + field.fieldName}
          navigation={props.navigation}
          renderLevel={renderLevel + 1}
          setFormState={setFormState}
          formName={field.fieldName}
          navigationDefaults={navigationDefaults}
          showFilter={field.showFilter}
          defaultSort={field.defaultSort}
          defaultFilter={field.defaultFilter}
          definition={state.precalculatedEntityDefinitions[field.fieldName]}
          allEntityFactories={allEntityFactories}
          screenSize={cellScreenSize}
          layoutGraph={layoutGraph}
          primaryCollection={allThreads}
          notificationSystem={notificationSystem}
          publicStyle={publicStyle}
        />
      );
    } else if (field.type === "entitygrid") {
      element = renderEntityGrid(
        currentUser,
        pageDefinition,
        section,
        field,
        displayMode,
        cellScreenSize,
        fieldCacheRoot,
        renderLevel
      );
    } else if (field.type === "entityform") {
      element = renderEntityForm(
        currentUser,
        pageDefinition,
        section,
        field,
        displayMode,
        cellScreenSize,
        fieldCacheRoot,
        renderLevel
      );
    } else if (field.type === "form") {
      element = renderPlainForm(
        currentUser,
        pageDefinition,
        section,
        field,
        displayMode,
        cellScreenSize,
        fieldCacheRoot,
        renderLevel
      );
    } else if (field.type === "object") {
      let ariaLabel = isEmpty(field.title) ? field.fieldName : field.title;
      return renderDefinition(
        ariaLabel,
        currentGridMode,
        currentGridParents,
        layoutLoading,
        currentUser,
        field.definition,
        "_" + field.fieldName,
        cellScreenSize,
        cacheRoot,
        renderLevel + 1
      );
    }
    return [element];
  };

  const renderSection = (
    currentGridMode,
    currentGridParents,
    layoutLoading,
    currentUser,
    pageDefinition,
    section,
    isFirstSection,
    displayMode,
    screenSize,
    cacheRoot,
    renderLevel
  ) => {
    let cells = [];
    let cellNames = [];
    let rows = [];
    let fields = pageDefinition.fields;
    let columns = isNull(section.columns) ? 1 : section.columns;
    let cellScreenSize = calcRelativeCellScreenSize(columns, screenSize);
    let totalCells = 0;
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (
        field.hidden !== true &&
        ((isFirstSection && isEmpty(field.tab)) || field.tab === section.id)
      ) {
        totalCells += 1;
      }
    }
    if (totalCells === 0 && isEmpty(section.workflow)) return null;
    if (
      displayMode !== "tabbar" &&
      displayMode !== "tabs" &&
      displayMode !== "pills" &&
      !isEmpty(section.workflow)
    )
      return null;
    let rowCount = Math.trunc(totalCells / columns);
    if (totalCells % columns > 0) {
      rowCount += 1;
    }

    let mobileMode =
      totalCells > 1 &&
      (Platform.OS === "ios" ||
        Platform.OS === "android" ||
        state.screenSize === SCREEN.SMALL);

    //var div = Math.trunc(y/x);
    //var rem = y % x;
    if (totalCells > 0) {
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        if (
          field.hidden !== true &&
          ((isFirstSection && isEmpty(field.tab)) || field.tab === section.id)
        ) {
          let cell = renderField(
            currentGridMode,
            currentGridParents,
            layoutLoading,
            currentUser,
            pageDefinition,
            section,
            field,
            displayMode,
            cellScreenSize,
            rowCount,
            cacheRoot,
            renderLevel
          );
          if (mobileMode) {
            let fieldTitle = isNull(field.title)
              ? field.fieldName
              : field.title;
            cells.push(
              <TabBar.Item
                key={"tab_" + i.toString() + "_" + screenSize.toString()}
                title={fieldTitle}
                publicStyle={props.publicStyle}
              >
                {cell}
              </TabBar.Item>
            );
          } else {
            let isVisible =
              isNull(currentGridMode) ||
              currentGridParents.indexOf(field.fieldName) >= 0;
            //console.log("SECTION CELL: ", section.id, isVisible)
            let style = {
              display: isVisible ? undefined : "none",
              visibility: isVisible ? "visible" : "hidden",
              opacity: isVisible ? 100 : 0,
              flex: 1,
              flexDirection: "column",
            };
            cells.push(
              <View style={style} key={"col_" + i.toString()}>
                {cell}
              </View>
            );
          }
          cellNames.push(field.fieldName);
        }
      }

      if (!mobileMode) {
        if (totalCells > 1) {
          for (let i = 0; i < rowCount; i++) {
            let rowElements = [];
            let containsCurrentGrid = false;
            for (let j = 0; j < columns; j++) {
              let current = i * columns + j;
              rowElements.push(cells[current]);
              containsCurrentGrid =
                containsCurrentGrid ||
                currentGridParents.indexOf(cellNames[current]) >= 0;
              //console.log("cellNames[current]", cellNames[current], containsCurrentGrid, currentGridParents)
            }
            let isVisible = isNull(currentGridMode) || containsCurrentGrid;
            //console.log("SECTION ROW: ", section.id, isVisible, cellNames, currentGridMode)
            let style = {
              display: isVisible ? undefined : "none",
              visibility: isVisible ? "visible" : "hidden",
              opacity: isVisible ? 100 : 0,
              flexDirection: "row",
              flex: 1,
            };
            rows.push(
              <View style={style} key={"row" + i.toString()}>
                {rowElements}
              </View>
            );
          }
        } else {
          if (cells && cells.length === 1) {
            rows.push(cells[0]);
          }
        }
      }
    }
    let elementsInDiv = [];
    let sectionTitle = isNull(section.title) ? section.id : section.title;
    if (mobileMode) {
      let idKey = cacheRoot + "_s_" + section.id + "_" + screenSize.toString();
      elementsInDiv.push(
        <TabBar
          label={sectionTitle}
          hidden={!isNull(currentGridMode)}
          type="tabs"
          id={idKey}
          key={idKey}
          activeKey={0}
          screenSize={screenSize}
          publicStyle={props.publicStyle}
        >
          {cells}
        </TabBar>
      );
    } else {
      elementsInDiv.push(
        <View key={"grid"} style={{ flex: 1 }}>
          {rows}
        </View>
      );
    }
    //let eventKey = pageDefinition.entityName + ":" + section.id;
    if (
      mobileMode ||
      displayMode === "tabbar" ||
      displayMode === "tabs" ||
      displayMode === "pills"
    ) {
      return (
        <TabBar.Item
          key={"st_" + section.id + screenSize.toString()}
          title={sectionTitle}
          icon={section.icon}
          workflow={replaceAndDecode(section.workflow, props.item)}
          publicStyle={props.publicStyle}
        >
          {elementsInDiv}
        </TabBar.Item>
      );
    } else {
      if (isEmpty(section.title)) {
        return (
          <View key={"st_" + section.id} style={{ flex: 1 }}>
            {elementsInDiv}
          </View>
        );
      } else {
        return (
          <View key={"st_" + section.id} style={{ flex: 1 }}>
            <Typography
              key={"jpt"}
              variant="h6"
              display="block"
              color={"inherit"}
            >
              {section.title}
            </Typography>
            {elementsInDiv}
          </View>
        );
      }
    }
  };

  const renderSections = (
    currentGridMode,
    currentGridParents,
    layoutLoading,
    currentUser,
    pageDefinition,
    displayMode,
    screenSize,
    cacheRoot,
    renderLevel
  ) => {
    let elements = [];
    let sections = pageDefinition.sections;

    for (let i = 0; i < sections.length; i++) {
      let section = pageDefinition.sections[i];
      if (
        displayMode === "tabbar" ||
        displayMode === "tabs" ||
        displayMode === "pills"
      ) {
        let sectionElement = renderSection(
          currentGridMode,
          currentGridParents,
          layoutLoading,
          currentUser,
          pageDefinition,
          section,
          i === 0,
          displayMode,
          screenSize,
          cacheRoot,
          renderLevel
        );
        if (!isNull(sectionElement)) {
          elements.push(sectionElement);
        }
        //} else {
        //  let eventKey = null;
        //  eventKey = pageDefinition.entityName + ":" + section.id;
        //  if (displayMode === "tabs") {
        //    let sectionTitle = isNull(section.title) ? section.id : section.title;
        //    elements.push(<TabBar.Item key={"rst_" + section.id} title={sectionTitle}>
        //        <ScrollView style={{ height: 10 }}>
        //        </ScrollView>
        //      </TabBar.Item>);
        //  } else if (displayMode === "pills") {
        //    elements.push(<TabBar.Item key={"rst_" + section.id} title={sectionTitle}>
        //        <ScrollView style={{ height: 10 }}>
        //        </ScrollView>
        //      </TabBar.Item>);
        //  }
        //}
      } else {
        let sectionElement = renderSection(
          currentGridMode,
          currentGridParents,
          layoutLoading,
          currentUser,
          pageDefinition,
          section,
          i === 0,
          displayMode,
          screenSize,
          cacheRoot,
          renderLevel
        );
        if (!isNull(sectionElement)) {
          elements.push(sectionElement);
        }
      }
    }
    return elements;
  };

  const renderDefinition = (
    ariaLabel,
    currentGridMode,
    currentGridParents,
    layoutLoading,
    currentUser,
    pageDefinition,
    fieldName,
    screenSize,
    cacheRoot,
    renderLevel
  ) => {
    let elements = [];
    if (layoutLoading || isNull(currentUser) || isNull(pageDefinition))
      return elements;

    let displayMode = getDisplayMode(pageDefinition);
    let mobileMode =
      Platform.OS === "ios" ||
      Platform.OS === "android" ||
      state.screenSize == SCREEN.SMALL;
    if (mobileMode) {
      if (displayMode !== "None" || pageDefinition.sections.length > 1) {
        if (renderLevel === 0) {
          displayMode = "tabbar";
        } else {
          displayMode = "tabs";
        }
      }
    }
    let pageElements = renderSections(
      currentGridMode,
      currentGridParents,
      layoutLoading,
      currentUser,
      pageDefinition,
      displayMode,
      screenSize,
      cacheRoot,
      renderLevel
    );

    if (
      displayMode === "tabs" ||
      displayMode === "pills" ||
      displayMode === "tabbar"
    ) {
      //console.log("redisplaying", pageDefinition.entityName, isNull(currentGridMode), isNull(currentGridMode) ? undefined : 1, "p_" + props.pageItem.id.toString() + fieldName + "_" + screenSize.toString())
      let entityId = getWorkflowEntityId();
      elements.push(
        <TabBar
          ariaLabel={ariaLabel}
          hidden={!isNull(currentGridMode)}
          type={displayMode}
          id={
            "p_" +
            props.pageItem.id.toString() +
            fieldName +
            "_" +
            screenSize.toString()
          }
          key={
            "p_" +
            props.pageItem.id.toString() +
            fieldName +
            "_" +
            screenSize.toString()
          }
          activeKey={undefined}
          screenSize={screenSize}
          publicStyle={props.publicStyle}
          onSubmit={props.onSubmit}
          entityFactory={props.entityFactory}
          entityId={entityId}
          allButtons={allButtons}
          allWorkflows={allWorkflows}
        >
          {pageElements}
        </TabBar>
      );
    } else {
      //console.log("redisplay no tabs", pageElements)
      elements.push(...pageElements);
    }
    if (elements.length === 0) elements.push(<View key={"rdt_" + fieldName} />);
    return elements;
  };

  const getIsDirty = (currentGridMode) => {
    const { isDirty } = state;
    if (!isNull(currentGridMode)) {
      if (isDirty[currentGridMode]) return true;
    } else {
      for (let key in isDirty) {
        if (isDirty[key]) return true;
      }
    }
    return false;
  };

  const getIsSubmitting = (currentGridMode) => {
    const { isSubmitting } = state;
    if (!isNull(currentGridMode)) {
      if (isSubmitting[currentGridMode]) return true;
    } else {
      for (let key in isSubmitting) {
        if (isSubmitting[key]) return true;
      }
    }
    return false;
  };

  // const getHasErrors = (currentGridMode) => {
  //   const { hasErrors } = state;
  //   if (!isNull(currentGridMode)) {
  //     if (hasErrors[currentGridMode]) return true;
  //   } else {
  //     for (let key in hasErrors) {
  //       if (hasErrors[key]) return true;
  //     }
  //   }
  //   return false;
  // };

  const getSubmitURL = (currentGridMode) => {
    const { submitURLs } = state;
    if (!isNull(currentGridMode)) {
      let submitURL = submitURLs[currentGridMode];
      if (submitURL) return submitURL;
    } else {
      for (let key in submitURLs) {
        let submitURL = submitURLs[key];
        if (submitURL) return submitURL;
      }
    }
    return null;
  };

  const getCurrentGridMode = () => {
    for (let key in gridModeState) {
      if (gridModeState[key] > 0) {
        return key;
      }
    }
    return null;
  };

  const onSubmitClick = () => {
    const { isDirty, onFormSubmit, handleSubmit } = state;
    let currentGridMode = getCurrentGridMode();

    if (!isNull(currentGridMode)) {
      handleSubmit[currentGridMode](onFormSubmit[currentGridMode])();
      //handleSubmit[currentGridMode]();
    } else {
      for (let key in isDirty) {
        if (isDirty[key]) {
          handleSubmit[key](onFormSubmit[key])();
        }
      }
    }
  };

  const onSubmitSuccess = (formName, formActions) => {
    const {
      isDirty,
      isSubmitting,
      hasErrors,
      handleSubmit,
      handleReset,
      onFormSubmit,
    } = state;
    let currentGridMode = getCurrentGridMode();
    if (props.isNew) {
      props.history.goBack();
    }
    if (!isNull(currentGridMode)) {
      if (formActions) formActions.resetForm();
      let newDirty = { ...isDirty };
      newDirty[formName] = false;
      let newSubmitting = { ...isSubmitting };
      newSubmitting[formName] = false;
      let newErrors = { ...hasErrors };
      newErrors[formName] = [];
      let newHandleSubmit = { ...handleSubmit };
      newHandleSubmit[formName] = undefined;
      let newHandleReset = { ...handleReset };
      newHandleReset[formName] = undefined;
      let newOnFormSubmit = { ...onFormSubmit };
      newOnFormSubmit[formName] = undefined;
      let newGridMode = { ...gridModeState };
      newGridMode[formName] = 0;

      let newState = {
        ...state,
        isDirty: newDirty,
        isSubmitting: newSubmitting,
        hasErrors: newErrors,
        handleSubmit: newHandleSubmit,
        handleReset: newHandleReset,
        onFormSubmit: newOnFormSubmit,
      };
      setState(newState);
      setGridModeState(newGridMode);
      setRerender((prev) => prev + 1);
    }
  };

  const onDiscard = () => {
    const {
      isDirty,
      isSubmitting,
      hasErrors,
      handleSubmit,
      handleReset,
      onFormSubmit,
    } = state;
    let isInGridMode = false;
    for (let key in gridModeState) {
      if (gridModeState[key] > 0) {
        isInGridMode = true;
        handleReset[key]();

        let newDirty = { ...isDirty };
        newDirty[key] = false;
        let newSubmitting = { ...isSubmitting };
        newSubmitting[key] = false;
        let newErrors = { ...hasErrors };
        newErrors[key] = [];
        let newHandleSubmit = { ...handleSubmit };
        newHandleSubmit[key] = undefined;
        let newHandleReset = { ...handleReset };
        newHandleReset[key] = undefined;
        let newOnFormSubmit = { ...onFormSubmit };
        newOnFormSubmit[key] = undefined;
        let newGridMode = { ...gridModeState };
        newGridMode[key] = 0;
        setState({
          ...state,
          isDirty: newDirty,
          isSubmitting: newSubmitting,
          hasErrors: newErrors,
          handleSubmit: newHandleSubmit,
          handleReset: newHandleReset,
          onFormSubmit: newOnFormSubmit,
        });
        setGridModeState(newGridMode);
        break;
      }
    }

    if (!isInGridMode) {
      for (let key in isDirty) {
        if (isDirty[key]) handleReset[key]();
      }
    }
    if (props.isNew) {
      props.history.goBack();
    }
  };

  const onSubmitURL = (submitURL) => {
    // Hmm add temporary delay to allow text blur events to fire and persist form.
    // 150ms seems to work on my machine. Guess at 1000ms
    // Need a better way.
    /*
    setState({ ...state, isSearching: true });
    setTimeout(function () {
      setState({ ...state, isSearching: false });
      props.history.push(submitURL);
    }.bind(this), 1000);
    */
    props.history.push(submitURL);
  };

  const renderRightBase = () => {
    const { history, currentUser } = props;
    const { screenSize, wafelButtons, anchorEl, debugMode } = state;

    if (
      currentUser?.id === 21 &&
      history.location.pathname === "/page/versioncheck"
    ) {
      return undefined;
    } else if (
      currentUser &&
      currentUser.id === 21 &&
      history.location.pathname !== "/page/login"
    ) {
      let buttons = [];
      buttons.push(
        <View key={"rrb1"} style={{ flexDirection: "row" }}>
          <Button
            size={"small"}
            variant={undefined}
            color={"inherit"}
            onClick={() => {
              history.push("/page/login");
            }}
            style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
          >
            {"Login"}
          </Button>
        </View>
      );
      return buttons.length > 0 ? buttons : undefined;
    } else if (
      currentUser?.id === 21 &&
      history.location.pathname === "/page/login"
    ) {
      return [];
    } else {
      let buttons = [];

      let entityId = getWorkflowEntityId();
      for (let i = 0; i < wafelButtons.length; i++) {
        let button = wafelButtons[i];
        let isDefault =
          (screenSize === SCREEN.SMALL && i === 0) ||
          (screenSize === SCREEN.MEDIUM && i < MAXIMUM_BUTTONS_ON_MEDIUM);
        if (isDefault || screenSize > SCREEN.MEDIUM) {
          let isFolder = !(button.action && button.action.workflow);
          let isDisabled =
            button?.action?.workflow?.requiresEntityId && isNull(entityId);
          let isOpen = false;
          if (isFolder) {
            isOpen = isNull(anchorEl)
              ? false
              : Boolean(anchorEl?.[i.toString()]);
          }
          const id = isOpen ? "token-popover" + i.toString() : undefined;
          if (!isFolder && button.action.workflow.name === "/logout") {
            buttons.push(
              <LogoutButton
                key={"lb1"}
                changeLoginState={changeLoginState}
                variant={null}
                color={"inherit"}
              />
            );
          } else {
            buttons.push(
              <View key={"wf-" + i.toString()}>
                <Button
                  key={"wf-" + i.toString()}
                  size={"small"}
                  variant={undefined}
                  color={"inherit"}
                  disabled={isDisabled}
                  aria-controls={isFolder ? id : undefined}
                  aria-haspopup={isFolder}
                  onClick={(event) => {
                    if (!isFolder) {
                      executeAction(
                        button.action.workflow,
                        null,
                        props.onSubmit,
                        props.entityFactory,
                        entityId
                      );
                    } else {
                      let newAnchor = {};
                      if (isNull(anchorEl)) newAnchor = { ...anchorEl };
                      newAnchor[i.toString()] = event.currentTarget;
                      setState({ ...state, anchorEl: newAnchor });
                      //openPageActionDrawer();
                    }
                  }}
                  style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
                  endIcon={isFolder ? <ArrowDropDownIcon /> : undefined}
                  startIcon={
                    !isFolder && button.icon ? (
                      <TabBarIcon
                        icon={button.icon}
                        size={20}
                        color={
                          isDisabled
                            ? theme.palette.text.disabled
                            : theme.palette.primary.contrastText
                        }
                      />
                    ) : undefined
                  }
                >
                  {debugMode && !isEmpty(button?.action?.workflow?.name)
                    ? button.action.workflow.name
                    : button.buttonName}
                </Button>
                {isFolder ? (
                  <Popover
                    id={id}
                    anchorEl={anchorEl?.[i.toString()]}
                    keepMounted
                    open={isOpen}
                    onClose={() => {
                      let newAnchor = {};
                      if (isNull(anchorEl)) newAnchor = { ...anchorEl };
                      delete newAnchor[i.toString()];
                      setState({ ...state, anchorEl: newAnchor });
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <View>
                      <List
                        style={{
                          background: theme.palette.background.paper,
                        }}
                      >
                        {Object.entries(button.buttonMenu).map((entry) => {
                          const [key, value] = entry;
                          if (isEmpty(value)) return undefined;
                          return (
                            <ListItem
                              button
                              onClick={() => {
                                executeAction(
                                  value[0]?.workflow,
                                  null,
                                  props.onSubmit,
                                  props.entityFactory,
                                  entityId
                                );
                              }}
                              key={key}
                              style={{
                                paddingLeft: theme.spacing(1),
                              }}
                            >
                              <ListItemText key={"lit-" + key} primary={key} />
                            </ListItem>
                          );
                        })}
                      </List>
                    </View>
                  </Popover>
                ) : undefined}
              </View>
            );
          }
        }
      }

      return buttons.length > 0 ? buttons : undefined;
    }
  };

  const renderRight = () => {
    const currentGridMode = getCurrentGridMode();
    const isDirty = getIsDirty(currentGridMode);
    const isSubmitting = getIsSubmitting(currentGridMode);
    const submitURL = getSubmitURL(currentGridMode);

    if (
      props.currentUser &&
      props.currentUser.id === 21 &&
      props.history.location.pathname === "/page/versioncheck"
    ) {
      return undefined;
    } else if (
      props.currentUser &&
      props.currentUser.id === 21 &&
      props.history.location.pathname !== "/page/login"
    ) {
      let buttons = [];
      buttons.push(
        <View key={"rr1"} style={{ flexDirection: "row" }}>
          <Button
            size={"small"}
            variant={undefined}
            color={"inherit"}
            onClick={() => {
              props.history.push("/page/login");
            }}
            style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
          >
            {"Login"}
          </Button>
        </View>
      );
      return buttons.length > 0 ? buttons : undefined;
    } else if (
      props.currentUser &&
      props.currentUser.id === 21 &&
      props.history.location.pathname === "/page/login"
    ) {
      return [];
    } else if (submitURL) {
      if (!state.isSearching) {
        return (
          <View
            key={"rr4"}
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <Button
              size={"small"}
              variant={"contained"}
              color={"secondary"}
              onClick={() => onSubmitURL(submitURL)}
              style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
            >
              {"Search"}
            </Button>
            <Button
              size={"small"}
              variant={undefined}
              color={"inherit"}
              onClick={onDiscard}
              style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
            >
              {"Reset"}
            </Button>
          </View>
        );
      } else {
        return (
          <Loading
            messageOnly={true}
            messageVariant={"subtitle1"}
            color={"#ff0000"}
            inverse={true}
            direction={"row-reverse"}
            key={"processing"}
            message={"Processing ... "}
          />
        );
      }
    } else if (props.isNew || !isNull(currentGridMode)) {
      return (
        <View
          key={"rr5"}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Button
            size={"small"}
            variant={"contained"}
            color={"secondary"}
            onClick={onSubmitClick}
            style={{
              width: 75,
              justifyContent: "center",
              marginLeft: 10,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {"OK"}
          </Button>
          <Button
            size={"small"}
            variant={"contained"}
            color={"secondary"}
            onClick={onDiscard}
            disabled={isSubmitting}
            style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
          >
            {"Cancel"}
          </Button>
        </View>
      );
    } else if (isDirty || isSubmitting) {
      return (
        <View
          key={"rr6"}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Button
            size={"small"}
            variant={"contained"}
            color={"secondary"}
            onClick={onSubmitClick}
            style={{
              width: 75,
              justifyContent: "center",
              marginLeft: 10,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {"Submit"}
          </Button>
          <Button
            size={"small"}
            variant={undefined}
            color={"inherit"}
            onClick={onDiscard}
            disabled={isSubmitting || !isDirty}
            style={{
              width: 75,
              justifyContent: "center",
              marginLeft: 10,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {"Discard"}
          </Button>
        </View>
      );
    } else {
      return renderRightBase();
    }
  };

  const renderTitle = () => {
    let title =
      state.screenSize !== SCREEN.SMALL || isNull(state.pageDefinition?.short)
        ? state.pageDefinition?.title
        : state.pageDefinition?.short;
    return title
      ? title
      : props.pageItem && props.pageItem.name
      ? props.pageItem.name
      : undefined;
  };

  const getItemFromSection = (definition, section, isFirstSection) => {
    const { pageItem } = props;

    let fieldIndex =
      tabCache["p_" + pageItem.id.toString() + "_s_" + section.id];
    if (isNull(fieldIndex)) fieldIndex = 0;

    let currentField = undefined;
    if (isNull(fieldIndex)) fieldIndex = 0;
    let fields = definition.fields;
    let count = -1;
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (
        field.hidden !== true &&
        ((isFirstSection && isEmpty(field.tab)) || field.tab === section.id)
      ) {
        count += 1;
        if (count === fieldIndex) {
          currentField = field;
          break;
        }
      }
    }
    if (currentField && currentField.type === "object") {
      return getCurrentField(
        currentField.definition,
        "_" + currentField.fieldName
      );
    } else {
      return currentField;
    }
  };

  const getCurrentField = (definition, fieldName) => {
    const { pageItem } = props;
    if (isNull(definition)) return null;
    let sectionIndex = pageItem
      ? tabCache["p_" + pageItem.id.toString() + fieldName]
      : null;
    if (isNull(sectionIndex)) sectionIndex = 0;
    let section = definition.sections[sectionIndex];
    return getItemFromSection(definition, section, sectionIndex === 0);
  };

  const findParents = (parents, fieldName) => {
    if (isNull(parents)) parents = [];
    if (!isNull(fieldName)) {
      parents.push(fieldName);
      let parentField = definitionHelper.findParent(
        state.pageDefinition.fields,
        null,
        fieldName
      );
      if (!isNull(parentField)) {
        findParents(parents, parentField.fieldName);
      }
    }
    return parents;
  };

  const renderBody = () => {
    const { layoutGraph, pageItem } = props;
    const { pageDefinition } = state;
    let currentUser = layoutGraph ? layoutGraph.currentUser : null;
    const layoutLoading = layoutGraph ? layoutGraph.loading : true;
    const currentGridMode = getCurrentGridMode();
    let currentGridParents = findParents(null, currentGridMode);
    let workflows = [];
    let pageTitle = "";
    let elements = [];
    if (!isNull(pageItem)) {
      if (!isNull(pageDefinition) && !isEmpty(pageDefinition.title)) {
        pageTitle = pageDefinition.title;
      }
      let renderLevel = 0;
      let ariaLabel = isEmpty(pageTitle) ? pageItem.name : pageTitle;
      elements = renderDefinition(
        ariaLabel,
        currentGridMode,
        currentGridParents,
        layoutLoading,
        currentUser,
        pageDefinition,
        "",
        state.screenSize,
        "p_" + pageItem.id.toString(),
        renderLevel
      );
    }
    if (elements && elements.length === 1) {
      return <View style={{ flex: 1 }}>{elements[0]}</View>;
    } else {
      return (
        <View style={{ flex: 1 }}>
          {!layoutLoading && isNull(pageItem) && !isNull(currentUser) ? (
            <View>
              <Typography
                key={"jpt"}
                variant="h6"
                display="block"
                color={"inherit"}
              >
                Page not found.
              </Typography>
            </View>
          ) : undefined}
          {!layoutLoading &&
          currentUser &&
          currentUser.id &&
          workflows.length > 0 ? (
            <View>{workflows}</View>
          ) : undefined}
          {!layoutLoading && currentUser && currentUser.id ? (
            <View style={{ flex: 1 }}>{elements}</View>
          ) : undefined}
        </View>
      );
    }
  };

  const {
    layoutGraph,
    hasSideBar,
    entityFactory,
    changeLoginState,
    onSubmit,
    menuOnlyHasHome,
    drawerMenu,
  } = props;
  const { screenSize, wafelButtons, allButtons, allWorkflows } = state;

  let currentUser = layoutGraph ? layoutGraph.currentUser : null;

  const currentGridMode = getCurrentGridMode();

  const isDirty = getIsDirty(currentGridMode);
  const isSubmitting = getIsSubmitting(currentGridMode);

  let showTitle =
    !(props.isNew || !isNull(currentGridMode)) || screenSize !== SCREEN.SMALL;
  let title = undefined;
  if (showTitle) {
    title = state.debugMode ? Constants.manifest.version : renderTitle();
  }
  const isLogin = props.history.location.pathname === "/page/login";
  //console.log("JSON PAGE RENDER", currentUser, layoutGraph);
  let keys = drawerMenu ? Object.keys(drawerMenu) : [];
  let onlyHome =
    keys.length === 1 &&
    drawerMenu[keys[0]].length === 1 &&
    drawerMenu[keys[0]][0].url === "/home";
  return (
    <PageLayout
      layoutGraph={layoutGraph}
      allEntityFactories={props?.allEntityFactories}
      windowHeight={props.windowHeight}
      isLogin={isLogin}
      currentUser={currentUser}
      classes={classes}
      theme={theme}
      screenSize={screenSize}
      wafelButtons={wafelButtons}
      hasSideBar={hasSideBar}
      entityFactory={entityFactory}
      entityId={getWorkflowEntityId()}
      changeLoginState={changeLoginState}
      drawerMenu={drawerMenu}
      onWorkflowSubmit={onSubmit}
      hideTopLeft={isDirty || isSubmitting}
      hideMenu={state.pageDefinition?.displayLogout && onlyHome}
      showNavigation={!hasSideBar && !menuOnlyHasHome}
      title={title}
      onLogoPress={onDebug}
      rightContent={renderRight()}
      bodyContent={renderBody()}
    />
  );
};

export default withWorkflowUpdate(withRouter(memo(JSONPage)));
