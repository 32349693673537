/* eslint-disable react/prop-types */
import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import FormBase from "./formBase";

const NonTaskForm = (props) => {
  return (
    <Elements stripe={null}>
      <FormBase {...props} />
    </Elements>
  );
};

export default NonTaskForm;
