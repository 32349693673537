/* eslint-disable react/prop-types */
import React from "react";

function withCompatibilityCheck(BaseComponent) {
  const withCompatibilityCheckFn = function CompatibilityCheckInner(props) {
    if (!Array.prototype.flat) {
      return (
        <div role="alert">
          <div>
            We apologise however you are attempting to run this application on
            an old or incompatible device. We recommend ensuring that your
            device is running the latest version of your browser and upgrading
            it if it if you can. Alternatively you can try a different browser.
          </div>
          <button
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </button>
        </div>
      );
    }

    return <BaseComponent {...props} />;
  };

  return withCompatibilityCheckFn;
}

export default withCompatibilityCheck;
