import React, { useEffect, useRef } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useApolloClient } from "@apollo/client";

const GET_THREAD = gql`
  query getThread($taskId: ID!) {
    getThread(taskId: $taskId) {
      id
      taskId
      stepId
      workflowName
      display
      userId
      userName
      timeStamp
      processing
      priority
    }
  }
`;

export default function withTaskPoll(BaseComponent) {
  const withTaskPollFn = function WithTaskPollInner(props) {
    const { task } = props;
    const [getThread, { loading, data }] = useLazyQuery(GET_THREAD);
    const client = useApolloClient();
    const isProcessing = task?.processing == true;
    const timeout = useRef(null);
    const threadDeleted = useRef(false);
    const lastTaskId = useRef(null);
    useEffect(() => {
      var timer = timeout.current;
      if (timer != null) clearInterval(timer);
      if (task && isProcessing) {
        timeout.current = setInterval(() => {
          lastTaskId.current = task.id;
          getThread({ variables: { taskId: task.id } });
        }, 10000);
      }
    }, [task, isProcessing, getThread]);

    if (!loading && data && data.getThread == null) {
      //Thread no long exists. Stop poll
      if (timeout.current) clearInterval(timeout.current);
      threadDeleted.current = true;
      if (lastTaskId.current) {
        const normalizedId = client.cache.identify({
          id: lastTaskId.current,
          __typename: "Thread",
        });
        client.cache.evict({ id: normalizedId });
        client.cache.gc();
      }
    }

    useEffect(() => {
      return () => {
        if (timeout.current) clearInterval(timeout.current);
      };
    }, []);

    if (threadDeleted.current) {
      return <BaseComponent {...props} task={null} />;
    } else {
      return <BaseComponent {...props} />;
    }
  };
  return withTaskPollFn;
}
