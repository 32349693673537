import React from "react";

let TextInput = React.lazy(() => import("./TextInput/TextInput"));

let DatePicker = React.lazy(() => import("./DatePicker/DatePicker"));

let Checkbox = React.lazy(() => import("./Checkbox/Checkbox"));

let Toggle = React.lazy(() => import("./Toggle/Toggle"));

let ButtonToggle = React.lazy(() => import("./ButtonToggle/ButtonToggle"));

let Alert = React.lazy(() => import("./Alert"));

let HTMLTemplate = React.lazy(() => import("./HTMLTemplate/HTMLTemplate"));

let StringLabel = React.lazy(() => import("./StringLabel"));

let QRCode = React.lazy(() => import("./QRCode/QRCode"));

let StripePayment = React.lazy(() => import("./Stripe/StripePayment"));

let Currency = React.lazy(() => import("./Currency/Currency"));

let Minutes = React.lazy(() => import("./Minutes/Minutes"));

let Radio = React.lazy(() => import("./Radio/Radio"));

let SingleSelect = React.lazy(() => import("./Select/SingleSelect"));

let MultiSelect = React.lazy(() => import("./Select/MultiSelect"));

let FilePicker = React.lazy(() => import("./FilePicker/FilePicker"));

let ExcelPicker = React.lazy(() => import("./ExcelPicker/ExcelPicker"));

let MonthPicker = React.lazy(() => import("./MonthPicker/MonthPicker"));

let TimePicker = React.lazy(() => import("./TimePicker/TimePicker"));

let Chart = React.lazy(() => import("./Chart/Chart"));

let MapPicker = React.lazy(() => import("./MapPicker/MapPicker"));

let SVG = React.lazy(() => import("./SVG/SVG"));

let JSONMultiGrid = React.lazy(() => import("./JSONGrid/JSONMultiGrid"));

let JSONSingleGrid = React.lazy(() => import("./JSONGrid/JSONSingleGrid"));

let JSONGridImport = React.lazy(() => import("./JSONGrid/JSONGridImport"));

let Calendar = React.lazy(() => import("./Calendar/Calendar"));

let PDF = React.lazy(() => import("./PDF/PDF"));

let GridArray = React.lazy(() => import("./GridArray/GridArray"));

let SearchText = React.lazy(() => import("./SearchText/SearchText"));

let SearchNumber = React.lazy(() => import("./SearchNumber/SearchNumber"));

let SearchDate = React.lazy(() => import("./SearchDate/SearchDate"));

let SearchGeog = React.lazy(() => import("./SearchGeog/SearchGeog"));

let RelativeLink = React.lazy(() => import("./RelativeLink"));

let LinkButton = React.lazy(() => import("./LinkButton"));

let JSONEditor = React.lazy(() => import("./CodeEditor/codeEditor"));

let HTMLEditor = React.lazy(() => import("./CodeEditor/htmlEditor"));

let WYSIWYGEditor = React.lazy(() => import("./WYSIWYGEditor/wysiwygEditor"));

let AddressFinder = React.lazy(() => import("./AddressFinder/AddressFinder"));

let WafelButton = React.lazy(() => import("./WafelButton"));
let FirebaseLoginButton = React.lazy(() =>
  import("./Login/FirebaseLoginButton")
);
let FieldList = React.lazy(() => import("./FieldList"));
let DiscardButton = React.lazy(() => import("./DiscardButton"));
let SearchButton = React.lazy(() => import("./SearchButton"));
let WafelSubmitButton = React.lazy(() => import("./WafelSubmitButton"));
let YouTubePlayer = React.lazy(() => import("./YouTubePlayer"));

export {
  AddressFinder,
  Alert,
  HTMLTemplate,
  TextInput,
  QRCode,
  StripePayment,
  DatePicker,
  MonthPicker,
  TimePicker,
  Checkbox,
  Currency,
  Minutes,
  Radio,
  SingleSelect,
  MultiSelect,
  FilePicker,
  ExcelPicker,
  Toggle,
  ButtonToggle,
  Chart,
  MapPicker,
  SVG,
  Calendar,
  PDF,
  JSONMultiGrid,
  JSONSingleGrid,
  JSONGridImport,
  GridArray,
  FieldList,
  WafelButton,
  WafelSubmitButton,
  DiscardButton,
  SearchButton,
  SearchText,
  SearchNumber,
  SearchDate,
  SearchGeog,
  RelativeLink,
  LinkButton,
  StringLabel,
  FirebaseLoginButton,
  JSONEditor,
  HTMLEditor,
  WYSIWYGEditor,
  YouTubePlayer,
};
