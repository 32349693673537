/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { isNull, isEmpty } from "../util/common.js";
import FormBase from "../entities/nonTaskForm";
import { SCREEN, SEARCHTYPES } from "../util/constants";
import definitionHelper from "../util/definitions.js";
import * as cloneDeep from "lodash/cloneDeep";
import { View } from "react-native";
import { useCache } from "./withCache";

function getViewModeFromProps(
  currentUser,
  entityFactory,
  geoFieldName,
  viewMode,
  Card
) {
  if (!currentUser || !entityFactory) {
    return isNull(viewMode) ? "grid" : viewMode;
  }
  let result = "grid";
  if (isNull(geoFieldName) && viewMode === "map") {
    result = "grid";
  } else if (isNull(Card) && viewMode === "card") {
    result = "grid";
  } else if (!isNull(viewMode)) {
    if (isNull(geoFieldName) && result === "map") {
      result = "grid";
    } else if (isNull(Card) && result === "card") {
      result = "grid";
    } else {
      result = viewMode;
    }
  } else {
    result = "grid";
  }
  return result;
}
function getStateFromProps(nextProps, definition) {
  if (!nextProps?.layoutGraph?.currentUser || !nextProps.entityFactory) {
    return {
      showSearch: false,
      allowSearch: false,
      searchDefinition: undefined,
      SearchForm: undefined,
      WrappedBaseComponent: <View style={{ flex: 4 }} />,
    };
  }

  let geoFieldName = nextProps.geoFieldName;

  return {
    searchDefinition: nextProps.searchDefinition,
    SearchForm: nextProps.entityFactory.withFactoryAndDefinition(
      FormBase,
      nextProps.searchDefinition
    ),
    WrappedBaseComponent: nextProps.entityFactory.withSearchGrid(definition),
    showSearch: isNull(nextProps.showAdvancedSearch)
      ? false
      : nextProps.showAdvancedSearch,
    allowSearch: isNull(nextProps.allowAdvancedSearch)
      ? false
      : nextProps.allowAdvancedSearch,
    defaultFilters: nextProps.hasCriteria ? cloneDeep([nextProps.filter]) : [],
    geoFieldName: geoFieldName,
  };
}

export default function withAdvancedSearch(definition) {
  const withAdvancedSearchFn = function AdvancedSearchInner(props) {
    const {
      screenSize,
      layoutGraph,
      Card,
      cacheRoot,
      searchDefinition,
      entityFactory,
      publicStyle,
      viewMode,
    } = props;
    const advancedSearchCacheRoot = useMemo(() => {
      return isEmpty(cacheRoot) ? undefined : cacheRoot + "_as";
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { generalCache } = useCache(advancedSearchCacheRoot);
    const [state, setState] = useState(() =>
      getStateFromProps(props, definition)
    );
    const { SearchForm, WrappedBaseComponent } = state;

    const [searchBarTop, setSearchBarTop] = useState(() => {
      if (
        advancedSearchCacheRoot &&
        generalCache &&
        !isNull(generalCache.searchBarTop)
      ) {
        return generalCache.searchBarTop;
      }
      return true;
    });
    // const [hasCriteria, setHasCriteria] = useState(() => {
    //   if (!layoutGraph?.currentUser || !entityFactory) {
    //     return false;
    //   }
    //   return props.hasCriteria ? props.hasCriteria : false;
    // });
    const [initViewMode, setInitViewMode] = useState(() => {
      return getViewModeFromProps(
        layoutGraph?.currentUser,
        entityFactory,
        props.geoFieldName,
        viewMode,
        Card
      );
    });
    const [mapViewPort, setMapViewPort] = useState(null);
    const [filterState, setFilterState] = useState(() => {
      return {
        filters:
          layoutGraph?.currentUser && entityFactory && props.hasCriteria
            ? [props.filter]
            : [],
        fts: undefined,
        ftsTimeStamp: undefined,
      };
    });

    const onMapViewPortChange = useCallback(
      (newMapViewPort) => setMapViewPort(newMapViewPort),
      []
    );

    const onChangeSearchMode = useCallback(() => {
      setSearchBarTop((isCurrentlyTop) => {
        generalCache.searchBarTop = !isCurrentlyTop;
        return !isCurrentlyTop;
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const onChange = useCallback(
    //   (formData) => {
    //     let hasData = false;
    //     if (!isNull(formData) && !isEmpty(formData.updateFields)) {
    //       for (let i = 0; i < formData.updateFields.length; i++) {
    //         let fieldName = formData.updateFields[i];
    //         if (!isNull(formData[fieldName])) {
    //           let field = definitionHelper.findField(
    //             state.searchDefinition.fields,
    //             fieldName
    //           );
    //           if (field.type === "json") {
    //             hasData = !isEmpty(JSON.parse(formData[fieldName]).val);
    //           } else {
    //             hasData = true;
    //           }
    //           if (hasData) break;
    //         }
    //       }
    //     }
    //     setHasCriteria(hasData);
    //   },
    //   [state.searchDefinition]
    // );

    const onSearch = useCallback(
      (formDataArray) => {
        console.log(">>>  onSearch formDataArray", formDataArray);
        return new Promise((resolve) => {
          if (!isEmpty(formDataArray)) {
            let formData = formDataArray[0];
            let filter = {};
            let hasData = false;
            if (!isNull(formData) && !isEmpty(formData.updateFields)) {
              for (let i = 0; i < formData.updateFields.length; i++) {
                let fieldName = formData.updateFields[i];
                if (!isEmpty(formData[fieldName])) {
                  let field = definitionHelper.findField(
                    state.searchDefinition.fields,
                    fieldName
                  );
                  if (field.type === "json") {
                    let parsedJSON = JSON.parse(formData[fieldName]);
                    if (!isEmpty(parsedJSON.val)) {
                      filter[fieldName] = parsedJSON;
                      delete filter[fieldName].searchResult; //For geog search;
                      hasData = true;
                    }
                  } else {
                    filter[fieldName] = {
                      val: formData[fieldName],
                      searchType: SEARCHTYPES.SUBSTRING,
                    };
                    hasData = true;
                  }
                }
              }
            }
            if (hasData) {
              console.log("SETTING FILTER STATE 1", {
                filters: [filter],
                fts: isNull(formData.fts) ? undefined : formData.fts,
                ftsTimeStamp: new Date(),
              });
              setFilterState({
                filters: [filter],
                fts: isNull(formData.fts) ? undefined : formData.fts,
                ftsTimeStamp: new Date(),
              });
            } else {
              console.log("SETTING FILTER STATE 2", {
                filters: [],
                fts: isNull(formData.fts) ? undefined : formData.fts,
                ftsTimeStamp: new Date(),
              });
              setFilterState({
                filters: [],
                fts: isNull(formData.fts) ? undefined : formData.fts,
                ftsTimeStamp: new Date(),
              });
            }
          }

          resolve();
        });
      },
      [state.searchDefinition]
    );

    useEffect(() => {
      if (
        !state.searchDefinition &&
        entityFactory &&
        layoutGraph?.currentUser
      ) {
        setState(getStateFromProps(props, definition));
        // setHasCriteria(props.hasCriteria ? props.hasCriteria : false);
        setInitViewMode(
          getViewModeFromProps(
            layoutGraph?.currentUser,
            entityFactory,
            props.geoFieldName,
            viewMode,
            Card
          )
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchDefinition]);

    let currentUser = layoutGraph ? layoutGraph.currentUser : null;

    //currentSubDef
    if (currentUser && entityFactory !== null) {
      let primaryCollection = entityFactory.primaryCollection;
      let thread = {};
      if (screenSize > SCREEN.MEDIUM) {
        return (
          <View
            style={{
              flex: 1,
              zIndex: 999,
              flexDirection:
                searchBarTop || screenSize === SCREEN.SMALL ? "column" : "row",
            }}
          >
            {isNull(props.gridMode) || props.gridMode === 0 ? (
              <SearchForm
                isGrid={true}
                publicStyle={publicStyle}
                isSearch={true}
                onChangeSearchMode={onChangeSearchMode}
                searchBarTop={searchBarTop}
                style={{
                  flex:
                    searchBarTop || screenSize === SCREEN.SMALL ? undefined : 2,
                  zIndex: 999,
                }}
                noPrompt={true}
                onChange={undefined}
                actionMessage="Searching"
                buttonsTop={true}
                flatten={false}
                definition={state.searchDefinition}
                allowSubdefinitionSwitch={false}
                thread={thread}
                allEntityFactories={props.allEntityFactories}
                primaryCollection={primaryCollection}
                isNew={true}
                isModal={true}
                update={onSearch}
                item={null}
                bulkItems={[]}
                layoutGraph={layoutGraph}
                notificationSystem={undefined}
                screenSize={screenSize}
              ></SearchForm>
            ) : undefined}
            <WrappedBaseComponent
              {...props}
              Card={Card}
              setMapViewPort={onMapViewPortChange}
              mapViewPort={mapViewPort}
              viewMode={initViewMode}
              filters={filterState.filters}
              fts={filterState.fts}
              ftsTimeStamp={filterState.ftsTimeStamp}
            />
          </View>
        );
      } else {
        return (
          <View
            style={{
              flex: 1,
              zIndex: 999,
              flexDirection:
                searchBarTop || screenSize === SCREEN.SMALL ? "column" : "row",
            }}
          >
            {isNull(props.gridMode) || props.gridMode === 0 ? (
              <SearchForm
                isGrid={true}
                publicStyle={publicStyle}
                isSearch={true}
                onChangeSearchMode={onChangeSearchMode}
                searchBarTop={searchBarTop}
                style={{
                  flex:
                    searchBarTop || screenSize === SCREEN.SMALL ? undefined : 2,
                  zIndex: 999,
                }}
                noPrompt={true}
                onChange={undefined}
                actionMessage="Searching"
                buttonsTop={true}
                flatten={false}
                definition={state.searchDefinition}
                allowSubdefinitionSwitch={false}
                thread={thread}
                allEntityFactories={props.allEntityFactories}
                primaryCollection={primaryCollection}
                isNew={true}
                isModal={true}
                update={onSearch}
                item={null}
                bulkItems={[]}
                layoutGraph={layoutGraph}
                notificationSystem={undefined}
                screenSize={screenSize}
              ></SearchForm>
            ) : undefined}
            <WrappedBaseComponent
              {...props}
              Card={Card}
              setMapViewPort={onMapViewPortChange}
              mapViewPort={mapViewPort}
              viewMode={initViewMode}
              filters={filterState.filters}
              fts={filterState.fts}
              ftsTimeStamp={filterState.ftsTimeStamp}
            />
          </View>
        );
      }
    } else {
      return (
        <WrappedBaseComponent
          {...props}
          Card={Card}
          setMapViewPort={onMapViewPortChange}
          mapViewPort={mapViewPort}
          viewMode={initViewMode}
        />
      );
    }
  };

  return withAdvancedSearchFn;
}
