/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, useCallback, memo } from "react";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { View } from "react-native";
import TabBarItem from "./TabBarItem";
import TabBarIcon from "./TabBarIcon";
import { withCache } from "../hoc/withCache";
import { isNull, isEmpty, isString } from "../util/types";
import { SCREEN } from "../util/constants";
import AccessibleView from "./accessibleView";
import { useWorkflow } from "../hooks/useWorkflow";
import { useHistory } from "../ReactRouter";

const TabBarIconMemo = memo(TabBarIcon);

function a11yProps(ariaToken, index) {
  return {
    id: ariaToken + `tab-${index}`,
    "aria-controls": ariaToken + `-${index}`,
  };
}

function getSelectedTab(id, cache, activeKey) {
  return activeKey
    ? activeKey
    : id && cache && cache.tabs && cache.tabs[id]
    ? cache.tabs[id]
    : 0;
}

function intitalizeLoaded(selectedTab) {
  let loaded = {};
  loaded[selectedTab.toString()] = true;
  return loaded;
}

function getStyle(style, type) {
  let newStyle = {};
  switch (type) {
    case "pills":
      newStyle = { ...pillStyles };
      break;
    case "tabs":
      newStyle = { ...tabStyles };
      break;
    default:
      //tabbar
      newStyle = { ...barStyles };
      break;
  }
  if (style) {
    newStyle.container = { ...newStyle.container, ...style };
  }
  return newStyle;
}

function getDisplayItems(slots, total, selectedTab) {
  if (total <= slots) {
    return {
      showPrev: false,
      startIndex: 0,
      endIndex: total - 1,
      showNext: false,
    };
  } else if (total <= (slots - 1) * 2) {
    if (selectedTab < slots - 1) {
      return {
        showPrev: false,
        startIndex: 0,
        endIndex: slots - 2,
        showNext: true,
      };
    } else {
      return {
        showPrev: true,
        startIndex: total - slots + 1,
        endIndex: total - 1,
        showNext: false,
      };
    }
  } else {
    // assuming slots = 5.
    // 4, 3, 3, 3, 3, ... remaining.
    // So  (selectedTab)
    if (selectedTab < slots - 1) {
      return {
        showPrev: false,
        startIndex: 0,
        endIndex: slots - 2,
        showNext: true,
      };
    } else if (selectedTab > total - slots) {
      return {
        showPrev: true,
        startIndex: total - slots + 1,
        endIndex: total - 1,
        showNext: false,
      };
    } else {
      let page = Math.ceil(selectedTab / (slots - 2)) - 1;
      return {
        showPrev: true,
        startIndex: page * (slots - 2) + 1,
        endIndex: (page + 1) * (slots - 2),
        showNext: true,
      };
    }
  }
}

const TabBar = ({
  id,
  type,
  activeKey,
  screenSize,
  style,
  disabled,
  hidden,
  cache,
  onTabItemChange,
  displayHeaderWithSingleItem,
  children,
  ariaLabel,
  onSubmit,
  entityFactory,
  entityId,
  allButtons,
  allWorkflows,
  positions,
}) => {
  const { executeAction } = useWorkflow();
  const history = useHistory();
  const [originalSelectedTab, setOriginalSelectedTab] = useState(() => {
    return getSelectedTab(id, cache, activeKey);
  });
  const [selectedTab, setSelectedTab] = useState(originalSelectedTab);
  const [loaded, setLoaded] = useState(() => {
    return intitalizeLoaded(originalSelectedTab);
  });
  const styles = useMemo(() => getStyle(style, type), [style, type]);
  const theme = useTheme();
  const changeTab = useCallback(
    (index) => {
      if (disabled || hidden) return;
      if (isNull(index)) index = 0;
      if (!loaded[index.toString()]) {
        let newLoadedState = { ...loaded };
        newLoadedState[index.toString()] = true;
        setLoaded(newLoadedState);
        setSelectedTab(index);
      } else {
        setSelectedTab(index);
      }
      if (id && cache) cache.tabs[id] = index;
      if (onTabItemChange) onTabItemChange();
    },
    [disabled, hidden, loaded, id, cache, onTabItemChange]
  );

  useEffect(() => {
    if (!isNull(activeKey)) {
      setOriginalSelectedTab((prevOriginalSelectedTab) => {
        if (prevOriginalSelectedTab !== activeKey) {
          changeTab(activeKey);
          return activeKey;
        }
        return prevOriginalSelectedTab;
      });
    }
  }, [activeKey, changeTab]);

  const buttonsPerPage = useMemo(() => {
    let slots = 9999;
    switch (screenSize) {
      case SCREEN.SMALL:
        slots = 5;
        break;
      case SCREEN.MEDIUM:
        slots = 10;
        break;
      default:
        break;
    }

    return slots;
  }, [screenSize]);

  const handleMUITabChange = useCallback(
    (event, newValue) => {
      if (isString(newValue)) {
        if (newValue.startsWith("W!")) {
          let workflowName = newValue.substring(2);
          if (workflowName.startsWith("/")) {
            history.push(workflowName);
          } else if (workflowName.startsWith("http")) {
            window.open(workflowName);
          } else if (workflowName === "%%back") {
            history.goBack();
          } else {
            if (!isEmpty(allButtons)) {
              let workflow = allButtons.find(
                (item) => item?.action?.workflow?.name === workflowName
              );
              if (workflow) {
                executeAction(
                  workflow.action.workflow,
                  null,
                  onSubmit,
                  entityFactory,
                  entityId
                );
              } else {
                //Try anyway.
                console.log("WORKFLOW NOT FOUND ON PAGE", workflowName);
                let workflow = allWorkflows.find(
                  (item) => item.name === workflowName
                );
                if (workflow) {
                  executeAction(
                    workflow,
                    null,
                    onSubmit,
                    entityFactory,
                    entityId
                  );
                } else {
                  console.log("WORKFLOW NOT FOUND IN LAYOUT");
                }
              }
            } else {
              console.log("WORKFLOW NOT FOUND (no workflows)", workflowName);
            }
          }
        } else if (newValue.startsWith("P")) {
          changeTab(parseInt(newValue.slice(1)) - 1);
        } else {
          changeTab(parseInt(newValue.slice(1)) + 1);
        }
      } else {
        changeTab(newValue);
      }
    },
    [changeTab, onSubmit, entityFactory, entityId, executeAction, allButtons]
  );

  let childCount = 0;
  let childArray = [];

  if (Array.isArray(children)) {
    childArray.push(...children);
  } else {
    childArray.push(children);
  }

  childArray.forEach((child) => {
    if (!isNull(child)) {
      childCount += 1;
    }
  });
  let actualSelectedTab = selectedTab;
  if (actualSelectedTab >= childCount || type === "panels")
    actualSelectedTab = 0;
  let displayItems = undefined;
  if (type === "tabbar") {
    displayItems = getDisplayItems(
      buttonsPerPage,
      childCount,
      actualSelectedTab
    );
  }
  let allChildrenHaveIcons = true;
  for (let i = 0; i < childArray.length; i++) {
    let child = childArray[i];
    if (isEmpty(child.icon)) {
      allChildrenHaveIcons = false;
      break;
    }
  }
  if (isEmpty(ariaLabel)) {
    if (type === "tabs") {
      ariaLabel = "Top tabbar";
    } else {
      ariaLabel = "Sidebar";
    }
  }
  let ariaToken = ariaLabel.replace(" ", "-");
  return (
    <View style={styles.container}>
      {childArray.length > 1 && type === "panels" && !isEmpty(positions)
        ? childArray.slice(1).map((child, index) => {
            if (positions[index + 1] !== "top") return null;
            return (
              <View
                key={"ft" + index.toString()}
                style={{ ...styles.tabContainer }}
              >
                {hidden ? undefined : child}
              </View>
            );
          })
        : undefined}

      {React.Children.map(children, (child, i) => {
        if (i === actualSelectedTab || loaded[i.toString()]) {
          //return <View key={"tbi_" + i.toString()} style={{ flex: 1, backgroundColor: "green"}} />
          let isTab =
            (type === "tabs" || type === "pills") && childArray.length > 1;
          return (
            <AccessibleView
              role={isTab ? "tabpanel" : undefined}
              id={isTab ? ariaToken + `-${i}` : undefined}
              aria-labelledby={isTab ? ariaToken + `tab-${i}` : undefined}
              key={"tbi_" + i.toString()}
              style={{
                flex: 1,
                display: i === actualSelectedTab ? undefined : "none",
                height: i === actualSelectedTab ? undefined : 0,
                visibility: i === actualSelectedTab ? "visible" : "hidden",
                opacity: i === actualSelectedTab ? 100 : 0,
              }}
            >
              {child}
            </AccessibleView>
          );
        }
      })}

      {(displayHeaderWithSingleItem || childArray.length > 1) &&
      type === "tabs" ? (
        <View style={{ width: "100%" }}>
          {hidden ? undefined : (
            <AppBar position="static" color="default">
              <Tabs
                value={actualSelectedTab}
                onChange={handleMUITabChange}
                indicatorColor="primary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons="auto"
                aria-label={ariaLabel}
              >
                {React.Children.map(children, (child, i) => {
                  if (!child) return null;
                  //{child.props.icon}
                  return (
                    <Tab
                      label={child.props.title}
                      value={
                        child.props.workflow
                          ? "W!" + child.props.workflow
                          : undefined
                      }
                      icon={
                        child.props.icon ? (
                          <TabBarIconMemo
                            icon={child.props.icon}
                            size={26}
                            color={
                              i === actualSelectedTab
                                ? theme.palette.primary.main
                                : theme.palette.text.disabled
                            }
                          />
                        ) : undefined
                      }
                      {...a11yProps(ariaToken, i)}
                    />
                  );
                })}
              </Tabs>
            </AppBar>
          )}
        </View>
      ) : undefined}
      {(displayHeaderWithSingleItem || childArray.length > 1) &&
      type === "pills" ? (
        <View
          style={{
            ...styles.tabContainer,
            backgroundColor: theme.palette.text.disabled,
          }}
        >
          {hidden ? undefined : (
            <AppBar position="static" color="default">
              <Tabs
                value={actualSelectedTab}
                orientation="vertical"
                onChange={handleMUITabChange}
                indicatorColor="primary"
                textColor="inherit"
                variant="scrollable"
                aria-label={ariaLabel}
              >
                {React.Children.map(children, (child, i) => {
                  if (!child) return null;
                  return (
                    <Tab
                      label={child.props.title}
                      value={
                        child.props.workflow
                          ? "W!" + child.props.workflow
                          : undefined
                      }
                      icon={
                        child.props.icon ? (
                          <TabBarIconMemo
                            icon={child.props.icon}
                            size={26}
                            color={
                              i === actualSelectedTab
                                ? theme.palette.primary.main
                                : theme.palette.text.disabled
                            }
                          />
                        ) : undefined
                      }
                      {...a11yProps(ariaToken, i)}
                    />
                  );
                })}
              </Tabs>
            </AppBar>
          )}
        </View>
      ) : undefined}
      {(displayHeaderWithSingleItem || childArray.length > 1) &&
      type === "tabbar" ? (
        <View style={{ ...styles.tabContainer }}>
          {hidden ? undefined : (
            <BottomNavigation
              style={{ width: "100%" }}
              value={actualSelectedTab}
              showLabels={!allChildrenHaveIcons || childArray.length <= 3}
              onChange={handleMUITabChange}
            >
              {displayItems?.showPrev ? (
                <BottomNavigationAction
                  label={"Prev"}
                  value={"P" + displayItems.startIndex.toString()}
                  icon={
                    <TabBarIconMemo
                      icon={"arrow-back-outline"}
                      size={26}
                      color={theme.palette.text.disabled}
                    />
                  }
                />
              ) : undefined}
              {React.Children.map(children, (child, i) => {
                if (!child) return null;
                if (i < displayItems?.startIndex || i > displayItems?.endIndex)
                  return null;
                return (
                  <BottomNavigationAction
                    label={child.props.title}
                    value={
                      child.props.workflow ? "W!" + child.props.workflow : i
                    }
                    icon={
                      child.props.icon ? (
                        <TabBarIconMemo
                          icon={child.props.icon}
                          size={26}
                          color={
                            i === actualSelectedTab
                              ? theme.palette.primary.main
                              : theme.palette.text.disabled
                          }
                        />
                      ) : undefined
                    }
                  />
                );
              })}
              {displayItems?.showNext ? (
                <BottomNavigationAction
                  label={"Next"}
                  value={"N" + displayItems.endIndex.toString()}
                  icon={
                    <TabBarIconMemo
                      icon={"arrow-forward-outline"}
                      size={26}
                      color={theme.palette.text.disabled}
                    />
                  }
                />
              ) : undefined}
            </BottomNavigation>
          )}
        </View>
      ) : undefined}
      {childArray.length > 1 && type === "panels" && !isEmpty(positions)
        ? childArray.slice(1).map((child, index) => {
            if (positions[index + 1] !== "bottom") return null;
            return (
              <View
                key={"ft" + index.toString()}
                style={{ ...styles.tabContainer }}
              >
                {hidden ? undefined : child}
              </View>
            );
          })
        : undefined}
    </View>
  );
};

/*
const tabbarStyles = {
  wrap: {
    backgroundColor: AltColors.lightPurple,
    borderBottomColor: "#eaeaea",
    borderBottomWidth: 1,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center"
  },
  tab: {
    flex: 1,
    alignItems: 'center',
    padding: 16,
    paddingTop: 12,
    paddingBottom: 12
  },
  tabText: {
    width: "100%",
    textAlign: "center"
  },
  underline: {
    height: 3,
    backgroundColor: "#00D8BB"
  }
};
*/

let TBar = withCache(TabBar);
TBar.Item = TabBarItem;

const barStyles = {
  container: {
    flex: 1,
  },
  scollView: {
    flex: 1,
    flexGrow: 1,
  },
  tabContainer: {
    flexDirection: "row",
    elevation: 2,
    alignItems: "center",
  },
  tabButton: {
    flex: 1,
    height: "100%",
  },
  scaler: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
};

const pillStyles = {
  container: {
    flex: 1,
    flexDirection: "row-reverse",
  },
  tabContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
  },
  tabButton: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: "45px",
  },
  scaler: {
    flex: 1,
    flexDirection: "row",
    height: "45px",
    alignItems: "center",
  },
};

const tabStyles = {
  container: {
    flex: 1,
    flexDirection: "column-reverse",
  },
  tabContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "stretch",
  },
  tabButton: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  scaler: {
    flexDirection: "row",
  },
};

// const butStyles = {
//   container: {
//     flex: 1,
//     width: "100%",
//     flexDirection: "column-reverse",
//   },
//   tabContainer: {
//     flexDirection: "row",
//     justifyContent: "center",
//     alignItems: "stretch",
//   },
//   tabButton: {
//     alignItems: "center",
//     justifyContent: "center",
//     flexDirection: "row",
//   },
//   scaler: {
//     flexDirection: "row",
//   },
// };

export default TBar;
